import React, { useEffect, useState } from 'react';
import './App.css';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import httpClient from './utils/http-client.util';
import { BackendAPI } from './constants/backend-api.enum';
import dayjs from 'dayjs';

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { RequestAccess } from './pages/authentication/request-access';
import { ResetPassword } from './pages/authentication/reset-password';
import { ForgetPassword } from './pages/authentication/forget-password';
import { Login } from './pages/authentication/login';
import DefaultPage from './components/layout/defaultpage';
import { useBoundStore } from './states/bound.store';

dayjs.extend(utc);
dayjs.extend(timezone);

function App() {
  const location = useLocation();
  const user = useBoundStore().user;
  const navigate = useNavigate();
  const setPreventReload = useBoundStore().setPreventReload;

  const [authenticated, setAuthenticated] = useState(false);
  const publicRoutes = [
    { url: '/login', element: <Login /> },
    { url: '/request-access', element: <RequestAccess /> },
    { url: '/forget-password', element: <ForgetPassword /> },
    { url: '/reset-password', element: <ResetPassword type={'doReset'} /> },
  ];

  useEffect(() => {
    liveCheck();
  }, [location, user, location.pathname]);

  useEffect(() => {
    setPreventReload(false);
  }, [location])

  const liveCheck = async () => {
    if (publicRoutes.map((pr) => pr.url).includes(location.pathname)) {
      setAuthenticated(false);
      return;
    }

    try {
      if (user !== null) {
        await httpClient.post(BackendAPI.PING, { userId: user.userId });
        setAuthenticated(true);
      } else {
        throw user;
      }
    } catch (e) {
      if (user === null && publicRoutes.filter(i => i.url === location.pathname).length === 0) {
        navigate('/login');
      }
      setAuthenticated(false);
    }
  };

  return (
    <div className="App">
      <Routes>
        {publicRoutes.map((route, i) => (
          <Route key={i} path={route.url} element={route.element} />
        ))}
      </Routes>
      {authenticated && user && <DefaultPage />}
    </div>
  );
}

export default App;
