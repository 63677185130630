import React from 'react';

export default function ILibrary() {
  return (
    <svg width="35" height="35" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="5" fill="#FFB348" />
      <path
        d="M6 14V16.6286C6 17.3357 6.57857 18 7.28571 18H16.7143C17.4214 18 18 17.3357 18 16.6286V14H6ZM16.7143 6.85714C16.7143 6.38571 16.3286 6 15.8571 6H8.14286C7.67143 6 7.28571 6.38571 7.28571 6.85714V13.1429H16.7143V6.85714Z"
        fill="black"
      />
      <rect x="9" y="8" width="6" height="0.9" rx="0.45" fill="#FFB348" />
      <rect x="9" y="10" width="6" height="0.9" rx="0.45" fill="#FFB348" />
    </svg>
  );
}
