import React, { useEffect, useState } from 'react';

import { Button, Form, Input, Typography } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import httpClient from '../../utils/http-client.util';
import { BackendAPI } from '../../constants/backend-api.enum';
import { AxiosError } from 'axios';
import { LoginCredential, LoginResponse } from 'bridge/authentication';
import { popMessage } from '../../utils/pop-message.util';
import { useBoundStore } from '../../states/bound.store';
import { AuthenticationLayout } from '../../components/layout/authentication-layout/authentication-layout.component';
import { getRedirectRoute } from '../../helpers/redirect-helper';
import { DocumentResponse } from 'bridge/sop-bot';

const { Title } = Typography;

export const Login = () => {
  const [submitting, setIsSubmitting] = useState(false);
  const [formError, setFormError] = useState(false);
  const userLogin = useBoundStore((state) => state.userLogin);
  const setRights = useBoundStore((state) => state.setRights);
  const setChatHistory = useBoundStore((state) => state.setChatHistory);
  const setSubRights = useBoundStore((state) => state.setSubRights);
  const user = useBoundStore((state) => state.user);
  const navigate = useNavigate();
  const [loginForm] = Form.useForm<LoginCredential>();

  const onFieldsChange = async () => {
    const hasErrors = loginForm.getFieldsError().some(({ errors }) => errors.length);
    setFormError(hasErrors);
  };

  const handleSubmit = async (loginCredential: LoginCredential) => {
    setIsSubmitting(true);
    try {
      const loginAttempt = await httpClient.post<LoginResponse>(BackendAPI.LOGIN, loginCredential);
      if (loginAttempt.status === 200) {
        const resDoc = await httpClient.get<DocumentResponse>(`${BackendAPI.GET_LIST_DOC}/${loginAttempt.data?.userId}`);
        userLogin(loginAttempt.data);
        setRights(loginAttempt.data.role.rights ? loginAttempt.data.role.rights : []);
        setSubRights(loginAttempt.data.role.subRights ? loginAttempt.data.role.subRights : []);
        navigate(getRedirectRoute(loginAttempt.data, resDoc.data));
        setChatHistory([]);
      }
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.response) {
          const { message } = err.response.data;
          popMessage.error({ content: message });
        }
      } else {
        console.log(err);
        popMessage.error('Unknown Error During Login!');
      }
    } finally {
      setIsSubmitting(false);
    }
  };


  useEffect(() => {
    if (user) {
      navigate(getRedirectRoute(user));
    }
  }, []);

  return (
    <AuthenticationLayout>
      <div className="w-full flex items-center justify-center mb-4">
        <Title level={2}>LOGIN</Title>
      </div>
      <Form
        form={loginForm}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={handleSubmit}
        onFieldsChange={onFieldsChange}
        layout="vertical"
        size="large"
        autoComplete={'off'}
      >
        <Form.Item
          className="custom-form"
          label={<Typography className="label-text">Email Address</Typography>}
          name="login"
          rules={[{ required: true, message: 'Please input your email address!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          className="custom-form mb-0"
          label={<Typography className="label-text">Password</Typography>}
          name="password"
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item>
          <Link to={'/forget-password'}>
            <Typography className="link-text text-right">Forget Password</Typography>
          </Link>
        </Form.Item>

        <Form.Item className="w-full flex items-center justify-center mb-4">
          <Button className="login-button" type="primary" htmlType="submit" loading={submitting} disabled={formError}>
            LOGIN
          </Button>
        </Form.Item>
        <Form.Item className="w-full flex items-center justify-center">
          <Link to={'/request-access'}>
            <Typography className="link-text">Request Access</Typography>
          </Link>
        </Form.Item>
      </Form>
    </AuthenticationLayout>
  );
};
