import React, { useEffect, useState } from 'react';
import { QuestionIcon } from '../../../assets/icons/question-icon';
import { Button, Input } from 'antd';
import ISearch from '../../../assets/icons/search';
import IArrow from '../../../assets/icons/arrow';
import { TSuggestion } from '../type-helper';
import IArrowWithBorder from '../../../assets/icons/arrow-with-border';

type TChatSuggestionProp = {
  suggestion: TSuggestion[];
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleQuestionAsked: (text?: string) => Promise<void>;
  pretendLoading: boolean;
};

export default function ChatSuggestion(props: TChatSuggestionProp) {
  const { suggestion, open, setOpen, handleQuestionAsked, pretendLoading } = props;
  const [datas, setDatas] = useState<TSuggestion[]>(suggestion);

  useEffect(() => {
    setDatas([...suggestion]);
  }, [suggestion]);
  return (
    <div
      className={`text-black absolute top-0 w-full z-[100] bg-white rounded-lg p-3 transition-all ${open && datas.length > 0 ? 'mt-[-410px] min-h-[390px] max-h-[390px] overflow-scroll' : 'mt-[0px] overflow-hidden min-h-[0px] max-h-[0px]'}`}
    >
      <div className="block md:flex gap-5 items-center relative">
        <div className="flex items-center gap-3">
          <div className="flex items-center">
            <QuestionIcon width="28px" height="28px" />
          </div>
          <h1 className="text-lg font-bold">Suggested Questions:</h1>
        </div>
        <Input
          onChange={(event) => {
            setDatas(
              suggestion.filter((item) => item.question.toLowerCase().includes(event.target.value.toLowerCase()))
            );
          }}
          style={{ height: '34px' }}
          suffix={<ISearch />}
          className="h-full flex-1 bg-white"
          placeholder="Input something to search"
        />
        <Button
          className="p-0 absolute md:relative top-0 right-0"
          type="text"
          onClick={() => setOpen(false)}
          shape="default"
          icon={<IArrow style={{ transform: 'rotate(90deg)' }} width="30px" height="30px" />}
        />
      </div>
      <div className="text-lg mt-3 mx-[-0.75rem] font-normal divide-dashed divide-y-2 divide-2 max-h-[320px] overflow-y-scroll">
        {datas.map((item: TSuggestion, index: number) => (
          <div
            key={item.question + index}
            role="button"
            className="flex px-6 gap-3 items-center on-hover-color"
            style={{ minHeight: '40px', cursor: pretendLoading ?  'not-allowed' : 'pointer'}}
            onClick={() => {
              if (!pretendLoading) {
                handleQuestionAsked(item.question);
                setOpen(false);
              }
            }}
          >
            <h1>{index + 1}.</h1>
            <h1>{item.question}</h1>
            <Button
              className="p-0 flex items-center mr-0 ml-auto"
              type="text"
              disabled={pretendLoading}
              onClick={(e) => {
                e.stopPropagation();
                handleQuestionAsked(item.question);
                setOpen(false);
              }}
              shape="default"
              icon={<IArrowWithBorder />}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
