import React from 'react'
import { TypeIconProps } from './icon';

export const DownloadIcon = (props: TypeIconProps) => {
    const { width, height, className, color } = props;
    return (
        <div>
            <svg
                width={width ?? '12'}
                height={height ?? '12'}
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path fillRule="evenodd" clipRule="evenodd" d="M1.54167 7.58337C1.84082 7.58337 2.08333 7.82589 2.08333 8.12504V10.2917C2.08333 10.4354 2.1404 10.5731 2.24198 10.6747C2.34357 10.7763 2.48134 10.8334 2.625 10.8334H10.2083C10.352 10.8334 10.4898 10.7763 10.5913 10.6747C10.6929 10.5731 10.75 10.4354 10.75 10.2917V8.12504C10.75 7.82589 10.9925 7.58337 11.2917 7.58337C11.5908 7.58337 11.8333 7.82589 11.8333 8.12504V10.2917C11.8333 10.7227 11.6621 11.136 11.3574 11.4408C11.0526 11.7455 10.6393 11.9167 10.2083 11.9167H2.625C2.19402 11.9167 1.7807 11.7455 1.47595 11.4408C1.1712 11.136 1 10.7227 1 10.2917V8.12504C1 7.82589 1.24251 7.58337 1.54167 7.58337Z" fill="#FF5C00" stroke="#FF5C00" strokeWidth="0.1" strokeLinecap="round" strokeLinejoin="round" />
                <path fillRule="evenodd" clipRule="evenodd" d="M3.32528 5.03365C3.53681 4.82212 3.87977 4.82212 4.09131 5.03365L6.41663 7.35897L8.74194 5.03365C8.95348 4.82212 9.29644 4.82212 9.50798 5.03365C9.71951 5.24518 9.71951 5.58815 9.50798 5.79968L6.79964 8.50802C6.58811 8.71955 6.24514 8.71955 6.03361 8.50802L3.32528 5.79968C3.11374 5.58815 3.11374 5.24518 3.32528 5.03365Z" fill="#FF5C00" stroke="#FF5C00" strokeWidth="0.1" strokeLinecap="round" strokeLinejoin="round" />
                <path fillRule="evenodd" clipRule="evenodd" d="M6.41667 1C6.71582 1 6.95833 1.24251 6.95833 1.54167L6.95833 8.125C6.95833 8.42416 6.71582 8.66667 6.41667 8.66667C6.11751 8.66667 5.875 8.42416 5.875 8.125V1.54167C5.875 1.24251 6.11751 1 6.41667 1Z" fill="#FF5C00" stroke="#FF5C00" strokeWidth="0.1" strokeLinecap="round" strokeLinejoin="round" />
            </svg>

        </div>
    )
}
