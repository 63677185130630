import { Modal } from 'antd';

export interface ModalProperty {
    title: string;
    content: string;
    centered?: boolean;
}

const messageConfig = (msg: ModalProperty) => {
    return msg;
}

export const modalMessage = {
    success: (msg: ModalProperty) =>
        Modal.success(messageConfig(msg)),
    info: (msg: ModalProperty) =>
        Modal.info(messageConfig(msg)),
    warning: (msg: ModalProperty) =>
        Modal.warning(messageConfig(msg)),
    error: (msg: ModalProperty) =>
        Modal.error(messageConfig(msg)),
};