import React from 'react';
import { TypeIconProps } from './icon';

export default function IDirectionDownOne(props: TypeIconProps) {
  const { width, height, className, color } = props;
  return (
    <svg
      width={width ?? '15'}
      height={height ?? '16'}
      className={className ?? ''}
      style={{ color: color ?? 'black' }}
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.7">
        <path
          d="M4.375 4.66666L7.5 7.33332L10.625 4.66666"
          stroke="#28303F"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M4 10L7.5 10L11 10" stroke="#28303F" strokeLinecap="round" strokeLinejoin="round" />
      </g>
    </svg>
  );
}
