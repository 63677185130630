import { Button, Popover } from 'antd';
import React from 'react';
import IRobot from '../../../assets/icons/robot';

type TInStructionPopoverProps = {
  children: React.ReactElement;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function InStructionPopover(props: TInStructionPopoverProps) {
  const { children, setOpen, open } = props;
  const handleOpenChange = (value: boolean) => {
    setOpen(value);
  };
  return (
    <Popover
      open={open}
      onOpenChange={handleOpenChange}
      placement="bottom"
      trigger={'contextMenu'}
      content={<Content setOpen={setOpen} />}
    >
      {children}
    </Popover>
  );
}

type TContentProps = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const Content = (props: TContentProps) => {
  const { setOpen } = props;
  return (
    <div>
      <div className="min-w-[230px] min-h-[115px]"></div>
      <div
        className="text-black w-full bg-white rounded-r-lg h-full absolute top-0 left-0 px-3 m-0 flex justify-center flex-col"
        style={{ borderLeft: '4px solid', borderColor: 'var(--primary-color)' }}
      >
        <div className="flex gap-3">
          <div>
            <IRobot />
          </div>
          <div>You can always click here to select or change doc for precise context</div>
        </div>
        <div className="text-center flex justify-end mt-3">
          <Button className="p-0" type="link" style={{ color: 'var(--primary-color)' }} onClick={() => setOpen(false)}>
            <span className="underline">Noted, thanks</span>
          </Button>
        </div>
      </div>
    </div>
  );
};