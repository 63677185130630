import React from 'react';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import { Button } from 'antd';
import IArrow from '../../../assets/icons/arrow';
import ILeftArrow from '../../../assets/icons/left-arrow';
import IRightArrow from '../../../assets/icons/right-arrow';
import PdfViewer from './pdf-viewer';
import { TMultiplePage } from '../type-helper';

type TMultiSourceSideProp = {
    currentPage: number;
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
    setPageNumber: React.Dispatch<React.SetStateAction<number | undefined>>;
    multiplePage: TMultiplePage[];
    showNav: boolean | undefined;
    setShowNav: React.Dispatch<React.SetStateAction<boolean | undefined>>;
    setGoalSearch: React.Dispatch<React.SetStateAction<string[] | undefined>>;
  };
export default function MultiSourceSide (props: TMultiSourceSideProp) {
    const { setShowNav, setGoalSearch, showNav, currentPage, setCurrentPage, setPageNumber, multiplePage } = props;
    const handlePre = () => {
      if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
        setPageNumber(multiplePage[currentPage - 2].page);
        setGoalSearch(multiplePage[currentPage - 2].content);
      }
    };
  
    const handleNext = () => {
      if (currentPage < multiplePage.length) {
        setCurrentPage(currentPage + 1);
        setPageNumber(multiplePage[currentPage].page);
        setGoalSearch(multiplePage[currentPage].content);
      }
    };
  
    const handleChangePage = (i: number) => {
      setCurrentPage(i);
      setPageNumber(multiplePage[i - 1].page);
      setGoalSearch(multiplePage[i - 1].content);
    };
    return (
      <div
        className={
          'fixed transition-all z-[410] bg-gray-200 overflow-y-scroll left-0 top-0 h-full border-black border-r-2 ' +
          (showNav ? 'w-[205px] opacity-100' : 'w-[0px] opacity-0')
        }
      >
        <div className="h-[56px] relative flex items-center justify-center ">
          <div className="flex items-center justify-center gap-3">
            Source:
            <div className="flex justify-between items-center p-1 border-black border-[1px] rounded-lg gap-2">
              <div role="button" className="flex items-center justify-center" onClick={handlePre}>
                <ILeftArrow width={'20px'} height="20px" />
              </div>
              <div>
                {currentPage} / {multiplePage.length}
              </div>
              <div role="button" className="flex items-center justify-center" onClick={handleNext}>
                <IRightArrow width={'20px'} height="20px" />
              </div>
            </div>
          </div>
          <div className="absolute z-[360] right-0 h-full flex justify-end items-center">
            <Button type="text" className="p-0" onClick={() => setShowNav(!showNav)}>
              <IArrow style={{ transform: 'scaleX(-1)', color: 'var(--primary-color)' }} />
            </Button>
          </div>
        </div>
        <div className="overflow-y-scroll">
          {multiplePage.map((item, key) => (
            <div className="px-[20px]" key={item.page + ',' + key}>
              <div
                role="button"
                onClick={() => handleChangePage(key + 1)}
                className="p-[5px]"
                style={{ backgroundColor: currentPage === key + 1 ? 'rgba(255, 92, 0, 0.2)' : 'transparent' }}
              >
                <PdfViewer zoom={0.25} pageNumber={item.page} searchs={item.content} />
              </div>
              <div className="p-3 text-center">
                <span
                  className="p-[3px]"
                  style={{ backgroundColor: currentPage === key + 1 ? 'rgba(255, 92, 0, 0.2)' : 'transparent' }}
                >
                  {key + 1}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };