import React, { useEffect, useState } from 'react'

export default function Times() {
  const [Time, setTime] = useState("");
  useEffect(() => {
    let interval;
    const GetDate = () => {
      let date = new Date();

      const weekdayFormatter = new Intl.DateTimeFormat('en-US', { weekday: 'long' });
      const weekday = weekdayFormatter.format(date);

      // Get the month with abbreviated notation and a dot
      const monthFormatter = new Intl.DateTimeFormat('en-US', { month: 'long' });
      const month = monthFormatter.format(date);

      // Get the day of the month
      const dayFormatter = new Intl.DateTimeFormat('en-US', { day: '2-digit' });
      const day = dayFormatter.format(date);

      // Get the year
      const yearFormatter = new Intl.DateTimeFormat('en-US', { year: 'numeric' });
      const year = yearFormatter.format(date);

      // Get the time in 12-hour format
      const timeFormatter = new Intl.DateTimeFormat('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      });
      const time = timeFormatter.format(date);

      const formattedDate = `${weekday} ${month}.${day}, ${year} ${time.toLowerCase()}`;
      setTime(formattedDate);
      clearInterval(interval);
      interval = setInterval(GetDate, (60 - date.getSeconds()) * 1000);
    }
    GetDate();

    return () => clearInterval(interval);
  }, [])
  return (
    <div className='text-sm'
      style={{ color: 'var(--primary-text-color)' }}
    >
      {Time}
    </div>
  )
}
