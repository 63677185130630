import React from 'react';
import { TypeIconProps } from './icon';

export default function ILeftArrow(props: TypeIconProps) {
  const { width, height, className, color } = props;
  return (
    <svg
      width={width ?? '11'}
      height={height ?? '13'}
      className={className ?? ''}
      style={{ color: color ?? 'black' }}
      viewBox="0 0 11 13"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M6.3335 3.79166L3.66683 6.49999L6.3335 9.20832"
          stroke="#28303F"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}
