import React from 'react'
import { TypeIconProps } from './icon';

export const ViewIcon = (props: TypeIconProps) => {
    const { width, height, className, color } = props;
    return (
        <div>
            <svg
                width={width ?? '12'}
                height={height ?? '10'}
                viewBox="0 0 12 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M5.99739 0C3.09248 0 0.65545 2.00515 -0.00522327 4.70611C0.325147 6.04857 1.096 7.24154 2.18413 8.09436C3.27227 8.94718 4.61488 9.41062 5.99739 9.41062C7.3799 9.41062 8.72251 8.94718 9.81064 8.09436C10.8988 7.24154 11.6696 6.04857 12 4.70611C11.6699 3.36336 10.8992 2.17003 9.81106 1.31689C8.72289 0.463762 7.38012 8.87481e-05 5.99739 0ZM5.99739 8.44821C4.87114 8.448 3.77519 8.08343 2.87323 7.40897C1.97127 6.7345 1.31171 5.78635 0.99307 4.70611C1.31232 3.62652 1.97215 2.67912 2.87405 2.00531C3.77596 1.33151 4.87158 0.967437 5.99739 0.967437C7.1232 0.967437 8.21882 1.33151 9.12073 2.00531C10.0226 2.67912 10.6825 3.62652 11.0017 4.70611C10.6831 5.78635 10.0235 6.7345 9.12155 7.40897C8.21959 8.08343 7.12363 8.448 5.99739 8.44821ZM6.00167 6.94863C6.59824 6.94863 7.17038 6.71164 7.59222 6.28981C8.01406 5.86797 8.25105 5.29583 8.25105 4.69926C8.25105 4.10269 8.01406 3.53055 7.59222 3.10871C7.17038 2.68687 6.59824 2.44989 6.00167 2.44989C5.4051 2.44989 4.83297 2.68687 4.41113 3.10871C3.98929 3.53055 3.7523 4.10269 3.7523 4.69926C3.7523 5.29583 3.98929 5.86797 4.41113 6.28981C4.83297 6.71164 5.4051 6.94863 6.00167 6.94863ZM6.00167 5.98462C5.66078 5.98462 5.33384 5.84919 5.09279 5.60814C4.85174 5.36709 4.71632 5.04016 4.71632 4.69926C4.71632 4.35836 4.85174 4.03143 5.09279 3.79038C5.33384 3.54932 5.66078 3.4139 6.00167 3.4139C6.34257 3.4139 6.66951 3.54932 6.91056 3.79038C7.15161 4.03143 7.28703 4.35836 7.28703 4.69926C7.28703 5.04016 7.15161 5.36709 6.91056 5.60814C6.66951 5.84919 6.34257 5.98462 6.00167 5.98462Z" fill="#FF5C00" />
            </svg>
        </div>
    )
}
