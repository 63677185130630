import React from 'react';
import { TypeIconProps } from './icon';

export default function IPlane(props: TypeIconProps) {
  const { width, height, className, color } = props;
  return (
    <svg
      width={width ?? '20'}
      height={height ?? '20'}
      className={className ?? ''}
      style={{ color: color ?? 'var(--primary-color)' }}
      viewBox="0 0 18 17"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.87967 16.1591C1.1177 16.5 0.298145 15.8124 0.501397 15.0028L1.79821 9.83721C1.90412 9.41532 2.27061 9.11029 2.70472 9.08271L11.6111 8.517L2.70005 7.90382C2.26822 7.87411 1.90453 7.56994 1.79892 7.15017L0.502745 1.99826C0.299021 1.18852 1.11871 0.500475 1.88089 0.84145L16.9596 7.58718C17.7484 7.94008 17.7484 9.05991 16.9596 9.41282L1.87967 16.1591Z"
        fill="white"
      />
    </svg>
  );
}
