import React, { useEffect } from 'react';
import { SideNavigation } from './side-navigation.component';
import { ApplicationHeader } from './application-header.component';
import { ApplicationContent } from './application-content.component';
import { useBoundStore } from '../../states/bound.store';
import { IPAD, MOBILE, PC } from '../../utils/enum.util';
import BackDropBackground from './back-drop-background';

export default function DefaultPage() {
  const preventReload = useBoundStore().preventReload;
  const displaySideNav = useBoundStore().displaySideNav;
  const device = useBoundStore((state) => state.device);
  const setDevice = useBoundStore((state) => state.setDevice);
  const toggleWideMode = useBoundStore((state) => state.toggleWideMode);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth && window.innerWidth <= 650) {
        if (device !== MOBILE) {
          toggleWideMode(true);
          setDevice(MOBILE);
        }
      } else if (window.innerWidth && window.innerWidth <= 1023) {
        if (device !== IPAD) {
          toggleWideMode(true);
          setDevice(IPAD);
        }
      } else {
        setDevice(PC);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      // Show a warning message
      const warningMessage = 'Are you sure you want to leave? Your uploading file will be cancel';
      event.returnValue = warningMessage; // Standard way to set the return value
      return warningMessage; // For some browsers
    };

    if (preventReload) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    } else {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [preventReload]);

  return (
    <>
      <BackDropBackground />
      <SideNavigation />
      <main
        className={`w-full relative overflow-x-hidden flex flex-col md:w-[calc(100%-256px)] md:ml-64 bg-gray-200 min-h-screen transition-all ${displaySideNav ? '' : 'active'}`}
        style={{color: 'var(--primary-text-color)', backgroundColor: 'var(--primary-bg-dark)', height: '100dvh'}}
      >
        <ApplicationHeader />
        <ApplicationContent />
      </main>
    </>
  );
}
