import React from 'react'
import { TypeIconProps } from './icon';

export const FileIcon = (props: TypeIconProps) => {
    const { width, height, className, color } = props;
    return (
        <svg
            width={width ?? '17'}
            height={height ?? '19'}
            viewBox="0 0 17 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M8.5 18H3C1.89543 18 1 17.1046 1 16V3C1 1.89543 1.89543 1 3 1H14C15.1046 1 16 1.89543 16 3V11M8.5 18L16 11M8.5 18V13C8.5 11.8954 9.39543 11 10.5 11H16" stroke="black" strokeWidth="1.2" />
            <path d="M5 5H12" stroke="black" strokeWidth="1.2" stroke-linecap="round" />
            <path d="M5 8.5H9" stroke="black" strokeWidth="1.2" stroke-linecap="round" />
        </svg>
    )
}
