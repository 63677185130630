import { Button } from 'antd';
import React from 'react';

type TypeOnErrorProp = {
  handleTryAgain: () => void;
};

export default function OnError(props: TypeOnErrorProp) {
  const { handleTryAgain } = props;
  return (
    <div className="w-full h-[calc(98vh-60px-3rem)] flex flex-col gap-3 justify-center items-center">
      <div>Failed to load file</div>
      <Button
        style={{ borderColor: "white", backgroundColor: 'var(--primary-color)', color: 'var(--primary-text-color)', height: '35px' }}
        onClick={handleTryAgain}
      >
        Try again
      </Button>
    </div>
  );
}
