import { Button, Input, Spin } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import IPdf from '../../assets/icons/pdf';
import IArrow from '../../assets/icons/arrow';
import IRobot from '../../assets/icons/robot';
import IPlane from '../../assets/icons/plane';
import './chatbot.css';
import ChatSuggestion from './suggestion/chat-suggestion';
import { TSuggestion, TFile, TSourceDocument, TChat, TChatResponse } from './type-helper';
import InStructionPopover from './components/instruction-popover';
import SelectFile from './select-file/select-file';
import ResponseChat from './response-chat/response-chat';
import { useBoundStore } from '../../states/bound.store';
import { BackendAPI } from '../../constants/backend-api.enum';
import { differenceBy, uniqBy } from 'lodash';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { PrintPdf } from './components/print-pdf';
import { DocumentResponse } from 'bridge/sop-bot';
import httpClient from '../../utils/http-client.util';
import ClearHistory from './components/clear-history';

export default function Chatbot() {
  const [finishLoading, setFinishLoading] = useState(true);
  const messagesEndRef = useRef<null | HTMLDivElement>(null);
  const [demoChatHistory, setDemoChatHistory] = useState<TChat[]>([]);
  const [pretendLoading, setPretendLoading] = useState<boolean>(false);
  const [currentQuestion, setCurrentQuestion] = useState<string | undefined>(undefined);
  const [currentAnswer, setCurrentAnswer] = useState<TChat | undefined>(undefined);
  const [sourceDocuments, setSourceDocuments] = useState<TSourceDocument[]>([]);
  const [question, setQuestion] = useState<string>('');
  const [selectedFiles, setSelectedFiles] = useState<TFile[]>([]);
  const [suggestionQuestionOpen, setSuggestionQuestionOpen] = useState(false);
  const [backgroundSelectedFile, setBackgroundSelectedFile] = useState(false);
  const [selectFileOpen, setSelectFileOpen] = useState(false);
  const [inStuctionPopoverOpen, setInStuctionPopoverOpen] = useState(false);
  const [files, setFiles] = useState<TFile[]>([]);
  const [selectFileLoading, setSelectFileLoading] = useState(true);
  const user = useBoundStore().user;
  const DiableBackDropOpen = useBoundStore().DiableBackDropOpen;
  const [suggestion, setSuggestion] = useState<TSuggestion[]>([]);
  const chatHistory = useBoundStore((state) => state.chatHistory);
  const setChatHistory = useBoundStore((state) => state.setChatHistory);

  useEffect(() => {
    (async () => {
      try {
        setSelectFileLoading(true);
        const resDoc = await httpClient.get<DocumentResponse>(`${BackendAPI.GET_LIST_DOC}/${user?.userId}`);
        if (resDoc && resDoc.data && resDoc.status === 200) {
          const fileData: string[] = Object.keys(resDoc.data.file_metadata);

          let newDoc = fileData.filter(item => resDoc.data.file_metadata[item].status === "SUCCESS").map((item: any) => {
            return {
              file_name: resDoc.data.file_metadata[item].file,
            };
          });
          setFiles([...newDoc]);
          setSelectFileLoading(false);
          if (fileData.length === 0) {
            getSelectedFileCurrentUser();
          }
        }
      } catch (e) {
        setSelectFileLoading(false);
        console.error('Unable to fetch data');
      }
    })();
    setDemoChatHistory([...chatHistory]);
  }, []);

  useEffect(() => {
    if (files.length > 0) {
      getSelectedFileCurrentUser();
    }
  }, [files]);

  const getSelectedFileCurrentUser = async () => {
    const resDoc = await httpClient
      .post(BackendAPI.GET_CURRENT_LIST_FILE, { user: user?.userId })
      .then((res) => res.data)
      .catch((reason) => ({ status: 400, message: 'Network Error' }));
    let keysResDoc = Object.keys(resDoc);

    let questionAsked: TSuggestion[] = [];
    const selectedFiles: TFile[] = keysResDoc.map((i: string) => {
      resDoc[i].forEach((q: string) => {
        questionAsked.push({
          question: q,
        });
      });
      return { file_name: i };
    });
    const selected = differenceBy(
      selectedFiles,
      differenceBy(selectedFiles, files, (i) => i.file_name),
      (i) => i.file_name
    );
    if (selected.length === 0) {
      setBackgroundSelectedFile(true);
      setSelectFileOpen(true);
    }
    setSelectedFiles([...selected]);
    setSuggestion([...uniqBy(questionAsked, (item: TSuggestion) => item.question.toLowerCase().replace(/\s+/g, ''))]);
  };

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ block: "end" });
  }, [chatHistory, pretendLoading, demoChatHistory]);

  const handleQuestionAsked = async (text?: string) => {
    try {
      setSuggestionQuestionOpen(false);
      setCurrentQuestion(text ?? question);
      setFinishLoading(false);
      setQuestion('');
      setDemoChatHistory([...chatHistory]);
      setCurrentAnswer(undefined);
      setPretendLoading(true);
      //API Question
      let payload = {
        question: text ?? question,
        user: user?.userId,
      };
      let response = await httpClient.post(BackendAPI.CHAT_QUESTION, payload);
      if (response.status === 200) {
        const json: TChatResponse = response.data;
        const chat_history: TChat[] = json.answer.chat_history;
        setCurrentAnswer(chat_history[chat_history.length - 1]);
        setChatHistory([...chat_history]);
        setDemoChatHistory([...chat_history.slice(0, chat_history.length - 1)]);
        setSourceDocuments([...chat_history[chat_history.length - 1].source_documents]);
      } else {
        setCurrentQuestion("");
      }
      setPretendLoading(false);
    } catch (err) {
      setPretendLoading(false);
      console.log('err', err);
    }
  };

  const clearHistory = () => {
    setCurrentAnswer(undefined);
    setChatHistory([]);
    setDemoChatHistory([]);
    setSourceDocuments([]);
  }

  const handleSelectedFile = async (listFile: TFile[], fn: () => void) => {
    //API Select Files
    let newListFiles = listFile.map((item) => item.file_name);
    let payload = {
      files: newListFiles.length > 0 ? newListFiles : [''],
      user: user?.userId,
    };
    let resDoc = await httpClient
      .post(BackendAPI.SELECT_FILE_CONTEXT, payload)
      .then((res) => res.data)
      .catch((reason) => ({ status: 400, message: 'Network Error' }));
    if (resDoc.status && resDoc.status === 400) {
      console.log(resDoc);
    } else {
      let keysResDoc = Object.keys(resDoc);
      let questionAsked: TSuggestion[] = [];
      const selectedFiles: TFile[] = keysResDoc.map((i: string) => {
        resDoc[i].forEach((q: string) => {
          questionAsked.push({
            question: q,
          });
        });
        return { file_name: i };
      });
      const selected = differenceBy(
        selectedFiles,
        differenceBy(selectedFiles, files, (i) => i.file_name),
        (i) => i.file_name
      );
      fn();
      setSelectedFiles([...selected]);
      setSuggestion([...uniqBy(questionAsked, (item: TSuggestion) => item.question.toLowerCase().replace(/\s+/g, ''))]);
      if (selected.length > 0) {
        setInStuctionPopoverOpen(true);
      }
    }
  };

  const handleChange = (event: any) => {
    setQuestion(event.target.value);
  };

  const handleBackgroundSelectClose = () => {
    if (selectedFiles.length > 0 && !DiableBackDropOpen) {
      setBackgroundSelectedFile(false);
      setSelectFileOpen(false);
    }
  };

  return (
    <div className="flex flex-col h-full">
      {backgroundSelectedFile && (
        <div
          className={`absolute top-0 z-[400] left-0 w-full h-full bg-[rgba(0,0,0,0)]`}
          onClick={handleBackgroundSelectClose}
        ></div>
      )}
      <div className="block md:flex justify-between items-center">
        <div className='flex gap-3'>
          <h1 className="font-bold text-lg">Chat with your SOPs</h1>
        </div>
        <div className="flex gap-3 mt-3 md:mt-0 items-center">
          <ClearHistory
            clearHistory={clearHistory}
          />
          <SelectFile
            selectFileLoading={selectFileLoading}
            originalSelectedFiles={selectedFiles}
            handleStartChat={handleSelectedFile}
            files={files}
            open={selectFileOpen}
            setBackgroundSelectedFile={setBackgroundSelectedFile}
            setOpen={setSelectFileOpen}
          />
          <InStructionPopover open={inStuctionPopoverOpen} setOpen={setInStuctionPopoverOpen}>
            <Button
              loading={selectFileLoading}
              onClick={() => {
                setBackgroundSelectedFile(true);
                setSelectFileOpen(true);
              }}
              className="flex items-center justify-center"
              iconPosition="end"
              style={{ backgroundColor: 'var(--primary-color)', color: 'var(--primary-text-color)', height: '35px' }}
              icon={<IArrow style={{ color: 'var(--primary-text-color)' }} />}
            >
              Select Doc: {selectedFiles.length} doc(s)
            </Button>
          </InStructionPopover>
          <PDFDownloadLink
            document={<PrintPdf user={user} chatHistory={chatHistory} />}
            fileName={'Chat history - ' + new Date().getTime()}
          >
            {({ loading }) => (
              <Button
                disabled={loading}
                className="flex items-center justify-center p-0"
                style={{
                  backgroundColor: !loading ? 'var(--primary-color)' : 'var(--disabled-bg-color)',
                  width: '35px',
                  height: '35px',
                }}
              >
                <IPdf />
              </Button>
            )}
          </PDFDownloadLink>
        </div>
      </div>
      <div className="flex-1 flex flex-col gap-[30px] mt-5 mb-3 overflow-y-scroll max-h-[71vh]">
        <div className="flex gap-3">
          <div className="w-[35px] h-[35px]">
            <IRobot width="35px" height="35px" />
          </div>
          <h1 className="content-center font-normal">Please select doc for precise context first in right tab</h1>
        </div>
        <div className="flex gap-3">
          <div className="w-[35px] h-[35px]">
            <IRobot width="35px" height="35px" />
          </div>
          <h1 className="content-center font-normal">How can I help you?</h1>
        </div>
        {demoChatHistory.map((item, index) => (
          <div key={item.answer + item.question + index}>
            <ResponseChat chat={item} finishLoading={true} setFinishLoading={() => { }} />
          </div>
        ))}
        {(pretendLoading || currentAnswer) && (
          <div>
            <ResponseChat
              messagesEndRef={messagesEndRef ?? undefined}
              pretendLoading={pretendLoading}
              animationAnswer={true}
              chat={currentAnswer}
              sourceDocuments={sourceDocuments}
              question={currentQuestion}
              finishLoading={finishLoading}
              setFinishLoading={setFinishLoading}
            />
            <div ref={messagesEndRef}></div>
          </div>
        )}
        {!(pretendLoading || currentAnswer) && <div ref={messagesEndRef}></div>}
      </div>
      <div className="mb-0 mt-auto">
        <div
          className="flex relative bg-dark botto-0"
          tabIndex={1}
          onBlur={(e) => {
            if (!e.currentTarget.contains(e.relatedTarget)) {
              setSuggestionQuestionOpen(false);
            }
          }}
        >
          <ChatSuggestion
            open={suggestionQuestionOpen}
            setOpen={setSuggestionQuestionOpen}
            handleQuestionAsked={handleQuestionAsked}
            suggestion={suggestion}
            pretendLoading={pretendLoading}
          />
          <Input
            value={question}
            style={{ height: '40px' }}
            placeholder="Ask me anything!"
            onPressEnter={() => {
              if (question !== '' && selectedFiles.length !== 0 && !pretendLoading && finishLoading) {
                handleQuestionAsked();
                setSuggestionQuestionOpen(false);
              }
            }}
            onFocus={() => {
              setSuggestionQuestionOpen(true);
            }}
            className="z-[150] pl-[1.5rem] py-[0.5rem] pr-[80px] border-black custom-chat-input"
            onChange={handleChange}
          />
          <Button
            disabled={question === '' || selectedFiles.length === 0 || pretendLoading || !finishLoading}
            onClick={() => handleQuestionAsked()}
            className="border-0 z-[200]  px-6 absolute right-0 top-0 flex justify-center items-center"
            style={{
              height: '40px',
              backgroundColor:
                selectedFiles.length > 0 && !pretendLoading && finishLoading ? 'var(--primary-color)' : 'var(--disabled-bg-color)',
            }}
          >
            <IPlane />
          </Button>
        </div>
      </div>
    </div>
  );
}
