import { Button, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { useBoundStore } from '../../../states/bound.store';
import { BackendAPI } from '../../../constants/backend-api.enum';
import httpClient from '../../../utils/http-client.util';

type ClearHistoryType = {
    clearHistory: () => void
}

export default function ClearHistory(props: ClearHistoryType) {
    const { clearHistory } = props;
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const chatHistory = useBoundStore((state) => state.chatHistory);
    const setChatHistory = useBoundStore((state) => state.setChatHistory);
    const user = useBoundStore((state) => state.user);

    const handleClearHistory = async () => {
        setLoading(true);
        setDisabled(true);
        const res = await httpClient
            .post(BackendAPI.CLEAR_HISTORY, { user: user?.userId })
            .then((res) => res)
            .catch((reason) => ({ status: 400, message: 'Network Error' }));

        if (res.status === 200) {
            message.success('History has been cleared!');
            setChatHistory([]);
            clearHistory();
        } else {
            message.error('Clear History failed!');
        }
        setLoading(false);
    }

    useEffect(() => {
        setDisabled(chatHistory.length === 0);
    }, [chatHistory])

    return (
        <Button
            className="flex items-center justify-center"
            iconPosition="end"
            style={{
                backgroundColor: !loading && !disabled ? 'var(--primary-color)' : 'var(--disabled-bg-color)',
                color: 'black',
                height: '35px'
            }}
            onClick={handleClearHistory}
            disabled={disabled}
            loading={loading}
        >Clear History</Button>
    )
}
