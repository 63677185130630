import { StateCreator } from 'zustand';
import { LoginResponse } from 'bridge/authentication';

export interface User extends LoginResponse {}

export interface LibraryDocumentSlice {
  documents: any;
  setDocuments: (documents: any) => void;
}

export const createLibraryDocumentSlice: StateCreator<
  LibraryDocumentSlice,
  [['zustand/devtools', never]],
  [],
  LibraryDocumentSlice
> = (set) => ({
  documents: [],
  setDocuments: (documents: any) => set({ documents }),
});
