import React from 'react'
import { TypeIconProps } from './icon';

export const CloseCircleIcon = (props: TypeIconProps) => {
    const { width, height, className, color } = props;
    return (
        <svg
            width={width ?? '20'}
            height={height ?? '20'}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M10 20C4.48671 20 0 15.5143 0 9.99973C0 4.48624 4.48669 0 10 0C15.5133 0 20 4.48624 20 9.99973C20 15.5143 15.5133 20 10 20Z" fill="#FF5C00" />
            <path d="M13.374 13.8893C13.242 13.8893 13.11 13.8401 13.0094 13.7394L6.26035 6.99132C6.05905 6.79002 6.05905 6.46345 6.26035 6.26217C6.46165 6.06089 6.78821 6.06089 6.98948 6.26217L13.7385 13.0103C13.9398 13.2116 13.9398 13.5381 13.7385 13.7394C13.6379 13.8401 13.5059 13.8893 13.374 13.8893Z" fill="white" stroke="white" strokeWidth="0.3" />
            <path d="M6.62489 13.8893C6.49295 13.8893 6.36099 13.8401 6.26035 13.7394C6.05905 13.5381 6.05905 13.2116 6.26035 13.0103L13.0094 6.26217C13.2107 6.06089 13.5372 6.06089 13.7385 6.26217C13.9398 6.46345 13.9398 6.79002 13.7385 6.99132L6.98951 13.7394C6.88882 13.8401 6.75688 13.8893 6.62489 13.8893Z" fill="white" stroke="white" strokeWidth="0.3" />
        </svg>
    )
}
