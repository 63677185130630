import React from 'react'
import { TypeIconProps } from './icon';

export const QuestionIcon = (props: TypeIconProps) => {
    const { width, height, className, color } = props;
    return (
        <svg
            width={width ?? '12'}
            height={height ?? '14'}
            viewBox="0 0 12 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_189_5928)">
                <path d="M4.52911 13.0562H7.55411" stroke="#FF5C00" strokeLinecap="round" />
                <path d="M4.58549 11.7279C4.08228 11.7279 3.67342 11.3269 3.67342 10.8334V10.3584L3.64197 10.3399C2.83684 9.9327 2.17008 9.30963 1.7109 8.55084C1.23914 7.76121 0.993828 6.86054 1.00012 5.94753C1.00012 3.21467 3.25827 1 6.03851 1C8.81875 1 11.0832 3.21467 11.0832 5.94136C11.0895 6.85437 10.8442 7.75504 10.3787 8.54467C9.91952 9.30963 9.24648 9.9327 8.44763 10.3337L8.41618 10.3522V10.8272C8.41618 11.3207 8.00732 11.7217 7.50411 11.7217L4.58549 11.7279ZM7.55443 10.8889V10.0622C7.56701 9.90802 7.66136 9.7723 7.81233 9.71061C9.30309 9.07521 10.2277 7.63166 10.2277 5.94753C10.2026 3.68351 8.31554 1.86366 6.00077 1.88217C3.72374 1.90684 1.88074 3.71436 1.85558 5.94753C1.85558 7.63166 2.78022 9.06904 4.27099 9.71061C4.43453 9.77847 4.53517 9.93887 4.52888 10.1116V10.8951L7.55443 10.8889Z" fill="#FF5C00" stroke="#FF5C00" strokeWidth="0.1" />
                <path d="M5.5194 8.77477C5.5194 8.99833 5.71868 9.17356 5.95782 9.17356C6.2036 9.17356 6.39624 8.99229 6.39624 8.77477C6.39624 8.5512 6.19696 8.37597 5.95782 8.37597C5.71868 8.37597 5.5194 8.5512 5.5194 8.77477ZM5.95782 7.78383H5.9246C5.71868 7.7657 5.57254 7.60256 5.58582 7.41525C5.58582 7.41525 5.58582 7.41524 5.58582 7.4092C5.71868 6.91977 6.03089 6.49077 6.47595 6.18865C7.14023 5.58442 7.15351 5.38502 7.1668 5.18563C7.18009 4.94393 7.08044 4.70828 6.89445 4.5391C6.66859 4.32762 6.36303 4.21281 6.03753 4.21281C5.4264 4.21281 4.92819 4.66599 4.92819 5.22188C4.92819 5.40919 4.76212 5.56025 4.5562 5.56025C4.35027 5.56025 4.1842 5.40919 4.1842 5.22188C4.19085 4.29741 5.02119 3.5542 6.04417 3.5542C6.56895 3.5542 7.06716 3.74755 7.43251 4.09197C7.75136 4.39408 7.91743 4.80496 7.8975 5.22188C7.87093 5.65693 7.72479 6.00134 6.98744 6.666C6.61545 7.00437 6.37631 7.25814 6.35638 7.46963C6.34974 7.64485 6.18367 7.78987 5.99103 7.78987L5.95782 7.78383Z" fill="#FF5C00" stroke="#FF5C00" strokeWidth="0.2" strokeMiterlimit="10" />
            </g>
            <defs>
                <clipPath id="clip0_189_5928">
                    <rect width="12" height="14" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}
