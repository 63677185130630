import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import './library.page.css';
import {
  ConfigProvider,
  Divider,
  Typography,
  UploadProps,
  Upload,
  message,
  UploadFile,
  Progress,
  Popconfirm,
  Spin,
} from 'antd';
import { Button } from 'antd/lib';
import { DeleteIcon } from '../../assets/icons/delete-icon';
import { DataTable, DataTableColumn, DataTableSetting } from '../../components/data-table/data-table.component';
import { DownloadIcon } from '../../assets/icons/download-icon';
import { ViewIcon } from '../../assets/icons/view-icon';
import { QuestionIcon } from '../../assets/icons/question-icon';
import { BackendAPI } from '../../constants/backend-api.enum';
import IRobot from '../../assets/icons/robot';
import { DisplayHandle, PopoverDisplay } from '../../components/popover/popover-display';
import { PlusOutlined } from '@ant-design/icons';
import { useBoundStore } from '../../states/bound.store';
import axios, { CancelTokenSource } from 'axios';
import { SuccessIcon } from '../../assets/icons/success-icon';
import { FileIcon } from '../../assets/icons/file-icon';
import { popMessage } from '../../utils/pop-message.util';
import { DeleteDocument, DocumentResponse, FileDocumentTypes } from 'bridge/sop-bot';
import { SuggestedQuestion, SuggestedQuestionDisplay } from './components/suggested-question';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { bytesToMegabytes, convertTimestampToDate, downloadFile, getUserByUserId, paginationItemRender } from '../../utils/utils.util';
import { DownloadAllDoc } from './components/download-all-doc';
import { DeleteAllDoc } from './components/delete-all-doc';
import httpClient from '../../utils/http-client.util';
import { TUser } from '../users-management/type-helper';
import { IPAD, MOBILE, PC } from '../../utils/enum.util';
import _ from 'lodash';
import { getJson, setJson } from '../../utils/storage.util';
import dayjs from 'dayjs';
import LoadingProgress from './components/loading-progress';
import CancelDocComponent from './components/cancel-doc';
import IArrow from '../../assets/icons/arrow';

const { Dragger } = Upload;
export type DocContextType = {
  fetchDocumentData: () => void;
};

const DocContext = createContext<DocContextType | null>(null);

export const LibraryPage = () => {
  const setUploadingFiles = useBoundStore().setUploadingFiles;
  const setPreventReload = useBoundStore().setPreventReload;
  const [allDocuments, setAllDocuments] = useState<UploadFile[]>([]);
  const [listLoading, setListLoading] = useState<boolean>(false);
  const popoverRef = useRef<DisplayHandle>(null);
  const questionPopoverRef = useRef<DisplayHandle>(null);
  const suggestedQuestionRef = useRef<SuggestedQuestionDisplay>(null);
  const setDocuments = useBoundStore((state) => state.setDocuments);
  const subRights = useBoundStore().subRights;
  const user = useBoundStore().user;
  const [showDocuments, setShowDocuments] = useState<UploadFile | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [userData, setUserData] = useState<TUser[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedDocuments, setSelectedDocuments] = useState<UploadFile[]>([]);
  const device = useBoundStore().device;

  useEffect(() => {
    setJson("list-doc", allDocuments);
    setUploadingFiles([...allDocuments]);
  }, [allDocuments])


  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  useEffect(() => {
    let dataSelected = allDocuments.filter((item: any) => selectedRowKeys.includes(item.key));
    setSelectedDocuments(dataSelected)
  }, [selectedRowKeys])

  const currentTableSettings: DataTableSetting<any> = {
    numbered: false,
    numberedWidth: 100,
    pagination: {
      pageSize: 6,
      itemRender: paginationItemRender
    },
    size: 'large',
    operationColumnWidth: 180,
    rowSelection: {
      selectedRowKeys,
      onChange: onSelectChange
    },
    scroll: {x: 550}
  };

  const fetchDocumentData = async () => {
    setListLoading(true);
    //API Get Documents
    await httpClient
      .get<DocumentResponse>(`${BackendAPI.GET_LIST_DOC}/${user?.userId}`)
      .then((res) => {
        if (res.status === 200) {
          setListLoading(false);
          const fileData: string[] = Object.keys(res.data.file_metadata);
          if (fileData.length < 1) {
            popoverRef.current?.open();
            questionPopoverRef.current?.open();
          }
          let newDocs: any;
          if (res.data.file_metadata) {
            newDocs = fileData.filter(item => {
              const tempData = res.data.file_metadata[item];
              return tempData.status === "SUCCESS" || tempData.user === user?.userId && tempData.status === "INPROGRESS"
            }).map((item, index) => {
              return {
                key: `${res.data.file_metadata[item].file}`,
                name: res.data.file_metadata[item].file,
                size: res.data.file_metadata[item].file_size,
                user: res.data.file_metadata[item].user,
                file_timestamp: res.data.file_metadata[item].file_timestamp,
                task_id: res.data.file_metadata[item].task_id,
                task_status: res.data.file_metadata[item].status,
                status: res.data.file_metadata[item].status === "INPROGRESS" ? "uploading" : ""
              };
            });
          }
          setAllDocuments(getTotalDocuments(newDocs));
          setDocuments(res.data);
          setSelectedRowKeys([]);
          setSelectedDocuments([]);
          setJson("list-doc", res.data);
        }
      })
      .catch((err) => {
        setListLoading(false);
      });
  };

  const getTotalDocuments = (allDoc: UploadFile[]) => {
    const globalDoc : UploadFile[] = allDocuments;
    const tempGlobal = globalDoc.filter(i => i.status === "uploading");
    const newGlobal = [...allDoc, ...tempGlobal];
    return newGlobal;
  }

  const getUserData = async (fn?: () => void) => {
    const res = await httpClient
      .get(BackendAPI.USER)
      .then((res) => res.data)
      .catch((reason) => ({ status: 400, message: 'Network Error' }));

    if (res.status === 200) {
      setUserData([...res.data]);
    }
  };

  useEffect(() => {
    fetchDocumentData();
    getUserData();
  }, []);

  const checkExistFile = (fileName: string, uid: string) => {
    return allDocuments.filter((i: UploadFile) => i.name === fileName && i.uid !== uid).length > 0;
  }

  const handleUpload = async (options: any) => {
    const { onSuccess, onError, file } = options;
    const uid = file.uid as string;
    if (checkExistFile(file.name, uid)) {
      message.error(`File ${file.name} already exist`);
      return setAllDocuments(pre => {
        const temp = ([...pre.filter(i => i.uid !== uid)])
        return temp;
      });
    }
    setPreventReload(true);
    const formData = new FormData();
    if (user) {
      formData.append('req', file);
      formData.append('user', user.userId);
    }

    try {
      //API Upload Documents
      let response = await httpClient.post<{ task_id: string }>(BackendAPI.UPLOAD_DOC, formData);
      if (response.status === 200) {
        const showThisDoc = allDocuments.filter((i) => i.uid === file.uid);
        const checkFirst = allDocuments.filter((i: UploadFile) => i.status === "done");
        const checkExist = allDocuments.filter((i: UploadFile) => i.status === undefined);
        if (showThisDoc.length > 0 && checkFirst.length === 0 && checkExist.length === 0) {
          setShowDocuments(pre => pre ?? showThisDoc[0]);
          setCurrentPage(1);
        }
        const data: UploadFile[] = getJson("list-doc");
        let check = false;
        const temp : UploadFile[] = data.map(i => {
          if (i.status === "uploading" && i.uid !== uid) {
            check = true;
          }
          return i.uid === uid ? {...i, 
            status: "uploading",
            task_id: response.data.task_id
          } : i
        });
        setPreventReload(check);
        setAllDocuments([...temp]);
        setDocuments(response.data);
      } else {
        message.error('File upload failed');
        onError(new Error('File upload failed'));
      }
    } catch (err) {}
  };

  const onChangeUpload: UploadProps['onChange'] = (info) => {
    let files = [...info.fileList];
    let newFiles = files.map((item) => item.status === 'done' ? ({ ...item, user: user?.userId, key: item.name ,file_timestamp: dayjs().utc().format('YYYY-MM-DDTHH:mm:ss.SSSSSS[+00:00]'), }) : ({ ...item, key: item.name }));
    setAllDocuments([...newFiles]);
  };

  const uploadProps: UploadProps = {
    multiple: true,
    accept: '.pdf, .docx, .doc',
    disabled: subRights.includes('Edit') ? false : true,
    onChange: onChangeUpload,
    customRequest: handleUpload,
    showUploadList: false,
  };

  const handleDeleteDoc = async (fileName: string) => {
    setListLoading(true);
    let payload = {
      files: [fileName],
      user: user?.userId,
    };
    if (showDocuments && allDocuments.length === 1) {
      setShowDocuments(undefined);
    }

    //API Delete Document
    httpClient
      .post<DeleteDocument>(BackendAPI.DELETE_DOC, payload)
      .then((res) => {
        if (res.status === 200) {
          setListLoading(false);
          fetchDocumentData();
          popMessage.success('Deleted Successfully');
        }
      })
      .catch((err) => {
        setListLoading(false);
        return false;
      });
  };
  const handleClickDocuments = (fileName: FileDocumentTypes['name']) => {
    const URI = fileName ? encodeURIComponent(fileName) : "";
    if(URI.includes('.pdf')) {
      window.open(`${process.env.REACT_APP_S3_AWS}${URI}`);
    } else {
      downloadFile(`${process.env.REACT_APP_S3_AWS}${URI}`, URI)
    }
  }

  const columns: DataTableColumn<any>[] = [
    {
      title: 'File Name',
      dataIndex: 'name',
      key: 'name',
      width: 300,
      render: (name: string, record: FileDocumentTypes) => {
        return (
          <div className="flex flex-col">
            <Typography className="text-1-line">{name}</Typography>
            {record && record.status || (record.task_status && record.task_status !== "SUCCESS") ? (
              <LoadingProgress key={record.name} allDocuments={allDocuments} record={record}  setAllDocuments={setAllDocuments}/>
            ) : null}
          </div>
        );
      },
    },
    {
      title: 'Size',
      dataIndex: 'size',
      key: 'size',
      width: 100,
      render: (size: string) => {
        return <span>{size ? `${bytesToMegabytes(size)}MB` : ''}</span>;
      },
    },
    {
      title: 'Upload Time',
      dataIndex: 'file_timestamp',
      key: 'file_timestamp',
      width: 150,
      render: (file_timestamp) => {
          return <span>{!file_timestamp ? "" : dayjs(file_timestamp).format("MM/DD/YYYY")}</span>;
      },
    },
    {
      title: 'Uploader',
      dataIndex: 'user',
      key: 'user',
      width: 200,
      render: (user: string) => {
        let fetchUser = getUserByUserId(user, userData);
        let uploader = fetchUser ? `${fetchUser?.firstName + ' ' + fetchUser?.lastName}` : '';
        return <span>{uploader}</span>;
      },
    },
    {
      title: 'Action(s)',
      dataIndex: 'actions',
      key: 'actions',
      width: 150,
      fixed: 'right',
      render: (name: string, record: FileDocumentTypes, index: number) => {
        return (
          <div className="flex flex-row flex flex-row gap-[10px]">
            {
              record.task_id &&
              (record && ['error', 'uploading'].includes(record && record.status ? record.status : '') ? (
                <>
                  <CancelDocComponent key={record.name} allDocuments={allDocuments} record={record} setAllDocuments={setAllDocuments} setListLoading={setListLoading} />
                </>
              ) : (
                <>
                  <IconSuggestedQuestion questionPopoverRef={questionPopoverRef} record={record} setShowDocuments={setShowDocuments} suggestedQuestionRef={suggestedQuestionRef} showDocuments={showDocuments} />
                  <Button
                    onClick={() => handleClickDocuments(record.name)}
                    type="text"
                    shape="default"
                    icon={<ViewIcon width="20" height="20" />}
                  />
                  {subRights.includes('Edit') ? (
                    <>
                      <Button
                        onClick={() => {
                          const URI = record.name ? encodeURIComponent(record.name) : "";
                          downloadFile(`${process.env.REACT_APP_S3_AWS}${URI}`, URI ? URI : '')
                        }}
                        type="text"
                        shape="default"
                        icon={<DownloadIcon width="20" height="20" />}
                      />
                      <ConfigProvider
                        theme={{
                          token: {
                            colorText: 'black',
                          },
                        }}
                      >
                        <Popconfirm
                          title="Delete the document"
                          description="Are you sure to delete this document?"
                          placement="topRight"
                          icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                          onConfirm={() => handleDeleteDoc(record.name ? record.name : '')}
                        >
                          <Button type="text" shape="default" icon={<DeleteIcon width="20" height="20" />} />
                        </Popconfirm>
                      </ConfigProvider>
                    </>
                  ) : null}
                </>
              ))
          }
          </div>
        );
      },
    },
  ];
  return (
    <DocContext.Provider value={{ fetchDocumentData }}>
      <div className="library-layout">
        <div className="upload-layout page-describe">
          <Typography>
            <Typography.Title level={2} className="title">
              Load your SOPs
            </Typography.Title>
            <Typography.Text className="subtitle" style={{ fontSize: '18px' }}>
              The Chat Box has access to your documents and can respond to user queries by
              referring to the content within these documents
            </Typography.Text>
          </Typography>
          <PopoverDisplay
            contentChildren={
              <div className="flex gap-[20px]">
                <IRobot width="35" height="35" />
                <Typography className="flex flex-col">
                  <Typography.Text className="text-content">
                    We don’t have documents in Library now.
                    <br />
                    Please upload some documents here first.
                    <br />
                    After that, you can use Chat Box.
                  </Typography.Text>
                  <Button
                    className="p-0 flex items-end justify-end font-bold"
                    type="link"
                    style={{ color: 'var(--primary-color)' }}
                    onClick={() => {
                      popoverRef.current?.close();
                    }}
                  >
                    <span className="underline text-content">Noted, thanks</span>
                  </Button>
                </Typography>
              </div>
            }
            placement={`${[MOBILE].includes(device) ? 'bottomLeft' : 'bottom'}`}
            ref={popoverRef}
            display={false}
          >
            <ConfigProvider
              theme={{
                token: {
                  colorBgMask: 'green',
                  colorPrimary: 'var(--primary-text-color)',
                  colorPrimaryHover: 'var(--primary-color)',
                },
              }}
            >
              <Dragger
                className={`custom-dragger w-full md:w-auto ${subRights.includes('Edit') ? '' : 'disabledDiv'}`}
                {...uploadProps}
                fileList={allDocuments}
              >
                <div className="drag-drop-area">
                  <Typography className="flex flex-col items-start">
                    <Typography.Title style={{ fontSize: '22px', color: 'black' }}>
                      Drag and Drop File here
                    </Typography.Title>
                    <Typography.Text style={{ color: 'black' }}>Limit 15MB per file - PDF, DOC, DOCX</Typography.Text>
                  </Typography>
                  <Button
                    icon={<i className="ri-folder-open-line text-[24px]"></i>}
                    size="large"
                    className="sop-button z-10"
                    type="primary"
                  >
                    Browse Files
                  </Button>
                </div>
              </Dragger>
            </ConfigProvider>
          </PopoverDisplay>
        </div>

        <div className="list-layout">
          <div className="flex flex-row item-center justify-between">
            <Typography.Text className="title">List of Documents</Typography.Text>
            {subRights.includes('Edit') ? (
              <div className={`flex flex-row flex flex-row gap-[10px] `}>
                <DownloadAllDoc selectedDoc={selectedDocuments} />
                <DeleteAllDoc selectedDoc={selectedDocuments} showDocuments={showDocuments} setShowDocuments={setShowDocuments} allDocuments={allDocuments} />
              </div>
            ) : null}
          </div>
          <Divider className="my-[10px]" style={{ backgroundColor: '#FFFFFF7D' }} />
          <ConfigProvider
            theme={{
              token: {
                colorBgContainer: 'transparent',
                colorText: 'var(--primary-text-color)',
                colorPrimary: '#FF5C00',
              },
              components: {
                Table: {
                  colorBgContainer: 'var(--primary-bg-dark)',
                  colorBorder: 'transparent',
                  headerSplitColor: 'transparent',
                  padding: 10,
                  colorBgBase: 'transparent',
                  filterDropdownBg: 'white',
                  colorText: 'var(--primary-text-color)',
                  headerColor: 'var(--primary-text-color)',
                  borderColor: 'transparent',
                  rowSelectedBg: '#4b372c',
                  rowSelectedHoverBg: '#4b372c',
                  headerBg: 'var(--primary-bg-dark)',
                  rowHoverBg: 'var(--primary-bg-dark)',
                },
                Pagination: {
                  colorBgContainer: 'var(--primary-bg-dark)',
                }
              },
            }}
          >
            <DataTable
              style={{ margin: '0' }}
              data={allDocuments && allDocuments.length > 0 ? allDocuments.sort((a: any, b: any) => {
                if (a.file_timestamp  === "uploading" && b.file_timestamp  === "uploading") return 0;
                if (a.status === "uploading") return -1;
                if (b.status  === "uploading") return 1;
              
                return dayjs(b.file_timestamp).isAfter(dayjs(a.file_timestamp)) ? 1 : -1;
              }) : []}
              setting={currentTableSettings}
              loading={listLoading}
              columnsDef={columns}
              customCurrentPage={currentPage}
              setCustomCurrentPage={setCurrentPage}
            />
          </ConfigProvider>
        </div>
        <SuggestedQuestion ref={suggestedQuestionRef} />
      </div >
    </DocContext.Provider>
  );
};

type TIconSuggestionQuestion = {
  record: FileDocumentTypes;
  showDocuments: UploadFile | undefined;
  setShowDocuments: React.Dispatch<React.SetStateAction<UploadFile | undefined>>;
  suggestedQuestionRef: React.RefObject<SuggestedQuestionDisplay>;
  questionPopoverRef:  React.RefObject<DisplayHandle>;
}

const IconSuggestedQuestion = (props:TIconSuggestionQuestion) => {
  const { record, setShowDocuments, suggestedQuestionRef, showDocuments, questionPopoverRef} = props;
  const [showPopover, setShowPopover] = useState(false);
  const device = useBoundStore().device;
  useEffect(() => {
    if (!showPopover) {
      setShowPopover((showDocuments && (record.uid === showDocuments.uid)) ? true : false);
    }
  }, [showDocuments, showPopover])

  return (
    <PopoverDisplay
          minWidth='min-w-[280px]'
         contentChildren={
           <div className="flex flex-col items-center justify-center gap-[20px] w-[280px]">
             <Typography className="flex flex-row items-center gap-[10px]">
               <SuccessIcon width="24" height="24" />
               <Typography.Text className="text-[16px] font-medium" style={{ color: 'black' }}>
                Upload Successful!
               </Typography.Text>
             </Typography>
             <div
               className="w-full flex flex-row items-center gap-[15px] bg-[#F9F7F7] rounded-[5px]"
               style={{ padding: '10px 20px' }}
             >
               <FileIcon width="32" height="32" />
               <Typography className="flex flex-col items-start">
                 <Typography.Text
                   className="text-[16px] font-medium italic text-1-line"
                   style={{ color: 'black' }}
                 >
                   {record.name}
                 </Typography.Text>
                 <Typography.Text className="text-[12px] font-normal italic" style={{ color: 'black' }}>
                   {bytesToMegabytes(record.size)}MB
                 </Typography.Text>
               </Typography>
             </div>
             <div className="flex flex-row items-center gap-[4px] w-full">
               <IRobot width="24" height="24" />
               <div className="text-nowrap" style={{ color: 'black', fontSize: "12px" }}>
                 You can click
               </div>
               <QuestionIcon width="24" height="24" />
               <div className="text-nowrap" style={{ color: 'black', fontSize: "12px" }}>
                 to add suggested questions
               </div>
             </div>
             <Button
               onClick={() => {
                 setShowDocuments(undefined)
                 setShowPopover(false);
                 suggestedQuestionRef.current?.open(record);
               }}
               size="middle"
               className="sop-button z-10"
               type="primary"
               icon={<PlusOutlined />}
             >
               Add Suggested Questions
             </Button>
           </div>
         }
         display={showPopover}
         ref={questionPopoverRef}
         placement="top"
         setDisplay={(value)=>{
          setShowPopover(value);
          if (value === false) {
            setShowDocuments(undefined);
          }
        }}
         style={{
          inset: `${[PC, IPAD].includes(device) ? 'auto -100px 0 auto' : 'auto -100px 0 auto'}`,
          minWidth: '300px'
        }}
         hasCloseBtn={true}
       >
         <Button
           type="text"
           shape="default"
           icon={<QuestionIcon width="20" height="20" />}
         />
    </PopoverDisplay>
  );
}

export const useDocContext = (): DocContextType => {
  const context = useContext(DocContext);
  if (!context) {
    throw new Error('useUserContext must be used within a ParentProvider');
  }
  return context;
};