import React from 'react';
import { TChat } from '../type-helper';
import { Document, Page, Text, View, Image, StyleSheet } from '@react-pdf/renderer';
import IChatUserIcon from '../../../assets/images/chat-user-icon.png';
import IChatRobotIcon from '../../../assets/images/chat-robot-icon.png';
import { User } from '../../../states/authentication.slice';

type TypePrintPdfProps = {
  chatHistory: TChat[];
  user: User | null;
};

const styles = StyleSheet.create({
  page: {
    width: '100%',
    height: '100%',
    backgroundColor: '#FFFDFD',
    padding: '30px',
    flexDirection: 'column',
  },
  section: {
    flexDirection: 'row',
    marginBottom: 30,
  },
  text: {
    marginLeft: 10,
    marginTop: 6,
    fontSize: 12,
    color: 'black',
    flex: 1,
    fontWeight: 400,
    lineHeight: 1.5,
  },
  icon: {
    objectFit: 'cover',
    width: 25,
    height: 25,
  },
  h1: {
    fontSize: 18,
    color: 'black',
    fontWeight: 600,
    flex: 1,
    textAlign: 'center',
  },
});

export const PrintPdf = (props: TypePrintPdfProps) => {
  const { chatHistory, user } = props;
  return (
    <Document>
      <Page size="A4" style={{ ...styles.page }}>
        <View style={{ ...styles.section }}>
          <Text style={{ ...styles.h1 }}>{user?.name} Chat history</Text>
        </View>
        <View>
          <View style={styles.section}>
            <Image src={IChatRobotIcon} style={styles.icon} />
            <Text style={styles.text}>Please select doc for precise context first in right tab</Text>
          </View>
          <View style={styles.section}>
            <Image src={IChatRobotIcon} style={styles.icon} />
            <Text style={styles.text}>How can I help you?</Text>
          </View>
          {chatHistory.map((chat, index) => {
            return (
              <View key={index + chat.answer + chat.question}>
                <View style={styles.section}>
                  <Image src={IChatUserIcon} style={styles.icon} />
                  <Text style={styles.text}>{chat.question}</Text>
                </View>

                <View style={styles.section}>
                  <Image src={IChatRobotIcon} style={styles.icon} />
                  <Text style={styles.text}>{chat.answer}</Text>
                </View>
              </View>
            );
          })}
        </View>
      </Page>
    </Document>
  );
};
