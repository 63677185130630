import { message, Progress, Spin } from 'antd';
import { FileDocumentTypes } from 'bridge/sop-bot';
import React, { useEffect, useState } from 'react'
import httpClient from '../../../utils/http-client.util';
import { BackendAPI } from '../../../constants/backend-api.enum';
import { UploadFile } from 'antd/lib';
import { getJson } from '../../../utils/storage.util';
import { useBoundStore } from '../../../states/bound.store';
import dayjs from 'dayjs';

type LoadingProgressT = {
    record: FileDocumentTypes,
    allDocuments: UploadFile<any>[],
    setAllDocuments: React.Dispatch<React.SetStateAction<UploadFile<any>[]>>
};

export default function LoadingProgress(props: LoadingProgressT) {
    const [intervalStatus, setIntervalStatus] = useState(false);
    const [percent, setPercent] = useState<number>(0);
    const [data, setData] = useState<FileDocumentTypes | undefined>(undefined);
    const user = useBoundStore().user;
    const setPreventReload = useBoundStore().setPreventReload;
    const { record, setAllDocuments, allDocuments } = props;

    useEffect(() => {
        setData(record);
    }, [record])

    const uploadCompleted = (percent: number) => {
        const uid = data && data.uid as string;
        const localData: UploadFile[] = getJson("list-doc");
        let check = false;
        const temp: UploadFile[] = localData.map(i => {
            if (uid && i.status === "uploading" && i.uid !== uid) {
                check = true;
            }
            return i.uid === uid ? {
                ...i,
                status: "done",
                user: user?.userId,
                file_timestamp: dayjs().utc().format('YYYY-MM-DDTHH:mm:ss.SSSSSS[+00:00]')
            } : i
        });
        setPreventReload(check);
        setAllDocuments([...temp]);
    }

    const uploadFailed = (percent: number) => {
        const uid = data && data.uid as string;
        const localData: UploadFile[] = getJson("list-doc");
        let check = false;
        const temp: UploadFile[] = localData.map(i => {
            if (uid && i.status === "uploading" && i.uid !== uid) {
                check = true;
            }
            return i.uid === uid ? {
                ...i,
                status: "error",
                percent: percent,
                user: user?.userId,
                file_timestamp: dayjs().utc().format('YYYY-MM-DDTHH:mm:ss.SSSSSS[+00:00]')
            } : i
        });
        setPreventReload(check);
        setAllDocuments([...temp]);
        setPercent(100);
    }


    useEffect(() => {
        if (!intervalStatus) {
            if (data && data.task_id && data.percent !== 100) {
                const handleListenUpload = async () => {
                    try {
                        const response = await httpClient.get(BackendAPI.UPLOAD_PROGRESS + `/${data?.task_id}`).then(res => res.data).catch(error => { throw error; })
                        if (response.status === 100) {
                            message.success('File uploaded successfully');
                            uploadCompleted(response.status);
                        } else {
                            setIntervalStatus(false);
                        }
                        setPercent(response.status);
                    } catch (err) {
                        uploadFailed(100);
                        message.error('File upload failed');
                    }
                }
                setTimeout(handleListenUpload, 1500);
                setIntervalStatus(true);
            }
        }
    }, [data, intervalStatus])

    return (
        <div>
            {
                data &&
                <div className='flex flex-row items-center gap-2'>
                    <Progress
                        style={{ width: '50%' }}
                        size="small"
                        percent={record.status === 'error' ? 100 : record.status === 'done' ? 100 : percent}
                        status={record.status === 'error' ? 'exception' : record.status === 'done' ? 'success' : 'active'}
                    />
                    <Spin spinning={record.status === "uploading" ? true : false} size='small' />
                </div>
            }
        </div>
    )
}
