import React, { useEffect, useState } from 'react';

import { Button, Form, Input, Spin, Typography } from 'antd';

import { useNavigate } from 'react-router-dom';
import httpClient from '../../utils/http-client.util';
import { BackendAPI } from '../../constants/backend-api.enum';
import { AxiosError } from 'axios';
import { ResetPasswordType } from 'bridge/authentication';
import { popMessage } from '../../utils/pop-message.util';
import { useBoundStore } from '../../states/bound.store';
import { AuthenticationLayout } from '../../components/layout/authentication-layout/authentication-layout.component';
import { modalMessage } from '../../utils/modal-message.util';
import { getRedirectRoute } from '../../helpers/redirect-helper';

const { Title } = Typography;
export const ResetPassword = ({ type }: { type: string }) => {
  const [token, setToken] = useState("");
  const [status, setStatus] = useState(200);
  const [loading, setLoading] = useState(true);

  const [submitting, setIsSubmitting] = useState(false);
  const [formError, setFormError] = useState(false);

  const user = useBoundStore((state) => state.user);
  const userLogout = useBoundStore().userLogout;
  const navigate = useNavigate();
  const [resetPasswordForm] = Form.useForm<ResetPasswordType>();

  const onFieldsChange = async () => {
    const hasErrors = resetPasswordForm.getFieldsError().some(({ errors }) => errors.length);
    setFormError(hasErrors);
  };

  const handleSubmit = async (resetPassword: ResetPasswordType) => {
    setIsSubmitting(true);
    try {
      const res = await httpClient.post(BackendAPI.CHANGE_PASSWORD, { token: token, password: resetPassword.confirmPassword })
      .then(res=> res.data)
      .catch(()=> ({ status: 400, message: "Error Network" }))
      if (res.status === 201) {
        setIsSubmitting(false);
        setStatus(res.status);
      } else {
        setIsSubmitting(false);
        modalMessage.error({
          title: 'Error',
          content: res.message,
          centered: true,
        });
      }
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.response) {
          const { message } = err.response.data;
          popMessage.error({ content: message });
        }
      } else {
        console.log(err);
        popMessage.error('Unknown Error During Login!');
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    
    const token = urlParams.get('token');

    setToken("" + token);
    if (token === null || token === undefined) {
      navigate('/login');
    } else {
      checkToken(token);
    }
  }, []);

  const checkToken = async (token: any) => {
    setLoading(true);
    const res = await httpClient.post(BackendAPI.CHANGE_PASSWORD, { token: token })
        .then(res=> res.data)
        .catch(()=> ({ status: 400, message: "Error Network" }))
    setLoading(false);
    setStatus(res.status);
    try {
      if (user !== null) {
        await httpClient.post(BackendAPI.PING, { userId: user.userId });
      } else {
        throw user;
      }
    } catch (e) {
      userLogout();
    }
  }

  const handleNavigate = () => {
    if (user) {
      navigate(getRedirectRoute(user))
    } else {
      navigate("/login")
    }
  }

  return (
    <AuthenticationLayout>
      <div className='flex flex-col h-full relative'>
        <div className='w-full flex items-center justify-center mb-4'>
              <Title level={2}>Reset Password</Title>
        </div>
        {
          loading &&
          <div className='absolute top-0 h-full w-full flex flex-1 justify-center flex-col gap-[20px] items-center'>
            <Spin spinning={true}></Spin>
          </div>
        }
        {
          !loading && [400, 401, 403, 201].includes(status) &&
          <div className='absolute top-0 h-full w-full flex flex-1 justify-center flex-col gap-[20px] items-center'>
            <div className='flex justify-center'>
              <Button onClick={handleNavigate} className='p-[1.5rem] flex items-center' style={{ backgroundColor: "var(--primary-color)" ,color: "var(--primary-text-color)" }}><span className='font-bold'>GO TO {user ? 'HOME PAGE' : 'LOGIN'}</span></Button>
            </div>
            <div className='w-full flex items-center justify-center mb-4'>
              <h2 className='font-[600] text-[20px]' style={{color:( status === 201 ? "green" : "red" )}}>{ status === 201 ? "Reset Successfully" : ("Your token has " + ([400, 401].includes(status) ? "expired" : "already been used")) } </h2>
            </div>
          </div>
        }
        {
          !loading &&  status === 200 &&
        <Form
          form={resetPasswordForm}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={handleSubmit}
          onFieldsChange={onFieldsChange}
          layout="vertical"
          size='large'
          autoComplete={'off'}
        >
          <Form.Item
            className='custom-form'
            label={<Typography className='label-text'>New Password</Typography>}
            name="newPassword"
            rules={[{ required: true, message: 'Please input your New Password!' }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            className='custom-form'
            label={<Typography className='label-text'>Confirm Password</Typography>}
            dependencies={["newPassword"]}
            name="confirmPassword"
            rules={[
              { required: true, message: 'Please input your Confirm Password!' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('newPassword') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('The new password that you entered do not match!'));
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
          
          <Form.Item className='w-full flex items-center justify-center'>
            <Button className='login-button' type="primary" htmlType="submit" loading={submitting} disabled={formError}>
              Change Password
            </Button>
          </Form.Item>
        </Form>
      } 
      </div>
    </AuthenticationLayout>
  )
}
