import { TUser } from "../pages/users-management/type-helper";
import { saveAs } from 'file-saver';
import { Button, message } from 'antd';
import IArrow from "../assets/icons/arrow";

export const bytesToMegabytes = (bytes: any) => {
    if (bytes === null) {
        return null
    }
    const megabytes = bytes / (1024 * 1024);
    return megabytes.toFixed(2);
}

export const convertTimestampToDate = (timestamp: any) => {
    if (timestamp === null) {
        return null
    }
    const originalDate = new Date(timestamp);
    const formattedDate = originalDate.toLocaleDateString('en-US', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
    });

    return formattedDate
}

export const getUserByUserId = (userId: string, data: TUser[]) => {
    return data.find((item) => item.userId === userId);
}

export const downloadFile = async (url: string, filename: string) => {
    try {
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const blob = await response.blob();
        saveAs(blob, filename);
    } catch (error) {
        message.error('Error downloading the file');
    }
};

export const paginationItemRender = (_: number, type: "page" | "prev" | "next" | "jump-prev" | "jump-next", originalElement: React.ReactNode) => {
    if (type === "prev") {
        return <Button type="text" icon={<IArrow style={{ color: 'var(--primary-text-color)' }} className={`transition-all rotate-180`} />} />;
    }
    if (type === "next") {
        return <Button type="text" icon={<IArrow style={{ color: 'var(--primary-text-color)' }} />} />;
    }
    return originalElement;
};