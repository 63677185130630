import React from 'react';
import { TypeIconProps } from './icon';

export default function IMinusCircle(props: TypeIconProps) {
  const { width, height, className, color } = props;

  return (
    <svg
      width={width ?? '14'}
      height={height ?? '14'}
      className={className ?? ''}
      style={{ color: color ?? 'black' }}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.7">
        <path
          d="M6.95345 13.2708C3.50012 13.2708 0.682617 10.4592 0.682617 6.99999C0.682617 3.54082 3.50012 0.729156 6.95345 0.729156C10.4068 0.729156 13.2243 3.54082 13.2243 6.99999C13.2243 10.4592 10.4126 13.2708 6.95345 13.2708ZM6.95345 1.60416C3.97845 1.60416 1.55762 4.02499 1.55762 6.99999C1.55762 9.97499 3.97845 12.3958 6.95345 12.3958C9.92845 12.3958 12.3493 9.97499 12.3493 6.99999C12.3493 4.02499 9.92845 1.60416 6.95345 1.60416Z"
          fill="#292D32"
        />
        <path
          d="M9.28678 7.4375H4.62012C4.38095 7.4375 4.18262 7.23917 4.18262 7C4.18262 6.76083 4.38095 6.5625 4.62012 6.5625H9.28678C9.52595 6.5625 9.72428 6.76083 9.72428 7C9.72428 7.23917 9.53178 7.4375 9.28678 7.4375Z"
          fill="#292D32"
        />
      </g>
    </svg>
  );
}
