import React, { createRef, useEffect, useRef, useState } from 'react';

import { Button, Form, Input, Typography } from 'antd';

import { Link, useNavigate } from 'react-router-dom';
import httpClient from '../../utils/http-client.util';
import { BackendAPI } from '../../constants/backend-api.enum';
import { AxiosError } from 'axios';
import { EmailType } from 'bridge/authentication';
import { popMessage } from '../../utils/pop-message.util';
import { useBoundStore } from '../../states/bound.store';
import { AuthenticationLayout } from '../../components/layout/authentication-layout/authentication-layout.component';
import { modalMessage } from '../../utils/modal-message.util';
import { Recaptcha, ReCaptchaRef } from '../../components/recaptcha/recaptcha';
import { getRedirectRoute } from '../../helpers/redirect-helper';

const { Title } = Typography;
export const ForgetPassword = () => {
    const [submitting, setIsSubmitting] = useState(false);
    const [formError, setFormError] = useState(false);

    const user = useBoundStore((state) => state.user);
    const navigate = useNavigate();
    const [forgetPasswordForm] = Form.useForm<EmailType>();
    // const captchaRef = useRef<ReCaptchaRef>(null);

    const onFieldsChange = async () => {
        const hasErrors = forgetPasswordForm.getFieldsError().some(({ errors }) => errors.length);
        setFormError(hasErrors);
    };

    const handleSubmit = async (forgetPassword: EmailType) => {
        // verify captcha
        // if (captchaRef.current) {
        //     const token = await captchaRef.current.execute();
        //     if (token === null) {
        //         popMessage.error('Please re-verify CAPTCHA below.')
        //         return false
        //     }
        // }
        setIsSubmitting(true);
        try {
            //API Reset Password
            const requestAccesss = await httpClient.post<EmailType>(BackendAPI.RESET_PASSWORD, forgetPassword);
            if (requestAccesss.status === 200) {
                modalMessage.success({
                    title: 'Send Email Successfully',
                    content: 'You will receive reset password link from our email',
                    centered: true
                });
                // await captchaRef.current?.reset();
                navigate('/login');
            }
        } catch (err) {
            // await captchaRef.current?.reset();
            if (err instanceof AxiosError) {
                if (err.response) {
                    const { message } = err.response.data;
                    popMessage.error({ content: message});
                }
            } else {
                popMessage.error('Unknown Error During Login!');
            }
        } finally {
            setIsSubmitting(false);
        }
    };

    useEffect(() => {
        if (user) {
            navigate(getRedirectRoute(user));
        }
    }, []);

    return (
        <AuthenticationLayout>
            <div className='w-full flex items-center justify-center mb-4'>
                <Title level={2}>Forget Password</Title>
            </div>
            <Form
                form={forgetPasswordForm}
                name="basic"
                initialValues={{ remember: true }}
                onFinish={handleSubmit}
                onFieldsChange={onFieldsChange}
                layout="vertical"
                size='large'
                autoComplete={'off'}
            >
                <Form.Item
                    className='custom-form'
                    label={<Typography className='label-text'>Enter your email address</Typography>}
                    name="email"
                    rules={[{ required: true, type: 'email', message: 'Please input valid email!' }]}
                >
                    <Input />
                </Form.Item>
                {/* <Form.Item>
                    <Recaptcha ref={captchaRef} />
                </Form.Item> */}
                <Form.Item className='w-full flex items-center justify-center mb-4'>
                    <Button className='login-button' type="primary" htmlType="submit" loading={submitting} disabled={formError}>
                        Send Email
                    </Button>
                </Form.Item>
                <Form.Item className='w-full flex items-center justify-center'>
                    <Link to={'/login'}>
                        <Typography className='link-text'>
                            LOGIN
                        </Typography>
                    </Link>
                </Form.Item>
            </Form>
        </AuthenticationLayout>
    )
}
