import React from 'react';

type TICacnelProps = {
    width?: string;
    height?: string;
    className?: string;
    color?: string;
};

export default function CancelIcon(props: TICacnelProps) {
    const { width, height, className, color } = props;
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            style={{ color: color ?? 'var(--primary-text-color)' }}
            fill="currentColor"
            width={width ?? '24'}
            height={height ?? '24'}
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className={`${className}`}
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
        </svg>

    );
}
