import { Button, Popover } from 'antd'
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import './popover-display.css'
import { TooltipPlacement } from 'antd/es/tooltip';
import { CloseCircleIcon } from '../../assets/icons/close-circle.icon';

type TypePopoverDisplay = {
    children: React.ReactNode;
    contentChildren: React.ReactNode;
    display?: boolean;
    style?: React.CSSProperties;
    placement?: TooltipPlacement | undefined;
    hasCloseBtn?: boolean;
    setDisplay?: React.Dispatch<React.SetStateAction<boolean>>;
    minWidth?: string;
    disableOpen?: boolean;
}

export type DisplayHandle = {
    close: () => void;
    open: () => void;
};

export const PopoverDisplay = forwardRef<DisplayHandle, TypePopoverDisplay>((props, ref) => {
    useImperativeHandle(ref, () => {
        return {
            close: () => setIsDisplay(false),
            open: () => setIsDisplay(true)
        };
    });
    const { children, contentChildren, display, style, placement, hasCloseBtn, setDisplay, minWidth, disableOpen } = props;
    const [isDisplay, setIsDisplay] = useState<boolean>(display ? display : false)

    useEffect(() => {
        setIsDisplay(display ?? false);
    }, [display])

    return (
        <Popover
            content={
                <div className={`custom_popover_upload ${minWidth ?? "min-w-[380px]"}`} style={style}>
                    {
                        hasCloseBtn ? (
                            <Button onClick={() => setIsDisplay(false)} className='close-btn' icon={<CloseCircleIcon width='30' height='30' />} />
                        ) : null
                    }
                    {contentChildren}
                </div>
            }
            trigger="click"
            open={isDisplay}
            placement={`${placement ? placement : 'bottom'}`}
            onOpenChange={(value) => {
                if (!disableOpen) {
                    setIsDisplay(value);
                } else {
                    setIsDisplay(false);
                }
                if (setDisplay) {
                    setDisplay(value);
                }
            }}
        >
            {children}
        </Popover>
    )
})
