import { Button } from 'antd';
import React, { useEffect, useState } from 'react';
import IArrow from '../../../assets/icons/arrow';
import { TFile } from '../type-helper';
import ITrash from '../../../assets/icons/trash';
import { difference, differenceBy } from 'lodash';
import { useBoundStore } from '../../../states/bound.store';
import { CloseCircleIcon } from '../../../assets/icons/close-circle.icon';

type TSelectFile = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  files: TFile[];
  handleStartChat: (listFile: TFile[], fn: () => void) => Promise<void>;
  originalSelectedFiles: TFile[];
  selectFileLoading: boolean;
  setBackgroundSelectedFile: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function SelectFile(props: TSelectFile) {
  const { selectFileLoading, setBackgroundSelectedFile, handleStartChat, setOpen, open, files, originalSelectedFiles } = props;
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [notSelectedFiles, setNotSelectedFiles] = useState<TFile[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<TFile[]>([]);
  const setDiableBackDropOpen = useBoundStore().setDiableBackDropOpen;
  const [loading, setLoading] = useState(false);

  const onClose = () => {
    setBackgroundSelectedFile(false);
    setOpen(false);
  };

  useEffect(() => {
    setCollapseOpen(false);
    setNotSelectedFiles([...differenceBy(files, originalSelectedFiles, 'file_name')]);
    setSelectedFiles([...originalSelectedFiles]);
  }, [open, files, originalSelectedFiles]);

  const handleStart = () => {
    setLoading(true);
    setDiableBackDropOpen(true);
    handleStartChat(selectedFiles, () => {
      setLoading(false);
      onClose();
      setDiableBackDropOpen(false);
    });
  };

  const handleSelected = (file: TFile) => {
    setSelectedFiles([...selectedFiles, file]);
    setNotSelectedFiles([...notSelectedFiles.filter((item) => item.file_name !== file.file_name)]);
  };

  const checkDisableStartButton = () => {
    return (
      (difference(selectedFiles, originalSelectedFiles).length === 0 &&
        difference(originalSelectedFiles, selectedFiles).length === 0) ||
      selectedFiles.length === 0
    );
  };

  const handleUnSelect = (file: TFile) => {
    setNotSelectedFiles([...notSelectedFiles, file]);
    setSelectedFiles([...selectedFiles.filter((item) => item.file_name !== file.file_name)]);
  };

  const handleCollapseOpen = () => {
    if (selectedFiles.length === 0) {
      setCollapseOpen(!collapseOpen);
    }
  };

  const handleAllSelect = () => {
    if (notSelectedFiles.length === 0) {
      setNotSelectedFiles([...files]);
      setSelectedFiles([]);
    } else {
      setNotSelectedFiles([]);
      setSelectedFiles([...files]);
    }
  };

  const handleUnSelectAll = () => {
    setNotSelectedFiles([...files]);
    setSelectedFiles([]);
    setLoading(true);
    setDiableBackDropOpen(true);
    handleStartChat([], () => {
      setLoading(false);
      setDiableBackDropOpen(false);
    });
  };

  return (
    <div
      className={`custom-drawer z-[1000] w-[90vw] md:w-[500px] p-5 text-black absolute shadow-xl rounded-lg bg-white top-0 right-0 h-[calc(98%-60px-3rem)] mt-[calc(60px+1.4rem)] ${open ? 'translateX-0' : 'translateX-100'
        }`}
    >
      {originalSelectedFiles.length > 0 && (
        <Button
          onClick={onClose}
          shape='circle'
          className="ml-[-1.1rem] border-0 mt-[-2rem] z-[300] p-3 absolute left-0  flex items-center close-btn"
          icon={<CloseCircleIcon width="30" height="30" />}
        />
      )}

      <div className="relative h-full flex flex-col gap-5">
        <div className="text-xl text-nowrap font-medium block md:flex items-center relative">
          {originalSelectedFiles.length === 0 && (
            <div className="flex italic" style={{ color: 'var(--primary-color)' }}>
              <h1 className=" content-center">Step </h1>
              <span className="text-2xl">&nbsp;1</span>
            </div>
          )}
          <h1 className="text-center w-full">Select Doc for Precise Context</h1>
        </div>

        <div className="flex-1">
          <div className="font-16-400">
            The Chat Box has access to your documents and
            can respond to user queries by referring to the content within these
            documents.
          </div>
          <div className="mt-5 text-black text-nowrap">
            <div
              role={selectedFiles.length === 0 ? 'button' : ''}
              className={'items-center rounded-lg p-2 border-2 bg-white border border-black flex gap-2 '}
              onClick={handleCollapseOpen}
            >
              {selectedFiles.length === 0 && (
                <div className="flex-1">
                  <h1 className="px-[1.3rem]">Choose an option</h1>
                </div>
              )}
              {selectedFiles.length > 0 && (
                <div
                  className={`select-options-layout flex flex-1 flex-col gap-2 transition-all h-fit overflow-scroll max-h-[80px] md:max-h-[160px]`}
                >
                  {selectedFiles.map((item, index) => (
                    <div
                      role="button"
                      onClick={() => handleUnSelect(item)}
                      key={item.file_name + index}
                      className="p-1 px-[1.3rem] max-w-[100%] flex justify-between items-center"
                      style={{ backgroundColor: '#fff5f0', textOverflow: 'ellipsis' }}
                    >
                      <p className="truncate">{item.file_name}</p>
                      <input
                        checked={true}
                        readOnly
                        type="checkbox"
                        style={{ width: '15px', height: '15px', accentColor: 'var(--primary-color)' }}
                      />
                    </div>
                  ))}
                </div>
              )}
              <div className="flex items-center gap-2">
                {selectedFiles.length > 0 && (
                  <Button className="flex items-center p-0" type="text" onClick={handleUnSelectAll}>
                    <ITrash />
                  </Button>
                )}
                <Button type="text" className="flex items-center p-0" onClick={() => setCollapseOpen(!collapseOpen)}>
                  <IArrow
                    className="transition-all"
                    style={{ color: 'var(--primary-color)', transform: 'rotate(' + (collapseOpen ? 90 : 0) + 'deg)' }}
                  />
                </Button>
              </div>
            </div>
            <div
              className={`select-options-layout shadow-xl overflow-hidden mx-[0.35rem] pt-1 bg-white rounded-b-lg flex flex-col gap-2 ${collapseOpen ? 'max-h-[130px] md:max-h-[210px]' : 'max-h-[0px]'}`}
            >
              <div className="overflow-y-scroll flex flex-col gap-2 ">
                {notSelectedFiles.map((item, index) => (
                  <div
                    role="button"
                    onClick={() => handleSelected(item)}
                    className="py-1 px-[1.5rem]"
                    key={item.file_name + index}
                    style={{ backgroundColor: '#f9f7f7' }}
                  >
                    <p className="truncate">{item.file_name}</p>
                  </div>
                ))}
              </div>
              <div className="me-0 ml-auto p-1">
                <Button
                  type="text"
                  className="p-3 flex items-center justify-center text-lg"
                  style={{ color: 'var(--primary-color)' }}
                  onClick={handleAllSelect}
                >
                  {notSelectedFiles.length > 0 ? 'Select all' : 'Clear Selection'}
                </Button>
              </div>
            </div>
          </div>
        </div>

        {(collapseOpen || selectedFiles.length > 0) && (
          <div className="absolute bottom-0 z-[220] w-full flex justify-center">
            <div className="text-x;l font-semibold flex items-center gap-7 ml-[-1.75rem]">
              {originalSelectedFiles.length === 0 && (
                <div className="flex italic" style={{ color: 'var(--primary-color)' }}>
                  <h1 className="text-lg content-center">Step </h1>
                  <span className="text-2xl">&nbsp;2</span>
                </div>
              )}
              <Button
                className="h-[40px] w-[150px]"
                disabled={checkDisableStartButton()}
                style={{
                  color: checkDisableStartButton() ? 'black' : 'var(--primary-text-color)',
                  backgroundColor: checkDisableStartButton() ? 'var(--disabled-bg-color)' : 'var(--primary-color)',
                }}
                onClick={handleStart}
                loading={selectFileLoading || loading}
              >
                Start Chat
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
