import React from 'react';
import { TypeIconProps } from './icon';

export default function IRobot(props: TypeIconProps) {
  const { width, height, color, className } = props;
  return (
    <svg
      width={width ?? '27'}
      height={height ?? '27'}
      className={className}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="22" height="22" rx="5" fill="#FFB348" />
      <path
        d="M10.9871 9.44992C10.5907 9.44992 10.2685 9.12766 10.2685 8.73127V6.21865C10.2685 5.82227 10.5907 5.5 10.9871 5.5C11.3835 5.5 11.7058 5.82227 11.7058 6.21865V8.73127C11.7058 9.12766 11.3835 9.44992 10.9871 9.44992Z"
        fill="black"
        stroke="#FFB348"
        strokeWidth="0.2"
      />
      <path
        d="M9.17921 13.6345C8.72642 13.6345 8.35904 13.2671 8.35904 12.8143C8.35904 12.3615 8.72642 11.9941 9.17921 11.9941C9.63199 11.9941 9.99937 12.3615 9.99937 12.8143C9.99937 13.2671 9.63199 13.6345 9.17921 13.6345ZM12.787 13.6345C12.3342 13.6345 11.9668 13.2671 11.9668 12.8143C11.9668 12.3615 12.3342 11.9941 12.787 11.9941C13.2398 11.9941 13.6071 12.3615 13.6071 12.8143C13.6071 13.2671 13.2398 13.6345 12.787 13.6345Z"
        fill="black"
        stroke="#FFB348"
        strokeWidth="0.2"
      />
      <path
        d="M13.7667 17.6177H8.20757C7.02325 17.6177 6.05967 16.6542 6.05967 15.4698V10.1589C6.05967 8.97456 7.02325 8.01099 8.20757 8.01099H13.7667C14.951 8.01099 15.9146 8.97456 15.9146 10.1589V15.4698C15.9146 16.6542 14.951 17.6177 13.7667 17.6177ZM8.20757 9.4499C7.81602 9.4499 7.49698 9.76895 7.49698 10.1605V15.4714C7.49698 15.863 7.81602 16.182 8.20757 16.182H13.7667C14.1582 16.182 14.4773 15.863 14.4773 15.4714V10.1589C14.4773 9.76733 14.1582 9.44829 13.7667 9.44829H8.20757V9.4499ZM17.4824 14.9896C17.086 14.9896 16.7637 14.6674 16.7637 14.271V11.3593C16.7637 10.9629 17.086 10.6407 17.4824 10.6407C17.8788 10.6407 18.201 10.9629 18.201 11.3593V14.271C18.201 14.669 17.8788 14.9896 17.4824 14.9896ZM4.54986 14.9896C4.15347 14.9896 3.83121 14.6674 3.83121 14.271V11.3593C3.83121 10.9629 4.15347 10.6407 4.54986 10.6407C4.94625 10.6407 5.26851 10.9629 5.26851 11.3593V14.271C5.26851 14.669 4.94786 14.9896 4.54986 14.9896Z"
        fill="black"
        stroke="#FFB348"
        strokeWidth="0.2"
      />
      <circle cx="11.0183" cy="5.72908" r="1.14583" fill="black" stroke="#FFB348" strokeWidth="0.2" />
    </svg>
  );
}