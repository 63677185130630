import React, { useState, useEffect } from 'react';
import { Document, pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import { groupBy } from 'lodash';
import { useBoundStore } from '../../../states/bound.store';
import { PC } from '../../../utils/enum.util';
import OnError from './on-error';
import OnLoad from './on-load';
import Header from './header';
import MultiSourceSide from './sidebar';
import PdfViewer from './pdf-viewer';
import { TMultiplePage } from '../type-helper';

const pdfjsVersion = '3.11.174'; // Replace with the correct version
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsVersion}/pdf.worker.min.js`;

type TResponse = { page: number; content: string };

type TPdfComponentProp = {
  multiplePage?: TResponse[];
  zoom: number;
  url: string;
  header?: boolean;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
};

const getMultiplePage = (data: TResponse[] | undefined) => {
  const MultiplePageGroup: { [key: number]: TResponse[] } = groupBy(data, (item) => item.page);

  return Object.keys(MultiplePageGroup).map((page: any) => ({
    page: MultiplePageGroup[page][0].page,
    content: MultiplePageGroup[page].map((i) => i.content),
  }));
};

export default function PdfComponent(props: TPdfComponentProp) {
  const { header, url, multiplePage, setRefresh } = props;
  const [sourceDocument, setSourceDocument] = useState<TMultiplePage[] | undefined>(
    multiplePage && getMultiplePage(multiplePage)
  );
  const device = useBoundStore().device;
  const [zoom, setZoom] = useState(props.zoom);
  const [goalSearch, setGoalSearch] = useState<string[] | undefined>(sourceDocument && sourceDocument[0].content);
  const [pageNumber, setPageNumber] = useState<number | undefined>(sourceDocument && sourceDocument[0].page);
  const [numPages, setNumPages] = useState<number>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [showNav, setShowNav] = useState<boolean | undefined>(
    getMultiplePage(multiplePage).length > 1 ? true : undefined
  );

  function onDocumentLoadSuccess(pdf: any): void {
    setNumPages(pdf.numPages);
  }

  useEffect(() => {
    setPageNumber(sourceDocument && sourceDocument[0].page);
    setGoalSearch(sourceDocument && sourceDocument[0].content);
  }, [sourceDocument]);

  useEffect(() => {
    if (sourceDocument && sourceDocument.filter((item) => item.page === pageNumber).length > 0) {
      const currentPageIndex = sourceDocument.findIndex((item) => item.page === pageNumber);
      setCurrentPage(currentPageIndex + 1);
    } else {
      setCurrentPage(0);
    }
  }, [pageNumber]);

  useEffect(() => {
    if (PC !== device) {
      setShowNav(sourceDocument && sourceDocument?.length > 1 ? false : undefined);
    } else {
      setShowNav(sourceDocument && sourceDocument?.length > 1 ? true : undefined);
    }
  }, [device]);

  return (
    <Document file={url} onLoadSuccess={onDocumentLoadSuccess}
      error={<OnError handleTryAgain={() => setRefresh(true)}/>}
      loading={<OnLoad />}>
      <div className="flex w-full h-full relative">
        {sourceDocument && sourceDocument.length > 1 && (
            <MultiSourceSide
              multiplePage={sourceDocument}
              setPageNumber={setPageNumber}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              showNav={showNav}
              setShowNav={setShowNav}
              setGoalSearch={setGoalSearch}
            />
        )}
        <main
          className={`transition-all md:mt-[56px] mt-[102px] flex flex-col lg:w-[calc(100%-205px)] lg:ml-[205px] w-full h-full relative ${showNav ? '' : 'active'}`}
        >
          {header && (
            <Header
              zoom={zoom}
              setZoom={setZoom}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              numPages={numPages}
              showNav={showNav}
              setShowNav={setShowNav}
              setGoalSearch={setGoalSearch}
              sourceDocument={sourceDocument}
            />
          )}
            <div className={`flex-1 h-full relative mx-auto`}>
              <div className="w-full h-full relative flex justify-center">
                <PdfViewer zoom={zoom} pageNumber={pageNumber} searchs={goalSearch} />
              </div>
            </div>
        </main>
      </div>
    </Document>
  );
}