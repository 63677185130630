import React, { ReactNode } from 'react'
import './authentication-layout.component.css'
import { Col, Row, Typography } from 'antd';
import { PilabsIcon } from '../../../assets/icons/pi-labs.icon';

const { Title } = Typography;

export const AuthenticationLayout = ({ children }: { children: ReactNode }) => {
    return (
        <div className='auth-section'>
            <div className='auth-layout'>
                <Row className='w-full h-full'>
                    <Col md={12} lg={14}>
                        <div className='auth-description page-describe'>
                            <div className='login-button'>LOGO</div>
                            <Title level={2} className='title'>Welcome to SOP Query House</Title>
                            <Typography className='subtitle'>Sop chat bot helps you understand the standard procedures.</Typography>
                            <Typography className='subtitle'>Please proceed to chat section if you have any questions.</Typography>
                        </div>
                    </Col>
                    <Col xs={24} md={12} lg={10}>
                        <div className='auth-form'>
                            <div className='w-full h-full'>
                                {children}
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}
