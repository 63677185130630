import React from 'react';
import { TypeIconProps } from './icon';

export default function IChatUser(props: TypeIconProps) {
  const { width, height, className, color } = props;
  return (
    <svg
      width={width ?? '24'}
      height={height ?? '24'}
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="5" fill="#F24649" />
      <path
        d="M11.9889 19.2111C8.01873 19.2111 4.78857 15.9809 4.78857 12.0107C4.78857 8.04053 8.01873 4.81055 11.9889 4.81055H16.8452C18.1378 4.81055 19.1893 5.86207 19.1893 7.15441V12.0107C19.1893 15.9809 15.9591 19.2111 11.9889 19.2111ZM11.9889 6.02871C8.69039 6.02871 6.00674 8.71236 6.00674 12.0107C6.00674 15.3091 8.69039 17.9929 11.9889 17.9929C15.2875 17.9929 17.9711 15.3093 17.9711 12.0107V7.15441C17.9711 6.53373 17.4659 6.02871 16.8452 6.02871H11.9889Z"
        fill="black"
        stroke="black"
        strokeWidth="0.2"
      />
      <path
        d="M8.38428 10.9181C8.38428 11.0304 8.40558 11.1417 8.44698 11.2455C8.48837 11.3493 8.54905 11.4436 8.62554 11.523C8.70203 11.6025 8.79283 11.6655 8.89277 11.7085C8.99271 11.7515 9.09982 11.7736 9.20799 11.7736C9.31616 11.7736 9.42327 11.7515 9.52321 11.7085C9.62315 11.6655 9.71395 11.6025 9.79044 11.523C9.86693 11.4436 9.9276 11.3493 9.969 11.2455C10.0104 11.1417 10.0317 11.0304 10.0317 10.9181C10.0317 10.6912 9.94492 10.4736 9.79044 10.3131C9.63596 10.1527 9.42645 10.0625 9.20799 10.0625C8.98953 10.0625 8.78001 10.1527 8.62554 10.3131C8.47106 10.4736 8.38428 10.6912 8.38428 10.9181Z"
        fill="black"
        stroke="black"
        strokeWidth="0.2"
      />
      <path
        d="M12.6426 10.9181C12.6426 11.145 12.7294 11.3626 12.8838 11.523C13.0383 11.6835 13.2478 11.7736 13.4663 11.7736C13.6848 11.7736 13.8943 11.6835 14.0487 11.523C14.2032 11.3626 14.29 11.145 14.29 10.9181C14.29 10.6912 14.2032 10.4736 14.0487 10.3131C13.8943 10.1527 13.6848 10.0625 13.4663 10.0625C13.2478 10.0625 13.0383 10.1527 12.8838 10.3131C12.7294 10.4736 12.6426 10.6912 12.6426 10.9181Z"
        fill="black"
        stroke="black"
        strokeWidth="0.2"
      />
    </svg>
  );
}
