import React from 'react';

export default function IEditPencil() {
  return (
    <svg width="20" height="20" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.55682 2.07572C1.41919 2.07572 1.28719 2.13039 1.18987 2.22771C1.09255 2.32503 1.03788 2.45703 1.03788 2.59466V9.85981C1.03788 9.99744 1.09255 10.1294 1.18987 10.2268C1.28719 10.3241 1.41919 10.3787 1.55682 10.3787H8.82197C8.9596 10.3787 9.09159 10.3241 9.18891 10.2268C9.28623 10.1294 9.34091 9.99744 9.34091 9.85981V7.08867C9.34091 6.80207 9.57324 6.56973 9.85984 6.56973C10.1464 6.56973 10.3788 6.80207 10.3788 7.08867V9.85981C10.3788 10.2727 10.2148 10.6687 9.9228 10.9606C9.63084 11.2526 9.23486 11.4166 8.82197 11.4166H1.55682C1.14392 11.4166 0.747941 11.2526 0.455981 10.9606C0.164021 10.6687 0 10.2727 0 9.85981V2.59466C0 2.18177 0.164021 1.78578 0.455981 1.49382C0.747941 1.20186 1.14392 1.03784 1.55682 1.03784H4.32795C4.61455 1.03784 4.84689 1.27018 4.84689 1.55678C4.84689 1.84338 4.61455 2.07572 4.32795 2.07572H1.55682Z"
        fill="#FF5C00"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.45503 0.151994C8.65769 -0.0506646 8.98627 -0.0506646 9.18892 0.151994L11.2647 2.22775C11.4673 2.43041 11.4673 2.75898 11.2647 2.96164L6.07529 8.15103C5.97797 8.24835 5.84597 8.30303 5.70834 8.30303H3.63259C3.34598 8.30303 3.11365 8.07069 3.11365 7.78409V5.70833C3.11365 5.5707 3.16832 5.43871 3.26564 5.34139L8.45503 0.151994ZM4.15153 5.92328V7.26515H5.49339L10.1638 2.5947L8.82198 1.25283L4.15153 5.92328Z"
        fill="#FF5C00"
      />
    </svg>
  );
}
