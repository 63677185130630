import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import 'remixicon/fonts/remixicon.css';
import { ConfigProvider } from 'antd';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const style = getComputedStyle(document.body);

root.render(
  <BrowserRouter>
    <React.StrictMode>
      <ConfigProvider
        theme={{
          token: {
            borderRadius: 6,
            colorSplit: 'rgba(5, 5, 5, 0.15)',
            colorBgBase: style.getPropertyValue('--primary-foreground'),
          },
          components: {
            Empty: {
              colorTextDescription: "var(--primary-text-color)",
            }, 
            Input: {
              colorTextPlaceholder: "var(--primary-text-dark)",
            },
            Button: {
              colorTextDisabled: "var(--primary-text-dark)"
            }
          }
        }}
      >
        <App />
      </ConfigProvider>
    </React.StrictMode>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
