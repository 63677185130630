import { Button, ConfigProvider, Form, Input, Select } from 'antd';
import React, { useEffect } from 'react';
import './create-user.css';
import { TUser } from '../type-helper';
import { TOption } from '../../../constants/global-type.constant';
import ICancel from '../../../assets/icons/cancel';
import IArrow from '../../../assets/icons/arrow';

type TCreateUserProp = {
  EditUser: TUser | undefined;
  setEditUser: React.Dispatch<React.SetStateAction<TUser | undefined>>;
  handleSave: (data: TUser, fn: () => void) => Promise<void>;
  handleResetPassword: (email: string) => Promise<void>;
  options: TOption[];
};

export default function CreateUser({ EditUser, setEditUser, handleSave, handleResetPassword, options }: TCreateUserProp) {
  const [form] = Form.useForm<TUser>();
  const requiredRule = [
    {
      required: true,
      message: '',
    },
  ];

  useEffect(() => {
    form.resetFields();
    if (EditUser !== undefined) {
      form.setFieldValue('firstName', EditUser.firstName);
      form.setFieldValue('lastName', EditUser.lastName);
      form.setFieldValue('email', EditUser.email);
      form.setFieldValue('role', EditUser.role);
    }
  }, [EditUser]);

  const handleCancel = () => {
    setEditUser(undefined);
  };

  const handleSubmit = (data: TUser) => {
    handleSave(data, () => {
      setEditUser(undefined);
      form.resetFields();
    });
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          colorBorder: 'var(--primary-text-color)',
          colorPrimaryHover: 'var(--primary-text-color)',
          colorText: 'var(--primary-text-color)',
        },
        components: {
          Select: {
            selectorBg: 'transparent',
          },
        },
      }}
    >
      <div className="flex justify-center flex-col pt-5">
        <h1 className="text-xl font-medium text-center">Create User Account</h1>
        <Form
          autoComplete="off"
          className="mt-10"
          form={form}
          onFinish={handleSubmit}
        >
          <Form.Item name={'firstName'} rules={requiredRule}>
            <Input autoComplete="nope" className="input-transparent" placeholder="First name" />
          </Form.Item>
          <Form.Item name={'lastName'} rules={requiredRule}>
            <Input autoComplete="nope" className="input-transparent" placeholder="Last name" />
          </Form.Item>
          <Form.Item name={'email'} rules={[...requiredRule, { type: 'email', message: '' }]}>
            <Input autoComplete="nope" className="input-transparent" placeholder="Email address" />
          </Form.Item>
          <Form.Item name={'role'} rules={requiredRule}>
            <Select
              options={options}
              optionRender={(text: any) => <div className="text-black">{text.label}</div>}
              suffixIcon={<IArrow style={{ color: 'var(--primary-text-color)', transform: 'rotate(90deg)' }} />}
              allowClear={{
                clearIcon: <ICancel className="mt-[-2px] ml-[-5px]" />,
              }}
              className="Select-Transparent h-[3rem]"
              style={{ color: 'var(--primary-text-color)' }}
              placeholder={<div style={{ color: 'var(--primary-text-color)' }}>Select Role</div>}
            />
          </Form.Item>
          {EditUser === undefined ? (
            <div className="p-3 flex justify-center">
              <Button
                htmlType="submit"
                shape="round"
                className="border-0 w-[50%] min-w-[10rem] h-[2.5rem]"
                style={{ backgroundColor: 'var(--primary-color)', color: 'var(--primary-text-color)' }}
              >
                <strong className="font-medium">Create</strong>
              </Button>
            </div>
          ) : (
            <div className="p-3 flex flex-col items-center gap-4 w-100">
              <Button
                htmlType="submit"
                shape="round"
                className="border-0 w-[50%] min-w-[10rem] h-[2.5rem]"
                style={{ backgroundColor: 'var(--primary-color)', color: 'var(--primary-text-color)' }}
              >
                <strong className="font-medium">Update</strong>
              </Button>
              <Button
                onClick={handleCancel}
                shape="round"
                className="border-0 max-w-[15rem] w-[50%] min-w-[10rem] h-[2.5rem]"
                style={{ backgroundColor: '#d9d9d9', color: 'black' }}
              >
                <strong className="font-medium">Cancel</strong>
              </Button>
              <Button
                onClick={() => handleResetPassword(EditUser.email)}
                type="link"
                className="underline text-lg"
                style={{ color: 'var(--primary-color)' }}
              >
                <span className="send-link-text">Send Reset Password Link</span>
              </Button>
            </div>
          )}
        </Form>
      </div>
    </ConfigProvider>
  );
}
