import { Link, useLocation } from 'react-router-dom';
import { ReactElement, useEffect, useState } from 'react';
import { applicationRoutes, AppRoute } from '../../routes/route.config';
import { useBoundStore } from '../../states/bound.store';
import { Button } from 'antd';
import IArrow from '../../assets/icons/arrow';
import { PilabsIcon } from '../../assets/icons/pi-labs.icon';
import { getRedirectRoute } from '../../helpers/redirect-helper';

type SideNavGroup = {
  name: string;
  items: SideNavItem[];
};

type SideNavItem = {
  name: string;
  path: string;
  icon: ReactElement;
  showBadge?: boolean;
};

export const SideNavigation = () => {
  const displaySideNav = useBoundStore().displaySideNav;
  const sideNavToggle = useBoundStore().toggleSideNav;
  const user = useBoundStore().user;
  const location = useLocation();
  const [sideNavGroups, setSideNavGroups] = useState<SideNavGroup[]>([]);

  useEffect(() => {
    const allSideNavRoutes: AppRoute[] = [];
    const collectSideNav = (appRoute: AppRoute, parentUrl: string) => {
      if (appRoute.sideNav) {
        const canAccess = appRoute.canAccess ? appRoute.canAccess : () => true;
        if (canAccess()) {
          allSideNavRoutes.push({
            ...appRoute,
            path: parentUrl + (!parentUrl.length || parentUrl.endsWith('/') ? '' : '/') + appRoute.path,
          });
        }
      }
      if (appRoute.children) {
        appRoute.children.forEach((c) => collectSideNav(c, appRoute.path));
      }
    };
    applicationRoutes.forEach((ap) => collectSideNav(ap, ''));
    const groups: SideNavGroup[] = [];
    allSideNavRoutes.forEach((r) => {
      const sideNav = r.sideNav;
      if (!!sideNav) {
        const existingGroup = groups.find((g) => g.name === sideNav.group);
        if (sideNav.showBadge) {
        }
        if (!!existingGroup) {
          existingGroup.items.push({
            name: sideNav.displayName,
            icon: sideNav.icon,
            path: r.path,
          });
        } else {
          groups.push({
            name: sideNav.group,
            items: [{ name: sideNav.displayName, icon: sideNav.icon, path: r.path }],
          });
        }
      }
    });
    setSideNavGroups(groups);
  }, [user]);

  const NavSection = (navGroup: SideNavGroup, key: number) => {
    const NavItem = (navItem: SideNavItem, itemKey: number) => {
      return (
        <li className="mb-1 group" key={itemKey}>
          <Link
            to={navItem.path}
            className={
              'flex text-nowrap font-semibold items-center py-4 px-6 text-black hover:bg-orange-50 rounded-md group-[.active]:bg-gray-800 group-[.active]:text-white group-[.selected]:bg-gray-950 group-[.selected]:text-gray-100 ' +
              (location.pathname === navItem.path ? 'bg-orange-50' : '')
            }
          >
            <div className="mr-3" style={{ height: '35px' }}>
              {navItem.icon}
            </div>
            <div className="text-lg ml-3">{navItem.name}</div>
          </Link>
        </li>
      );
    };
    return <div key={key}>{navGroup.items.map((item, i) => NavItem(item, i))}</div>;
  };

  function toggleSider() {
    sideNavToggle(!displaySideNav);
  }

  return (
    <div
      style={{ backgroundColor: 'var(--primary-bg-light)', borderColor: 'var(--primary-color)' }}
      className={`border-[0px] z-[1050] border-l-[3px] transition-all fixed left-0 top-0 px-0 py-4 h-full overflow-y-auto ${displaySideNav ? 'w-[16rem]' : 'w-[0]'}`}
    >
      <div className="relative h-32 flex items-center justify-center">
        <Link to={`${getRedirectRoute(user)}`}>
          <div
            className="flex items-center justify-center h-14 w-20 rounded-2xl"
            style={{ backgroundColor: 'var(--primary-color)', color: 'var(--primary-text-color)' }}
          >
            <div>Logo</div>
          </div>
        </Link>
        <div className="absolute right-0 h-full flex justify-end items-center">
          <Button type="text" className="p-0" onClick={toggleSider}>
            <IArrow style={{ transform: 'scaleX(-1)', color: 'var(--primary-color)' }} />
          </Button>
        </div>
      </div>
      <ul className="mt-4">{sideNavGroups.map((g, i) => NavSection(g, i))}</ul>
    </div>
  );
};
