import React from 'react';

export default function IChat() {
  return (
    <svg width="35" height="35" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="5" fill="#FFB348" />
      <path
        d="M12 6C15.3138 6 18 8.68587 18 11.9993C18 15.3127 15.3138 17.9985 12 17.9985C11.0293 17.9998 10.073 17.7647 9.2136 17.3134L6.63961 17.9835C6.5536 18.0059 6.46323 18.0055 6.37746 17.9822C6.29169 17.9589 6.21349 17.9136 6.15061 17.8508C6.08774 17.788 6.04237 17.7099 6.01901 17.6241C5.99564 17.5384 5.99509 17.448 6.01741 17.362L6.68701 14.7889C6.23449 13.9288 5.99867 12.9712 6.00001 11.9993C6.00001 8.68587 8.6862 6 12 6ZM12.7512 12.5992H10.05L9.9888 12.6034C9.8811 12.6182 9.7824 12.6715 9.71095 12.7535C9.6395 12.8354 9.60014 12.9404 9.60014 13.0491C9.60014 13.1578 9.6395 13.2629 9.71095 13.3448C9.7824 13.4268 9.8811 13.4801 9.9888 13.4949L10.05 13.4991H12.7512L12.8118 13.4949C12.9195 13.4801 13.0182 13.4268 13.0897 13.3448C13.1611 13.2629 13.2005 13.1578 13.2005 13.0491C13.2005 12.9404 13.1611 12.8354 13.0897 12.7535C13.0182 12.6715 12.9195 12.6182 12.8118 12.6034L12.7512 12.5992ZM13.95 10.4995H10.05L9.9888 10.5037C9.8811 10.5185 9.7824 10.5718 9.71095 10.6537C9.6395 10.7357 9.60014 10.8407 9.60014 10.9494C9.60014 11.0581 9.6395 11.1631 9.71095 11.2451C9.7824 11.327 9.8811 11.3803 9.9888 11.3951L10.05 11.3993H13.95L14.0112 11.3951C14.1189 11.3803 14.2176 11.327 14.2891 11.2451C14.3605 11.1631 14.3999 11.0581 14.3999 10.9494C14.3999 10.8407 14.3605 10.7357 14.2891 10.6537C14.2176 10.5718 14.1189 10.5185 14.0112 10.5037L13.95 10.4995Z"
        fill="#212121"
      />
    </svg>
  );
}
