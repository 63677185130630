import { create } from 'zustand';
import { AuthenticationSlice, createAuthenticationSlice } from './authentication.slice';
import { createSideNavDisplaySlice, SideNavDisplaySlice } from './side-nav-display.slice';
import { devtools, persist } from 'zustand/middleware';
import { createSideNavItemBadgeSlice, SideNavItemBadgeSlice } from './side-nav-item-badge.slice';
import { createLibraryDocumentSlice, LibraryDocumentSlice } from './library-documents.slice';
import { createCustomBackgroundSlice, CustomBackgroundSlice } from './custom-background.slice';
import { createRoleRightDocumentSlice, RoleRightDocumentSlice } from './role-rights.slice';

export const useBoundStore = create<
  AuthenticationSlice & SideNavDisplaySlice & SideNavItemBadgeSlice & LibraryDocumentSlice & CustomBackgroundSlice & RoleRightDocumentSlice
>()(
  devtools(
    persist(
      (...a) => ({
        ...createAuthenticationSlice(...a),
        ...createSideNavDisplaySlice(...a),
        ...createSideNavItemBadgeSlice(...a),
        ...createLibraryDocumentSlice(...a),
        ...createCustomBackgroundSlice(...a),
        ...createRoleRightDocumentSlice(...a),
      }),
      {
        name: 'persisted-application-state',
        partialize: (state) => ({ user: state.user, rights: state.rights, subRights: state.subRights, chatHistory: state.chatHistory }),
      }
    )
  )
);
