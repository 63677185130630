import { StateCreator } from 'zustand';
import { LoginResponse } from 'bridge/authentication';
import { TChat } from '../pages/chat/type-helper';
import { UploadFile } from 'antd';

export interface User extends LoginResponse {}

export interface AuthenticationSlice {
  user: User | null;
  proactiveLoggingOut: boolean;
  userLogin: (user: User) => void;
  setProactiveLoggingOut: (loggingOut: boolean) => void;
  userLogout: (nextUrl?: string) => void;
  rights: string[];
  setRights: (rights: string[]) => void;
  subRights: string[];
  setSubRights: (subRights: string[]) => void;
  chatHistory: TChat[];
  setChatHistory: (chatHistory: TChat[]) => void;
  preventReload: boolean;
  setPreventReload: (preventReload: boolean) => void;
  uploadingFiles: UploadFile[];
  setUploadingFiles: (uploadingFiles: UploadFile[]) => void;
}

export const createAuthenticationSlice: StateCreator<
  AuthenticationSlice,
  [['zustand/devtools', never]],
  [],
  AuthenticationSlice
> = (set, getState) => ({
  user: null,
  proactiveLoggingOut: false,
  userLogin: (user: User) => set({ user }, false, 'userLogin'),
  setProactiveLoggingOut: (loggingOut) => set({ proactiveLoggingOut: loggingOut }, false, 'setLoggingOut'),
  userLogout: (nextUrl?: string) => {
    getState().setProactiveLoggingOut(false);
    set({ user: null, rights: [], subRights: [], chatHistory: [] }, false, 'userLogout');
  },
  rights: [],
  setRights: (rights: string[]) => set({ rights }),
  subRights: [],
  setSubRights: (subRights: string[]) => set({ subRights }),
  chatHistory: [],
  setChatHistory: (chatHistory: TChat[]) => set({ chatHistory }),
  preventReload: false,
  setPreventReload: (preventReload: boolean) => set({ preventReload }),
  uploadingFiles: [],
  setUploadingFiles: (uploadingFiles: UploadFile[]) => set({ uploadingFiles }),
});
