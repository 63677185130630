import { Button, Col, ConfigProvider, Input, List, Row, Spin } from 'antd';
import { TEmail } from '../type-helper';
import IPlus from '../../../assets/icons/plus';
import { useEffect, useState } from 'react';
import ITrash from '../../../assets/icons/trash';
import ISave from '../../../assets/icons/save';
import { BackendAPI } from '../../../constants/backend-api.enum';
import httpClient from '../../../utils/http-client.util';
import { modalMessage } from '../../../utils/modal-message.util';
import PopoverConfirm from '../../../components/popover/popover-confirm';
import CancelIcon from '../../../assets/icons/cancel.icon';

type TContentProps = {
  dataSource: TEmail[];
  setDataSource: React.Dispatch<React.SetStateAction<TEmail[]>>;
  allowAddMore: boolean;
  setAllowAddMore: React.Dispatch<React.SetStateAction<boolean>>;
  hide: () => void;
  getData: () => void;
  currentEmail: TEmail | undefined;
  setCurrentEmail: React.Dispatch<React.SetStateAction<TEmail | undefined>>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function Content(props: TContentProps) {
  const {
    getData,
    hide,
    dataSource,
    setDataSource,
    allowAddMore,
    setAllowAddMore,
    currentEmail,
    setCurrentEmail,
    loading,
    setLoading,
  } = props;
  const handleDelete = async (record: TEmail) => {
    setAllowAddMore(false);
    setCurrentEmail(undefined);
    if (record.createdAt === null) {
      setDataSource([...dataSource.filter((item) => item._id !== record._id)]);
    } else {
      setLoading(true);
      const res = await httpClient
        .delete(BackendAPI.EMAIL + '/' + record._id)
        .then((res) => res.data)
        .catch((reason) => ({ status: 400, message: 'Network Error' }));

      if (res.status === 200) {
        getData();
        modalMessage.success({
          title: 'Success',
          content: res.message,
          centered: true,
        });
      } else {
        setLoading(false);
        modalMessage.error({
          title: 'Error ' + res.status,
          content: res.message,
          centered: true,
        });
      }
    }
  };

  const handleSave = async (record: TEmail, email: string, fn: () => void, err: () => void) => {
    let res;
    setLoading(true);
    if (record.createdAt === null) {
      res = await httpClient
        .post(BackendAPI.EMAIL, { email: email })
        .then((res) => res.data)
        .catch((reason) => ({ status: 400, message: 'Network Error' }));
    } else if (email !== record.email) {
      res = await httpClient
        .put(BackendAPI.EMAIL + '/' + record._id, { email: email })
        .then((res) => res.data)
        .catch((reason) => ({ status: 400, message: 'Network Error' }));
    } else {
      res = { status: 304 };
    }

    if (res.status === 304 || res.status === 201) {
      setCurrentEmail(undefined);
      setAllowAddMore(false);
      fn();
      if (res.status === 304) {
        setLoading(false);
        modalMessage.success({
          title: 'Success',
          content: 'Nothing has been changed',
          centered: true,
        });
      } else if (res.status === 201) {
        getData();
        modalMessage.success({
          title: 'Success',
          content: res.message,
          centered: true,
        });
      }
    } else if (res.status === 400) {
      setLoading(false);
      err();
      if (res.message.includes('E11000')) {
        modalMessage.error({
          title: 'Error E11000',
          content: 'Email already exist',
          centered: true,
        });
      } else {
        modalMessage.error({
          title: 'Error ' + res.status,
          content: res.message,
          centered: true,
        });
      }
    }
  };

  const handleRemoved = () => {
    setDataSource([...dataSource.filter((item) => item.createdAt !== null)]);
  };

  const handleAddEmail = () => {
    setAllowAddMore(true);
    let GenerateID = Math.floor(Math.random() * 100);
    const tempEmail = {
      _id: 'generateID-' + GenerateID,
      key: 'generateID-' + GenerateID,
      email: '',
      updateAt: null,
      createdAt: null,
    };
    setDataSource([...dataSource, { ...tempEmail }]);
    setCurrentEmail({ ...tempEmail });
  };

  const handleRenderItem = (record: TEmail, index: number) => {
    return (
      <RenderItem
        record={record}
        handleDelete={handleDelete}
        handleSave={handleSave}
        setAllowAddMore={setAllowAddMore}
        currentEmail={currentEmail}
        setCurrentEmail={setCurrentEmail}
        handleRemoved={handleRemoved}
      />
    );
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          colorBorder: 'transparent',
          colorPrimaryHover: 'transparent',
        },
        components: {
          Select: {
            selectorBg: 'transparent',
          },
        },
      }}
    >
      <Spin spinning={loading}>
        <div className="flex flex-col gap-3 text-black w-full md:w-[25rem] lg:w-[30rem] p-[1.5rem]">
          <div className="text-xl font-bold">Email Setup</div>
          <div className="text-lg font-thin">
            You can add the email address(es) which can receive access request from web login page.
          </div>
          {dataSource.length > 0 && (
            <div>
              <List
                className="gap-3"
                grid={{ gutter: [0, 12], column: 1 }}
                renderItem={handleRenderItem}
                dataSource={dataSource}
              />
            </div>
          )}
          <div className="block md:flex items-center">
            <Button
              onClick={handleAddEmail}
              disabled={allowAddMore}
              className="font-medium w-full md:w-auto"
              style={{ backgroundColor: 'var(--primary-color)', color: 'var(--primary-text-color)' }}
            >
              <div className="flex items-center justify-center">
                <IPlus />
                <div className="ml-2">Add Email Address</div>
              </div>
            </Button>
            <Button className="font-medium cancel-button md:w-[100px] w-full mt-3 md:mt-0 md:ml-3" onClick={hide}>
              Cancel
            </Button>
          </div>
        </div>
      </Spin>
    </ConfigProvider>
  );
}

const RenderItem = ({
  record,
  handleDelete,
  handleSave,
  setAllowAddMore,
  currentEmail,
  setCurrentEmail,
  handleRemoved,
}: {
  record: TEmail;
  handleDelete: (record: TEmail) => void;
  handleSave: (record: TEmail, email: string, fn: () => void, err: () => void) => void;
  setAllowAddMore: React.Dispatch<React.SetStateAction<boolean>>;
  currentEmail: TEmail | undefined;
  setCurrentEmail: React.Dispatch<React.SetStateAction<TEmail | undefined>>;
  handleRemoved: () => void;
}) => {
  const [email, setEmail] = useState<string>(record.email);
  const [disable, setDisable] = useState<boolean>(record.createdAt !== null);
  const [validated, setValidated] = useState<boolean>(false);

  useEffect(() => {
    if (currentEmail && currentEmail._id === record._id) {
      setDisable(false);
    } else if (record.createdAt !== null) {
      setEmail(record.email);
      setValidated(false);
      setDisable(true);
    } else {
      handleRemoved();
    }
  }, [currentEmail]);

  const handleSubmit = () => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!email || !emailPattern.test(email)) {
      setValidated(true);
      return false;
    }

    handleSave(
      record,
      email,
      () => {
        setValidated(false);
        setDisable(true);
      },
      () => {
        setValidated(true);
      }
    );
  };

  const handleDisable = () => {
    setCurrentEmail(record);
    setDisable(false);
    setAllowAddMore(true);
  };

  const handleChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValidated(false);
    setEmail(event.target.value);
  };

  return (
    <Row gutter={[15, 0]}>
      <Col span={20}>
        <Input
          onClick={handleDisable}
          onChange={handleChangeValue}
          status={validated ? 'error' : ''}
          value={email}
          className={disable ? 'email-custom-disable' : `email-custom-input`}
          placeholder="Email address"
        />
      </Col>
      <Col span={4}>
        <div className="flex">
          {disable === true && record.createdAt !== null ? (
            <PopoverConfirm fn={() => handleDelete(record)}>
              <Button type="text" icon={<ITrash />} shape='default' />
            </PopoverConfirm>
          ) : (
            <Button type="text" onClick={handleSubmit} icon={<ISave width="20" height="20" />} shape="default" />
          )}

          {(record.createdAt === null || disable === false) && (
            <Button
              type="text"
              onClick={() => {
                setDisable(true);
                setCurrentEmail(undefined);
                setAllowAddMore(false);
              }}
              shape="default"
              icon={<CancelIcon width="20" height="20" color={'red'} />}
            />
          )}
        </div>
      </Col>
    </Row>
  );
};
