import React from 'react';

export default function IEmail() {
  return (
    <svg width="31" height="31" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="20" height="18" rx="5" fill="#FFB348" />
      <path
        d="M6.23525 5H13.7647C13.9501 5 14.1336 5.03652 14.3049 5.10746C14.4762 5.17841 14.6318 5.2824 14.7629 5.4135C14.894 5.54459 14.998 5.70022 15.069 5.87151C15.1399 6.04279 15.1764 6.22637 15.1764 6.41177V11.5882C15.1764 11.9627 15.0277 12.3218 14.7629 12.5865C14.4982 12.8513 14.1391 13 13.7647 13H6.23525C5.86083 13 5.50174 12.8513 5.23698 12.5865C4.97223 12.3218 4.82349 11.9627 4.82349 11.5882V6.41177C4.82349 6.03734 4.97223 5.67825 5.23698 5.4135C5.50174 5.14874 5.86083 5 6.23525 5Z"
        fill="#131415"
      />
      <path
        d="M13.8495 6.5632C13.9315 6.52458 14.0252 6.51897 14.1112 6.54753C14.1972 6.5761 14.2689 6.63665 14.3115 6.71664C14.354 6.79663 14.3642 6.88993 14.3399 6.97723C14.3156 7.06452 14.2586 7.13911 14.1808 7.18555L14.1507 7.20132L10.1634 9.08367C10.0807 9.12279 9.98603 9.12827 9.89937 9.09897L9.86313 9.08414L5.85042 7.20461C5.76808 7.1663 5.70367 7.09772 5.6706 7.01314C5.63752 6.92855 5.63833 6.83447 5.67285 6.75047C5.70736 6.66646 5.77294 6.59899 5.85593 6.5621C5.93892 6.5252 6.03293 6.52172 6.11843 6.55237L6.14972 6.56532L10.0121 8.37449L13.8495 6.5632Z"
        fill="#FFB348"
        stroke="#FFB348"
        strokeWidth="0.2"
      />
    </svg>
  );
}
