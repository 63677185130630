import React from 'react'
import { TypeIconProps } from './icon';

export const SuccessIcon = (props: TypeIconProps) => {
    const { width, height, className, color } = props;
    return (
        <svg
            width={width ?? '16'}
            height={height ?? '16'}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="8" cy="8" r="8" fill="#52C41A" />
            <path d="M4 8L6.64645 10.6464C6.84171 10.8417 7.15829 10.8417 7.35355 10.6464L12 6" stroke="white" strokeWidth="1.3" stroke-linecap="round" />
        </svg>
    )
}
