export enum BackendAPI {
  PING = '/api/authentication/ping',
  LOGIN = '/api/authentication/login',
  LOGOUT = '/api/authentication/logout',
  REQUEST_ACCESS = '/api/authentication/request-access',
  RESET_PASSWORD = '/api/authentication/reset-password',
  CHANGE_PASSWORD = '/api/authentication//change-password',
  RIGHT_ROLE_MANAGEMENT = '/api/right-role-management',
  EMAIL = '/api/email',
  USER = '/api/user',
  GET_LIST_DOC = '/api/bot/files',
  UPLOAD_DOC = '/api/bot/files/upload',
  CANCEL_DOC = '/api/bot/files/cancel',
  UPLOAD_PROGRESS = '/api/bot/files/progress',
  SELECT_FILE_CONTEXT = '/api/bot/files/select',
  GET_CURRENT_LIST_FILE = '/api/bot/files/selected',
  DELETE_DOC = '/api/bot/files/delete',
  GET_CHAT = '/api/bot/queries',
  CHAT_QUESTION = '/api/bot/queries/generate',
  CLEAR_HISTORY = '/api/bot/queries/clear',
  LIST_QUESTION_DOC = '/api/bot/questions/list',
  ADD_QUESTION_DOC = '/api/bot/questions/add',
  REMOVE_QUESTION_DOC = '/api/bot/questions/remove',
  EDIT_QUESTION_DOC = '/api/bot/questions/edit',
}