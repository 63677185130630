import React from 'react';

export default function ITrash() {
  return (
    <svg width="20" height="20" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 3.29159C1 3.03845 1.2052 2.83325 1.45833 2.83325H11.5417C11.7948 2.83325 12 3.03845 12 3.29159C12 3.54472 11.7948 3.74992 11.5417 3.74992H1.45833C1.2052 3.74992 1 3.54472 1 3.29159Z"
        fill="#F24649"
        stroke="#F24649"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.12484 5.11351C5.37797 5.11351 5.58317 5.31872 5.58317 5.57185V9.23851C5.58317 9.49164 5.37797 9.69685 5.12484 9.69685C4.87171 9.69685 4.6665 9.49164 4.6665 9.23851V5.57185C4.6665 5.31872 4.87171 5.11351 5.12484 5.11351Z"
        fill="#F24649"
        stroke="#F24649"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.87484 5.11358C8.12797 5.11358 8.33317 5.31878 8.33317 5.57191V9.23857C8.33317 9.49171 8.12797 9.69691 7.87484 9.69691C7.62171 9.69691 7.4165 9.49171 7.4165 9.23857V5.57191C7.4165 5.31878 7.62171 5.11358 7.87484 5.11358Z"
        fill="#F24649"
        stroke="#F24649"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.37484 2.83325C2.62797 2.83325 2.83317 3.03845 2.83317 3.29159V11.4999H10.1665V3.29159C10.1665 3.03845 10.3717 2.83325 10.6248 2.83325C10.878 2.83325 11.0832 3.03845 11.0832 3.29159V11.4999C11.0832 11.7431 10.9866 11.9762 10.8147 12.1481C10.6428 12.32 10.4096 12.4166 10.1665 12.4166H2.83317C2.59006 12.4166 2.3569 12.32 2.18499 12.1481C2.01308 11.9762 1.9165 11.7431 1.9165 11.4999L1.9165 3.29159C1.9165 3.03845 2.12171 2.83325 2.37484 2.83325Z"
        fill="#F24649"
        stroke="#F24649"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.15273 1.40273C4.41059 1.14487 4.76033 1 5.125 1H7.875C8.23967 1 8.58941 1.14487 8.84727 1.40273C9.10513 1.66059 9.25 2.01033 9.25 2.375V3.29167C9.25 3.5448 9.0448 3.75 8.79167 3.75C8.53854 3.75 8.33333 3.5448 8.33333 3.29167V2.375C8.33333 2.25344 8.28504 2.13686 8.19909 2.05091C8.11314 1.96496 7.99656 1.91667 7.875 1.91667H5.125C5.00344 1.91667 4.88686 1.96496 4.80091 2.05091C4.71496 2.13686 4.66667 2.25344 4.66667 2.375V3.29167C4.66667 3.5448 4.46146 3.75 4.20833 3.75C3.9552 3.75 3.75 3.5448 3.75 3.29167V2.375C3.75 2.01033 3.89487 1.66059 4.15273 1.40273Z"
        fill="#F24649"
        stroke="#F24649"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
