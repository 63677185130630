import React from 'react';
import { useBoundStore } from '../../states/bound.store';

export default function BackDropBackground() {
  const BackDropOpen = useBoundStore().BackDropOpen;
  const setBackDropOpen = useBoundStore().setBackDropOpen;
  const DiableBackDropOpen = useBoundStore().DiableBackDropOpen;

  return (
    <div
      onClick={() => {
        if (!DiableBackDropOpen) {
          setBackDropOpen(false);
        }
      }}
      className={`${BackDropOpen ? 'opacity-100  z-[250]' : 'opacity-0 z-[-1000]'} transition-all absolute top-0 left-0 h-full w-full`}
      style={{ backgroundColor: 'rgba(0, 0, 0, 0)' }}
    ></div>
  );
}
