import { applicationRoutes, AppRoute } from '../../routes/route.config';
import { Outlet, Route, Routes } from 'react-router-dom';
import { NotFoundPage } from '../../pages/4xx/404-not-found.page';
import { useBoundStore } from '../../states/bound.store';
import { useEffect } from 'react';
import { IPAD, MOBILE } from '../../utils/enum.util';
import { Button } from 'antd';
import IArrow from '../../assets/icons/arrow';

export const ApplicationContent = () => {
  const displaySideNav = useBoundStore().displaySideNav;
  const sideNavToggle = useBoundStore().toggleSideNav;
  const displayWideMode = useBoundStore().displayWideMode;
  const device = useBoundStore().device;

  useEffect(() => {
    if ([MOBILE, IPAD].includes(device)) {
      sideNavToggle(false);
    } else {
      sideNavToggle(true);
    }
  }, [device]);

  const constructRoute = (route: AppRoute) => {
    const canActive = route.canAccess ? route.canAccess : () => true;
    if (canActive()) {
      return (
        <Route
          key={route.path}
          path={route.path}
          element={
            <div className="h-full px-5 lg:px-[40px]">
              <div
                className={`h-full transition-all mx-auto ${displayWideMode ? 'max-w-full' : 'max-w-[70%]'}`}
              >
                {route.component}
                {route.children && route.children.length ? <Outlet /> : <></>}
              </div>
            </div>
          }
        >
          {route.children && route.children.map(constructRoute)}
        </Route>
      );
    }
  };

  function toggleSider() {
    sideNavToggle(!displaySideNav);
  }

  return (
      <div
        className={'py-6 h-full'}
      >
        {!displaySideNav && (
          <div className="fixed z-[1050] h-32 mt-4 top-0 flex justify-end items-center">
            <Button type="text" className="p-0" onClick={toggleSider}>
              <IArrow style={{ color: 'var(--primary-color)' }} />
            </Button>
          </div>
        )}
        <Routes>
          {applicationRoutes.map(constructRoute)}
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </div>
  );
};