import { Button, Popover } from 'antd';
import React, { useEffect, useState } from 'react';
import './email-popup.css';
import { useBoundStore } from '../../../states/bound.store';
import Content from './email-popup-content';
import { TEmail } from '../type-helper';
import { MOBILE } from '../../../utils/enum.util';
import httpClient from '../../../utils/http-client.util';
import { BackendAPI } from '../../../constants/backend-api.enum';
import { modalMessage } from '../../../utils/modal-message.util';

type TPopoverHeader = {
  children: React.ReactNode;
};

export default function EmailPopup(props: TPopoverHeader) {
  const { children } = props;
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [bottomRight, setBottomRight] = useState<boolean>(true);
  const [dataSource, setDataSource] = useState<TEmail[]>([]);
  const [allowAddMore, setAllowAddMore] = useState(false);
  const [currentEmail, setCurrentEmail] = useState<TEmail | undefined>(undefined);
  const device = useBoundStore().device;

  const hide = () => {
    setOpen(false);
  };

  const getData = async () => {
    const res = await httpClient
      .get(BackendAPI.EMAIL)
      .then((res) => res.data)
      .catch((reason) => ({ status: 400, message: 'Network Error' }));

    if (res.status === 200) {
      setLoading(false);
      res.data.forEach((item: TEmail, index: number) => {
        res.data[index].key = item._id;
      });

      setDataSource([...res.data]);
    } else {
      modalMessage.error({
        title: 'Error',
        content: res.message,
        centered: true,
      });
    }
  };

  useEffect(() => {
    if (device === MOBILE) {
      setBottomRight(false);
    } else {
      setBottomRight(true);
    }
  }, [device]);

  const handleOpenChange = () => {
    if (open === false) {
      setLoading(true);
      getData();
    }
    setCurrentEmail(undefined);
    setAllowAddMore(false);
    setOpen(!open);
  };

  return (
    <div className="flex items-center">
      <Popover
        content={
          <Content
            getData={getData}
            allowAddMore={allowAddMore}
            setAllowAddMore={setAllowAddMore}
            hide={hide}
            dataSource={dataSource}
            setDataSource={setDataSource}
            currentEmail={currentEmail}
            setCurrentEmail={setCurrentEmail}
            loading={loading}
            setLoading={setLoading}
          />
        }
        placement={bottomRight ? 'bottomRight' : 'bottom'}
        className="change-Popover"
        onOpenChange={handleOpenChange}
        trigger="click"
        open={open}
      >
        <Button type="text" className="p-0">
          {children}
        </Button>
      </Popover>
    </div>
  );
}
