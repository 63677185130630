import React, { useEffect, useState } from 'react';
import IRobot from '../../../assets/icons/robot';
import { TChat, TSourceDocument } from '../type-helper';
import IChatUser from '../../../assets/icons/chat-user-icon';
import IArrow from '../../../assets/icons/arrow';
import PdfDrawer from '../pdf-drawer/pdf-drawer';
import { Button } from 'antd';
import { useBoundStore } from '../../../states/bound.store';
import { groupBy } from 'lodash';
import { downloadFile } from '../../../utils/utils.util';
import { STATIC_DOC } from '../../../constants/global-key.constant';

type TResponseChatProps = {
  chat?: TChat;
  sourceDocuments?: TSourceDocument[];
  animationAnswer?: boolean;
  pretendLoading?: boolean;
  messagesEndRef?: React.MutableRefObject<HTMLDivElement | null>;
  question?: string;
  finishLoading: boolean
  setFinishLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function ResponseChat(props: TResponseChatProps) {
  const { chat, messagesEndRef, finishLoading, setFinishLoading, sourceDocuments, animationAnswer, pretendLoading, question } = props;
  const [answer, setAnswer] = useState(!animationAnswer ? chat?.answer : '');
  const [documents, setDocuments] = useState<any>(!animationAnswer ? groupBy(chat?.source_documents , (data) => data.metadata.source) : undefined);

  useEffect(() => {
    const AllText: string[] | undefined = chat?.answer.split(' ');
    let interval: NodeJS.Timer;
    const AddText = (i: number) => {
      if (AllText && i < AllText.length) {
        setAnswer((pre) => pre + ' ' + AllText[i]);
        clearInterval(interval);
        interval = setInterval(() => AddText(i + 1), 100);
      } else {
        setFinishLoading(true);
        clearInterval(interval);
      }
      messagesEndRef && messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };
    if (animationAnswer && chat && answer !== chat?.answer && AllText) {
      setAnswer((pre) => AllText[0]);
      interval = setInterval(() => AddText(1), 100);
    }

    return () => clearInterval(interval);
  }, [chat]);

  useEffect(() => {
    if (sourceDocuments && sourceDocuments.length > 0) {
      setDocuments(groupBy(sourceDocuments, (data) => data.metadata.source));
    }
  }, [sourceDocuments]);

  useEffect(() => {
    if (pretendLoading) {
      setAnswer('');
      setFinishLoading(false);
    }
  }, [pretendLoading]);

  useEffect(() => {
    if (documents) {
      messagesEndRef && messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [finishLoading, sourceDocuments, documents]);

  return (
    <div className='flex flex-col gap-[30px]'>
      <div className="flex gap-3">
        <div className="w-[35px] h-[35px]">
          <IChatUser width="35px" height="35px" />
        </div>
        <h1 className="content-center font-normal">{chat?.question ?? question}</h1>
      </div>
      <div className="flex gap-3">
        <div className="w-[35px] h-[35px]">
          <IRobot width="35px" height="35px" />
        </div>
        {pretendLoading ? (
          <div
            className="w-[20px] h-[20px] mt-[10px] animate-bounce rounded-full"
            style={{ backgroundColor: 'var(--primary-text-color)' }}
          ></div>
        ) : (
          <div className="mt-[6px]">
            <p className="content-center font-normal" style={{ whiteSpace: 'pre-wrap' }}>
              {answer}{' '}
              {!finishLoading && (
                <div
                  className="inline-block w-[15px] h-[15px] mt-[3px] rounded-full"
                  style={{ backgroundColor: 'var(--primary-text-color)' }}
                ></div>
              )}
            </p>
            {(!animationAnswer ? chat && chat.source_documents.length > 0 : (finishLoading && sourceDocuments && sourceDocuments.length > 0)) && (
              <div
                className="flex gap-4 border-2  w-fit p-2 mt-2 rounded-lg"
                style={{ borderColor: 'var(--primary-color)' }}
              >
                <div>Source:</div>
                <div>
                  {documents &&
                    Object.keys(documents).map((item: any, index: number) => (
                      <div key={item + index}>
                        <DocumentSource fileName={item} sourceDocument={documents[item]} />
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

type DocumentSourceProps = {
  fileName: string;
  sourceDocument: TSourceDocument[];
};

const DocumentSource = (props: DocumentSourceProps) => {
  const { sourceDocument, fileName } = props;
  const BackDropOpen = useBoundStore().BackDropOpen;
  const setBackDropOpen = useBoundStore().setBackDropOpen;
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!BackDropOpen) {
      setOpen(false);
    }
  }, [BackDropOpen]);

  const handleClickDocuments = () => {
    if(fileName.includes('.pdf')) {
      setBackDropOpen(true);
      setOpen(true);
    } else {
      const url = encodeURIComponent(fileName.replace(STATIC_DOC, ''));
      downloadFile(`${process.env.REACT_APP_S3_AWS}${url}`, fileName ? fileName.replace(STATIC_DOC, '') : '')
    }
  }

  return (
    <div className="underline flex items-center gap-3">
      <Button
        className="flex items-start p-0 font-normal text-base"
        type="text"
        onClick={handleClickDocuments}
        style={{ color: 'var(--primary-text-color)' }}
      >
        <div className="flex gap-3 items-center">
          {fileName.replace(STATIC_DOC, '').replace(fileName.includes('.pdf') ? '.pdf': fileName.includes('.docx') ?  ".docx" : ".doc", '')}
          <IArrow />
        </div>
      </Button>
      <PdfDrawer
        fileName={encodeURIComponent(fileName.replace(STATIC_DOC, ''))}
        open={open}
        setOpen={setOpen}
        sourceDocument={sourceDocument}
      />
    </div>
  );
};
