import { ReactElement } from 'react';
import { useBoundStore } from '../states/bound.store';
import Chatbot from '../pages/chat/chatbot';
import UserPage from '../pages/users-management/user-page';
import IChat from '../assets/icons/chat';
import ILibrary from '../assets/icons/library';
import IUser from '../assets/icons/user';
import { LibraryPage } from '../pages/library/library.page';

export type AppRoute = {
  path: string;
  sideNav?: {
    group: string;
    displayName: string;
    icon: ReactElement;
    showBadge?: {
      logic: (data: any) => boolean;
      storeSlice: string;
    };
  };
  component?: ReactElement;
  canAccess?: () => boolean;
  children?: AppRoute[];
};


export const applicationRoutes: AppRoute[] = [
  {
    path: '/chatbot',
    sideNav: {
      group: '',
      displayName: 'Chat Box',
      icon: <IChat />,
    },
    canAccess: () => useBoundStore.getState().rights?.includes('Chat Box') || false,
    component: <Chatbot />,
  },
  {
    path: '/library',
    sideNav: {
      group: '',
      displayName: 'Library',
      icon: <ILibrary />,
    },
    canAccess: () => useBoundStore.getState().rights.includes('Library') || false,
    component: <LibraryPage />,
  },
  {
    path: '/users',
    sideNav: {
      group: '',
      displayName: 'Users',
      icon: <IUser width='35' height='35' />,
    },
    canAccess: () => useBoundStore.getState().rights.includes('Users') || false,
    component: <UserPage />,
  },
];