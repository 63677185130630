import { StateCreator } from 'zustand';

export interface CustomBackgroundSlice {
  BackDropOpen: boolean;
  DiableBackDropOpen: boolean;
  setBackDropOpen: (display: boolean) => void;
  setDiableBackDropOpen: (display: boolean) => void;
}

export const createCustomBackgroundSlice: StateCreator<
  CustomBackgroundSlice,
  [['zustand/devtools', never]],
  [],
  CustomBackgroundSlice
> = (set) => ({
  DiableBackDropOpen: false,
  BackDropOpen: false,
  setBackDropOpen: (BackDropOpen: boolean) => set({ BackDropOpen }, false, 'setBackDropOpen'),
  setDiableBackDropOpen: (DiableBackDropOpen: boolean) => set({ DiableBackDropOpen }, false, 'setDiableBackDropOpen'),
});
