import React from 'react';

type TIArrowProp = {
  style?: React.CSSProperties;
  width?: string;
  height?: string;
  className?: string;
};

export default function IArrow(props: TIArrowProp) {
  const { style, width, height, className } = props;
  return (
    <svg
      style={{ ...style }}
      className={`bi bi-play-fill ${className}`}
      width={width ?? '20'}
      height={height ?? '20'}
      fill="currentColor"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393" />
    </svg>
  );
}
