import { StateCreator } from 'zustand';
import { LoginResponse } from 'bridge/authentication';

export interface User extends LoginResponse {}

export interface RoleRightDocumentSlice {
  roles: any;
  setRoles: (documents: any) => void;
}

export const createRoleRightDocumentSlice: StateCreator<
  RoleRightDocumentSlice,
  [['zustand/devtools', never]],
  [],
  RoleRightDocumentSlice
> = (set) => ({
  roles: [],
  setRoles: (roles: any) => set({ roles }),
});
