import { StateCreator } from 'zustand';
import { PC } from '../utils/enum.util';
import { TIPAD, TMOBILE, TPC } from '../utils/enum-type.util';

type DEVICE =  TPC | TMOBILE | TIPAD;

export interface SideNavDisplaySlice {
  displaySideNav: boolean;
  toggleSideNav: (display: boolean) => void;
  displayWideMode: boolean;
  toggleWideMode: (displayWideMode: boolean) => void;
  device: DEVICE;
  setDevice: (device: DEVICE) => void;
  FullScreenLoading: boolean;
  setFullScreenLoading: (FullScreenLoading: boolean) => void;
}

export const createSideNavDisplaySlice: StateCreator<
  SideNavDisplaySlice,
  [['zustand/devtools', never]],
  [],
  SideNavDisplaySlice
> = (set) => ({
  displaySideNav: true,
  toggleSideNav: (displaySideNav: boolean) => set({ displaySideNav }, false, 'toggleSideNav'),
  displayWideMode: true,
  toggleWideMode: (displayWideMode: boolean) => set({ displayWideMode }, false, 'toggleWideMode'),
  device: PC,
  setDevice: (device: DEVICE) => set({ device }, false, 'setDevice'),
  FullScreenLoading: false,
  setFullScreenLoading: (FullScreenLoading: boolean) => set({ FullScreenLoading }, false, 'setFullScreenLoading'),
});
