import React, { useEffect } from 'react';
import { Button, Result } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useBoundStore } from '../../states/bound.store';
import { getRedirectRoute } from '../../helpers/redirect-helper';
import { User } from '../../states/authentication.slice';

export const NotFoundPage = () => {
  const navigate = useNavigate();
  const user: User | null = useBoundStore().user;

  useEffect(() => {
      navigate(getRedirectRoute(user));
  }, [user]);
  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={
        <Button
          type="primary"
          onClick={() => {
            navigate(getRedirectRoute(user));
          }}
        >
          Back Home
        </Button>
      }
    />
  );
};
