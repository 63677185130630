import React from 'react';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import { Button, Dropdown, MenuProps } from 'antd';
import IAddCircle from '../../../assets/icons/add-circle';
import IMinusCircle from '../../../assets/icons/minus-circle';
import IArrow from '../../../assets/icons/arrow';
import IDirectionUpOne from '../../../assets/icons/direction-up-one';
import IDirectionUpTwo from '../../../assets/icons/direction-up-two';
import IDirectionDownOne from '../../../assets/icons/direction-down-one';
import IDirectionDownTwo from '../../../assets/icons/direction-down-two';
import { TMultiplePage } from '../type-helper';

type THeaderProp = {
  zoom: number;
  setZoom: React.Dispatch<React.SetStateAction<number>>;
  pageNumber: number | undefined;
  setPageNumber: React.Dispatch<React.SetStateAction<number | undefined>>;
  numPages: number | undefined;
  showNav: boolean | undefined;
  setShowNav: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  setGoalSearch: React.Dispatch<React.SetStateAction<string[] | undefined>>;
  sourceDocument: TMultiplePage[] | undefined;
};

export default function Header(props: THeaderProp) {
  const { setZoom, setGoalSearch, sourceDocument, zoom, pageNumber, setPageNumber, numPages, showNav, setShowNav } =
    props;

  const items: MenuProps['items'] = [
    {
      label: '200%',
      key: '2',
    },
    {
      label: '175%',
      key: '1.75',
    },
    {
      label: '150%',
      key: '1.5',
    },
    {
      label: '125%',
      key: '1.25',
    },
    {
      label: '100%',
      key: '1',
    },
    {
      label: '75%',
      key: '0.75',
    },
    {
      label: '50%',
      key: '0.5',
    },
  ];

  const handleMinus = () => {
    setZoom(zoom - 0.25);
  };

  const handleAdd = () => {
    setZoom(zoom + 0.25);
  };

  const onClick: MenuProps['onClick'] = ({ key }) => {
    setZoom(parseFloat(key));
  };

  const handleGoToMinPage = () => {
    const searchs = sourceDocument?.filter((i) => i.page === 1)[0];
    setGoalSearch(searchs && [...searchs.content]);
    setPageNumber(1);
  };

  const handlePrePage = () => {
    const searchs = sourceDocument?.filter((i) => pageNumber && i.page === pageNumber - 1)[0];
    setGoalSearch(searchs && [...searchs.content]);
    setPageNumber(pageNumber && pageNumber - 1);
  };

  const handleNextPage = () => {
    const searchs = sourceDocument?.filter((i) => pageNumber && i.page === pageNumber + 1)[0];
    setGoalSearch(searchs && [...searchs.content]);
    setPageNumber(pageNumber && pageNumber + 1);
  };

  const handleGoToMaxPage = () => {
    const searchs = sourceDocument?.filter((i) => i.page === numPages)[0];
    setGoalSearch(searchs && [...searchs.content]);
    setPageNumber(numPages ?? 0);
  };

  return (
    <div
      className={`py-[0.75rem] transition-all lg:w-[calc(100%-205px)] fixed w-full top-0 shadow-lg z-[400] bg-white ${showNav ? '' : 'head-active'}`}
    >
      <div className="relative divide-y-2 divide-y md:divide-y-0 md:divide-x-2 md:divide-x flex flex-col md:flex-row items-center justify-center">
        <div className="flex items-center gap-1 md:gap-4 w-[50%] justify-center">
          {showNav !== undefined && !showNav && (
            <div className="absolute h-[56px] mt-[-0.75rem] left-0 top-0 flex justify-end items-center">
              <Button type="text" className="p-0" onClick={() => setShowNav(!showNav)}>
                <IArrow style={{ color: 'var(--primary-color)' }} />
              </Button>
            </div>
          )}
          <Button
            onClick={handleMinus}
            disabled={zoom === 0.5}
            icon={<IMinusCircle width="25px" height="25px" />}
            shape="default"
            type="text"
          />
          <Dropdown menu={{ items, onClick }} trigger={['click']}>
            <Button className="px-0 md:px-3" type="text">
              <div className="flex items-center gap-1">
                {zoom * 100} %
                <IArrow width="10px" height="10px" style={{ transform: 'rotate(90deg)' }} />
              </div>
            </Button>
          </Dropdown>
          <Button
            disabled={zoom === 2}
            onClick={handleAdd}
            icon={<IAddCircle width="25px" height="25px" />}
            shape="default"
            type="text"
          />
        </div>
        <div className="flex pt-3 md:pt-0 items-center gap-1 md:gap-5 w-[50%] justify-center">
          <Button
            disabled={pageNumber === 1}
            className="flex items-center"
            type="text"
            style={{ width: '25px', height: '25px', padding: '0px' }}
            onClick={handleGoToMinPage}
          >
            <IDirectionUpOne width="25px" height="25px" />
          </Button>
          <Button
            disabled={pageNumber === 1}
            className="flex items-center"
            type="text"
            style={{ width: '25px', height: '25px', padding: '0px' }}
            onClick={handlePrePage}
          >
            <IDirectionUpTwo width="25px" height="25px" />
          </Button>
          <div className="h-[32px] px-3 border border-black text-center content-center rounded-lg font-normal text-sm">
            {pageNumber}/{numPages ?? 0}
          </div>
          <Button
            disabled={pageNumber === numPages}
            className="flex items-center"
            type="text"
            style={{ width: '25px', height: '25px', padding: '0px' }}
            onClick={handleNextPage}
          >
            <IDirectionDownTwo width="25px" height="25px" />
          </Button>
          <Button
            disabled={pageNumber === numPages}
            className="flex items-center"
            type="text"
            style={{ width: '25px', height: '25px', padding: '0px' }}
            onClick={handleGoToMaxPage}
          >
            <IDirectionDownOne width="25px" height="25px" />
          </Button>
        </div>
      </div>
    </div>
  );
}
