import { applicationRoutes } from "../routes/route.config";
import { User } from "../states/authentication.slice";

export const getRedirectRoute = (user: User | null, documents?: any) => {
    if (user === null) {
        return '/login';
    }

    if (user.role.devOnly) {
        return '/chatbot';
    } else {
        const routes = applicationRoutes.filter((item) => user.role.rights.includes(item.sideNav?.displayName ?? ''));
        if(user.role.rights.includes("Library") && documents && documents.file_metadata.length === 0) {
            return "/library";
        }
        return routes[0].path;
    }
};