import { Button, Collapse, Modal, Typography, UploadFile } from 'antd'
import React, { useEffect, useState } from 'react'
import { useBoundStore } from '../../../states/bound.store';
import { DeleteDocument, FileDocumentTypes } from 'bridge/sop-bot';
import { DeleteIcon } from '../../../assets/icons/delete-icon';
import {
    WarningFilled
} from '@ant-design/icons';
import { BackendAPI } from '../../../constants/backend-api.enum';
import { popMessage } from '../../../utils/pop-message.util';
import httpClient from '../../../utils/http-client.util';
import { useDocContext } from '../library.page';
import { DisplayListQuestion } from './suggested-question';

export const DeleteAllDoc = ({ selectedDoc, allDocuments, showDocuments, setShowDocuments }: { selectedDoc: any, showDocuments: UploadFile | undefined,setShowDocuments: React.Dispatch<React.SetStateAction<UploadFile | undefined>>, allDocuments: UploadFile[]  }) => {
    const user = useBoundStore().user;
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
    const [files, setFiles] = useState<string[]>([])
    const [isDeleting, setIsDeleting] = useState<boolean>(false)
    const { fetchDocumentData } = useDocContext();

    useEffect(() => {
        setFiles(selectedDoc.map((item: FileDocumentTypes) => item.name));
    }, [selectedDoc])

    const handleDeleteAllDoc = () => {
        setIsDeleting(true)
        let payload = {
            files: files,
            user: user?.userId
        }
        if (showDocuments && allDocuments.length === files.length) {
            setShowDocuments(undefined);
        }
        httpClient.post<DeleteDocument>(BackendAPI.DELETE_DOC, payload).then((res) => {
            if (res.status === 200) {
                setIsModalOpen(false)
                setIsDeleting(false)
                fetchDocumentData()
                popMessage.success('Deleted Successfully');
            }
        })
            .catch((err) => {
                popMessage.error(`Delete Failed ${err}`);
                setIsDeleting(false)
                return false;
            });
    }

    const handleCancel = () => {
        setIsModalOpen(false)
    }

    return (
        <>
            <Button disabled={files && files.length > 0 ? false : true} onClick={() => setIsModalOpen(true)} type="text" shape="default" icon={<DeleteIcon width='20' height='20' />} />
            <Modal
                title={
                    <Typography className='flex flex-row items-center gap-[10px]'>
                        <WarningFilled className='text-[30px] text-[#ff4d4f]' />
                        <Typography.Text className='text-[18px]'>Delete All Documents</Typography.Text>
                    </Typography>
                }
                open={isModalOpen}
                onCancel={handleCancel}
                footer={[
                    <Button type='default' onClick={handleCancel}>Cancel</Button>,
                    <Button type="primary" danger onClick={handleDeleteAllDoc} loading={isDeleting}>Confirm</Button>
                ]}
                centered
            >
                <Collapse defaultActiveKey={['1']}>
                    <Collapse.Panel
                        header={
                            <div className='font-bold'>
                                Document(s) to be <span style={{ color: '#cf1322' }}>deleted:</span>
                            </div>
                        }
                        key="1"
                        className='custom-collape'
                    >
                        <DisplayListQuestion questions={files} />
                    </Collapse.Panel >
                </Collapse >
            </Modal>
        </>
    )
}
