import React from 'react';

export default function IFilter() {
  return (
    <svg width="15" height="15" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.06631 9.99999C5.91907 10.0007 5.774 9.96446 5.64443 9.89452L4.48428 9.25292C4.34557 9.17703 4.22991 9.06513 4.14948 8.92901C4.06904 8.79289 4.02682 8.63759 4.02725 8.47948V4.79687C4.02725 4.76958 4.02089 4.74267 4.00869 4.71826C3.99649 4.69385 3.97877 4.67262 3.95694 4.65625L1.27627 2.51172L1.24112 2.47656C0.995025 2.22168 0.933502 1.85254 1.07413 1.52734C1.21475 1.20215 1.52237 1 1.87393 1H9.09853C9.45009 1 9.7665 1.20215 9.90712 1.52734C10.0477 1.85254 9.98623 2.21289 9.74892 2.47656L9.71376 2.51172L7.00674 4.68261C6.98651 4.69987 6.97047 4.7215 6.95982 4.74586C6.94916 4.77021 6.94417 4.79667 6.94521 4.82324V9.12108C6.94521 9.35418 6.85261 9.57774 6.68779 9.74256C6.52296 9.90739 6.29941 9.99999 6.06631 9.99999ZM1.73331 1.98437L4.39639 4.11132C4.50045 4.19274 4.58461 4.29678 4.64248 4.41556C4.70035 4.53434 4.73041 4.66474 4.73037 4.79687V8.47948C4.7293 8.51134 4.73691 8.5429 4.75239 8.57076C4.76788 8.59863 4.79064 8.62176 4.81826 8.63769L5.97842 9.2705C6.00558 9.28621 6.03645 9.29436 6.06783 9.2941C6.09921 9.29385 6.12994 9.2852 6.15685 9.26906C6.18376 9.25291 6.20585 9.22986 6.22084 9.2023C6.23583 9.17473 6.24317 9.14365 6.24209 9.1123V4.82324C6.24164 4.69193 6.27062 4.5622 6.3269 4.44356C6.38317 4.32493 6.46531 4.22041 6.56728 4.13769L9.23916 1.98437C9.25874 1.95826 9.27067 1.9272 9.2736 1.89469C9.27653 1.86218 9.27035 1.82949 9.25575 1.80029C9.24115 1.7711 9.21871 1.74654 9.19094 1.72938C9.16318 1.71221 9.13118 1.70312 9.09853 1.70312H1.87393C1.84129 1.70312 1.80929 1.71221 1.78152 1.72938C1.75375 1.74654 1.73131 1.7711 1.71671 1.80029C1.70211 1.82949 1.69593 1.86218 1.69886 1.89469C1.70179 1.9272 1.71372 1.95826 1.73331 1.98437Z"
        fill="white"
        stroke="white"
        strokeWidth="0.3"
      />
      <path
        d="M10.3285 5.70312H8.34216C8.24892 5.70312 8.1595 5.66608 8.09357 5.60015C8.02764 5.53422 7.9906 5.4448 7.9906 5.35156C7.9906 5.25832 8.02764 5.1689 8.09357 5.10297C8.1595 5.03704 8.24892 5 8.34216 5H10.3285C10.4217 5 10.5111 5.03704 10.5771 5.10297C10.643 5.1689 10.6801 5.25832 10.6801 5.35156C10.6801 5.4448 10.643 5.53422 10.5771 5.60015C10.5111 5.66608 10.4217 5.70312 10.3285 5.70312ZM10.3285 7.20312H8.34216C8.24892 7.20312 8.1595 7.16609 8.09357 7.10015C8.02764 7.03422 7.9906 6.9448 7.9906 6.85156C7.9906 6.75832 8.02764 6.6689 8.09357 6.60297C8.1595 6.53704 8.24892 6.5 8.34216 6.5H10.3285C10.4217 6.5 10.5111 6.53704 10.5771 6.60297C10.643 6.6689 10.6801 6.75832 10.6801 6.85156C10.6801 6.9448 10.643 7.03422 10.5771 7.10015C10.5111 7.16609 10.4217 7.20312 10.3285 7.20312ZM10.3285 8.70312H8.34216C8.24892 8.70312 8.1595 8.66608 8.09357 8.60015C8.02764 8.53422 7.9906 8.4448 7.9906 8.35156C7.9906 8.25832 8.02764 8.1689 8.09357 8.10297C8.1595 8.03704 8.24892 8 8.34216 8H10.3285C10.4217 8 10.5111 8.03704 10.5771 8.10297C10.643 8.1689 10.6801 8.25832 10.6801 8.35156C10.6801 8.4448 10.643 8.53422 10.5771 8.60015C10.5111 8.66608 10.4217 8.70312 10.3285 8.70312Z"
        fill="white"
        stroke="white"
        strokeWidth="0.3"
      />
    </svg>
  );
}
