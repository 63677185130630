import React from 'react'
import { TypeIconProps } from './icon';

export const DeleteIcon = (props: TypeIconProps) => {
    const { width, height, className, color } = props;
    return (
        <div>
            <svg
                width={width ?? '15'}
                height={height ?? '15'}
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path fillRule="evenodd" clipRule="evenodd" d="M1.05042 3.69304C1.05042 3.39557 1.29157 3.15442 1.58904 3.15442H13.4388C13.7363 3.15442 13.9774 3.39557 13.9774 3.69304C13.9774 3.99052 13.7363 4.23167 13.4388 4.23167H1.58904C1.29157 4.23167 1.05042 3.99052 1.05042 3.69304Z" fill="#F24649" stroke="#F24649" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" />
                <path fillRule="evenodd" clipRule="evenodd" d="M5.898 5.83421C6.19548 5.83421 6.43663 6.07537 6.43663 6.37284V10.6818C6.43663 10.9793 6.19548 11.2205 5.898 11.2205C5.60053 11.2205 5.35938 10.9793 5.35938 10.6818V6.37284C5.35938 6.07537 5.60053 5.83421 5.898 5.83421Z" fill="#F24649" stroke="#F24649" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" />
                <path fillRule="evenodd" clipRule="evenodd" d="M9.12975 5.83417C9.42723 5.83417 9.66838 6.07532 9.66838 6.37279V10.6818C9.66838 10.9793 9.42723 11.2204 9.12975 11.2204C8.83228 11.2204 8.59113 10.9793 8.59113 10.6818V6.37279C8.59113 6.07532 8.83228 5.83417 9.12975 5.83417Z" fill="#F24649" stroke="#F24649" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" />
                <path fillRule="evenodd" clipRule="evenodd" d="M2.66625 3.15442C2.96372 3.15442 3.20488 3.39557 3.20488 3.69304V13.3394H11.8229V3.69304C11.8229 3.39557 12.064 3.15442 12.3615 3.15442C12.659 3.15442 12.9001 3.39557 12.9001 3.69304V13.3394C12.9001 13.6251 12.7866 13.8991 12.5846 14.1011C12.3826 14.3031 12.1086 14.4166 11.8229 14.4166H3.20488C2.91917 14.4166 2.64517 14.3031 2.44314 14.1011C2.24112 13.8991 2.12762 13.6251 2.12762 13.3394L2.12762 3.69304C2.12762 3.39557 2.36878 3.15442 2.66625 3.15442Z" fill="#F24649" stroke="#F24649" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" />
                <path fillRule="evenodd" clipRule="evenodd" d="M4.75544 1.47328C5.05848 1.17024 5.46948 1 5.89804 1H9.12979C9.55835 1 9.96936 1.17024 10.2724 1.47328C10.5754 1.77631 10.7457 2.18732 10.7457 2.61588V3.69313C10.7457 3.9906 10.5045 4.23175 10.207 4.23175C9.90957 4.23175 9.66842 3.9906 9.66842 3.69313V2.61588C9.66842 2.47302 9.61167 2.33602 9.51066 2.23501C9.40965 2.134 9.27265 2.07725 9.12979 2.07725H5.89804C5.75519 2.07725 5.61819 2.134 5.51718 2.23501C5.41616 2.33602 5.35942 2.47302 5.35942 2.61588V3.69313C5.35942 3.9906 5.11827 4.23175 4.82079 4.23175C4.52332 4.23175 4.28217 3.9906 4.28217 3.69313V2.61588C4.28217 2.18732 4.45241 1.77631 4.75544 1.47328Z" fill="#F24649" stroke="#F24649" strokeWidth="0.2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>

        </div>
    )
}
