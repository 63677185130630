import { Button, Popover } from 'antd';
import httpClient from '../../utils/http-client.util';
import { BackendAPI } from '../../constants/backend-api.enum';
import React, { useEffect, useState } from 'react';
import { useBoundStore } from '../../states/bound.store';
import './header-user-popover.css';
import ILogout from '../../assets/icons/logout';
import { MOBILE, PC } from '../../utils/enum.util';
import IArrow from '../../assets/icons/arrow';
import { modalMessage } from '../../utils/modal-message.util';
import { EmailType } from 'bridge/authentication';
import { AxiosError } from 'axios';
import { popMessage } from '../../utils/pop-message.util';

type TPopoverHeader = {
  children: React.ReactNode;
};

export default function PopoverHeader({ children }: TPopoverHeader) {
  const [open, setOpen] = useState<boolean>(false);
  const [iconOpen, setIconOpen] = useState<boolean>(false);
  const [bottomRight, setBottomRight] = useState<boolean>(true);
  const device = useBoundStore().device;

  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = () => {
    setOpen((pre: Boolean) => !pre);
    if (open === true) {
      setIconOpen(true);
    }
  };

  useEffect(() => {
    if (device === MOBILE) {
      setBottomRight(false);
    } else {
      setBottomRight(true);
    }
  }, [device]);

  const handleClicked = () => {
    if (open === true) setIconOpen(false);
  };

  const handleOpen = () => {
    if (iconOpen === false) setOpen(true);
    setIconOpen(false);
  };

  return (
    <div className="p-2 text-left flex items-center select-none">
      <Popover
        content={<Content hide={hide} />}
        placement={bottomRight ? 'bottomRight' : 'bottom'}
        className="change-Popover flex"
        trigger="click"
        open={open}
        onOpenChange={handleOpenChange}
      >
        <Button type="text" className="p-0 flex items-center" onClick={handleClicked}>
          {children}
        </Button>
      </Popover>
      <Button type="text" className="p-0 flex items-center" onClick={handleOpen}>
        <IArrow style={{ color: 'var(--primary-text-color)' }} className={`transition-all ${open ? 'Reverse' : ''}`} />
      </Button>
    </div>
  );
}

const Content = ({ hide }: { hide: () => void }) => {
  const device = useBoundStore().device;
  const userLogout = useBoundStore().userLogout;
  const user = useBoundStore().user;
  const displayWideMode = useBoundStore().displayWideMode;
  const WideModetoggle = useBoundStore().toggleWideMode;
  const setProactiveLoggingOut = useBoundStore().setProactiveLoggingOut;
  const FullScreenLoading = useBoundStore().FullScreenLoading;
  const setFullScreenLoading = useBoundStore().setFullScreenLoading;

  const handleCheckBox = () => {
    WideModetoggle(!displayWideMode);
  };

  const handleLogout = async () => {
    try {
      setFullScreenLoading(!FullScreenLoading);
      hide();
      setProactiveLoggingOut(true);
      await httpClient.post(BackendAPI.LOGOUT);
    } finally {
      setTimeout(() => {
        setFullScreenLoading(false);
        userLogout('/login');
      }, 2000);
    }
  };

  const handleReload = () => {
    window.location.reload();
  };

  const handleResetPassword = async () => {
    setFullScreenLoading(!FullScreenLoading);
    hide();
    try {
      const requestAccesss = await httpClient.post<EmailType>(BackendAPI.RESET_PASSWORD, { email: user?.email });
      if (requestAccesss.status === 200) {
        modalMessage.success({
          title: 'Send Email Successfully',
          content: 'You will receive reset password link from our email',
          centered: true,
        });
      }
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.response) {
          const { message } = err.response.data;
          popMessage.error({ content: message });
        }
      } else {
        popMessage.error('Unknown Error During Login!');
      }
    } finally {
      setFullScreenLoading(false);
    }
  };

  return (
    <div className="px-4 py-1">
      <div className="w-full xl:w-[42rem] divide-y text-lg">
        <div className="grid grid-cols-2 py-3">
          <div className="col-span-1 font-bold">User Name:</div>
          <div className="col-span-1">{user?.name}</div>
        </div>
        <div className="grid grid-cols-2 py-3">
          <div className="col-span-1 font-bold">Role:</div>
          <div className="col-span-1">{user?.role.name}</div>
        </div>
        <div className="grid grid-cols-2 py-3">
          <div className="col-span-1 font-bold">Email:</div>
          <div className="col-span-1">{user?.email}</div>
        </div>
        {PC === device && (
          <div className="grid grid-cols-2 py-3">
            <div className="col-span-1 font-bold">Appearance</div>
            <div className="col-span-1 flex items-center">
              <input
                type="checkbox"
                checked={displayWideMode}
                onChange={handleCheckBox}
                className="w-5 h-5"
                style={{ accentColor: 'var(--primary-color)' }}
              />
              <span className="ml-3">Wide mode</span>
            </div>
            <div className="col-span-2 text-sm pt-2">
              Turn on to make this app occupy the entire width of the screen
            </div>
          </div>
        )}

        <div className="py-3" role="button" onClick={handleReload}>
          <div className="font-bold	">Rerun</div>
        </div>
        <div className="py-3" role="button" onClick={handleResetPassword}>
          <div className="font-bold	">Reset Password</div>
        </div>
        <div className="py-3 flex items-center" role="button" onClick={handleLogout}>
          <div className="font-bold	text-red-500 mr-2">Logout</div>
          <ILogout />
        </div>
      </div>
    </div>
  );
};
