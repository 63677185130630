import { Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { TSourceDocument } from '../type-helper';
import PdfComponent from './pdf-component';
import { useBoundStore } from '../../../states/bound.store';
import { CloseCircleIcon } from '../../../assets/icons/close-circle.icon';

type TPdfDrawerProp = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  sourceDocument?: TSourceDocument[];
  fileName: string;
};

export default function PdfDrawer(props: TPdfDrawerProp) {
  const { setOpen, open, sourceDocument, fileName } = props;
  const setBackDropOpen = useBoundStore().setBackDropOpen;
  const [opened, setOpened] = useState(false);
  const [refresh, setRefresh] = useState(false);

  useEffect(()=>{
    if (!opened && open) {
      setOpened(true);
    }
  }, [open])

  useEffect(() => {
    if (opened && refresh) {
      setOpened(false);
    } else if (!opened && refresh) {
      setOpened(true);
      setRefresh(false);
    }
  }, [refresh, opened])

  const onClose = () => {
    setBackDropOpen(false);
    setOpen(false);
  };

  return (
    <div
      className={`custom-drawer w-[90vw] bg-gray-200 lg:w-[70vw] max-w-[1200px] min-w-[240px] text-black absolute bottom-0 rounded-lg right-0 max-h-[calc(98%-60px-3rem)] h-[calc(98%-60px-3rem)] mb-[1.5rem] mt-[calc(60px+1.5rem)] shadow-lg bg-white z-[300] ${
        open ? 'translateX-0' : 'translateX-100'
      }`}
    >
      <Button
        onClick={onClose}
        shape="circle"
        className="ml-[-0.75rem] border-0 mt-[-0.75rem] z-[450] p-3 absolute left-0  flex items-center close-btn"
        icon={<CloseCircleIcon width="30" height="30" />}
      />
      <div className="w-full overflow-scroll h-full bg-gray-200">
        {
          (opened) &&
        <PdfComponent
          multiplePage={
            sourceDocument &&
            sourceDocument?.map((item: TSourceDocument) => ({
              page: item.metadata.page + 1,
              content: item.page_content,
            }))
          }
          setRefresh={setRefresh}
          header={true}
          zoom={1}
          url={process.env.REACT_APP_S3_AWS + fileName}
        />
      }
      </div>
    </div>
  );
}
