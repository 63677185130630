import { Button, ConfigProvider } from 'antd'
import React, { useEffect, useState } from 'react'
import { DownloadsIcon } from '../../../assets/icons/downloads-icon'
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { FileDocumentTypes } from 'bridge/sop-bot';

export const DownloadAllDoc = ({ selectedDoc }: { selectedDoc: any }) => {
  const [files, setFiles] = useState<string[]>([])
  const [isDownload, setIsDownload] = useState<boolean>(false)

  useEffect(() => {
    setFiles(selectedDoc.map((item: FileDocumentTypes) => item.name));
  }, [selectedDoc])

  const createZipAndDownload = async () => {
    setIsDownload(true)
    const zip = new JSZip();
    let countFile = 0;

    for (let i = 0; i < files.length; i++) {
      countFile++;
      const URI = encodeURIComponent(files[i]);
      const response = await fetch(`${process.env.REACT_APP_S3_AWS}${URI}`);
      const blob = await response.blob();
      zip.file(files[i], blob);
      if (countFile === files?.length) {
        const zipData = await zip.generateAsync({
          type: "blob",
          streamFiles: true,
        });
        setIsDownload(false)
        saveAs(zipData, 'documents.zip');
      }
    }
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          colorText: '#FF5C00',
        }
      }}>
      <Button loading={isDownload} disabled={files && files.length > 0 ? false : true} onClick={createZipAndDownload} type="text" shape="default" icon={<DownloadsIcon width='20' height='20' />} />
    </ConfigProvider>
  )
}
