import { Button, Popover } from 'antd';
import React, { useState } from 'react';
import "./popover-confirm.css";

type TPopoverConfirmProp = {
  children: React.ReactNode;
  fn: () => void;
  title?: string;
};

export default function PopoverConfirm(props: TPopoverConfirmProp) {
  const { children, fn, title } = props;
  const [open, setOpen] = useState<boolean>(false);

  const handleOpenChange = () => {
    setOpen(!open);
  };

  return (
    <Popover
      trigger={'click'}
      content={<Content onHide={() => setOpen(false)} fn={fn} title={title} />}
      open={open}
      onOpenChange={handleOpenChange}
    >
      {children}
    </Popover>
  );
}

type TContentProp = {
  fn: () => void;
  title?: string;
  onHide: () => void;
};

const Content = (props: TContentProp) => {
  const { fn, title, onHide } = props;
  return (
    <div className="text-center text-black p-4">
      <h1 className="text-lg">{title ?? 'Are you sure?'}</h1>
      <div className="flex gap-3 mt-3">
        <Button onClick={onHide} className="cancel-button text-md">
          No
        </Button>
        <Button
          onClick={() => {
            onHide();
            fn();
          }}
          className="ok-button text-md"
        >
          Yes
        </Button>
      </div>
    </div>
  );
};
