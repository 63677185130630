import React from 'react'
import { TypeIconProps } from './icon';

export const DownloadsIcon = (props: TypeIconProps) => {
    const { width, height, className, color } = props;
    return (
        <div>
            <svg
                width={width ?? '12'}
                height={height ?? '15'}
                viewBox="0 0 12 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M10.4842 8C10.1592 8 9.9425 8.21667 9.9425 8.54167V10.4667C9.9425 10.6833 9.72584 10.9 9.50917 10.9H1.70917C1.4925 10.9 1.27584 10.6833 1.27584 10.4667V8.54167C1.27584 8.21667 1.05917 8 0.734171 8C0.409171 8 0.192505 8.21667 0.192505 8.54167V10.4667C0.192505 11.2792 0.896671 11.9833 1.70917 11.9833H9.50917C10.3217 11.9833 11.0258 11.2792 11.0258 10.4667V8.54167C11.0258 8.21667 10.8092 8 10.4842 8Z" fill="#FF5C00" stroke="#FF5C00" strokeWidth="0.1" />
                <path d="M5.22992 8.74583C5.28409 8.8 5.33826 8.85417 5.39242 8.85417C5.44659 8.90833 5.55492 8.90833 5.60909 8.90833C5.66326 8.90833 5.77159 8.90833 5.82576 8.85417C5.87992 8.8 5.93409 8.8 5.98826 8.74583L8.42576 6.30833C8.64242 6.09167 8.64242 5.76667 8.42576 5.55C8.20909 5.33333 7.88409 5.33333 7.66742 5.55L6.15076 7.06667V1.54167C6.15076 1.21667 5.93409 1 5.60909 1C5.28409 1 5.06742 1.21667 5.06742 1.54167V7.06667L3.55076 5.55C3.33409 5.33333 3.00909 5.33333 2.79242 5.55C2.57576 5.76667 2.57576 6.09167 2.79242 6.30833L5.22992 8.74583ZM9.40076 12.9167H1.81742C1.49242 12.9167 1.27576 13.1333 1.27576 13.4583C1.27576 13.7833 1.49242 14 1.81742 14H9.40076C9.72576 14 9.94242 13.7833 9.94242 13.4583C9.94242 13.1333 9.72576 12.9167 9.40076 12.9167Z" fill="#FF5C00" stroke="#FF5C00" strokeWidth="0.1" />
            </svg>
        </div>
    )
}
