import React from 'react';

export default function ILock() {
  return (
    <svg width="20" height="20" viewBox="0 0 11 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.1385 5.34301H9.32437V4.46157C9.32437 2.55779 7.76658 1 5.8628 1C3.95901 1 2.40122 2.55779 2.40122 4.46157L2.3934 5.34301H1.62625C1.28181 5.34301 1 5.62482 1 5.96926V11.3738C1 11.7182 1.28181 12 1.62625 12H10.1369C10.4814 12 10.7632 11.7182 10.7632 11.3738V5.96926C10.7647 5.62482 10.4829 5.34301 10.1385 5.34301ZM3.02747 4.46001C3.02747 2.90065 4.30344 1.62468 5.8628 1.62468C7.42215 1.62468 8.69812 2.90065 8.69812 4.46001V5.34301H3.01964L3.02747 4.46001ZM10.1385 11.0622C10.1385 11.2344 9.99758 11.3753 9.82536 11.3753H1.94093C1.76872 11.3753 1.62781 11.2344 1.62781 11.0622V6.27925C1.62781 6.10703 1.76872 5.96613 1.94093 5.96613H9.82536C9.99758 5.96613 10.1385 6.10703 10.1385 6.27925V11.0622Z"
        fill="#F24649"
        stroke="#F24649"
        strokeWidth="0.4"
      />
      <path
        d="M5.88313 7.55835C5.71091 7.55835 5.57001 7.69925 5.57001 7.87147V9.46996C5.57001 9.64218 5.71091 9.78309 5.88313 9.78309C6.05535 9.78309 6.19625 9.64218 6.19625 9.46996V7.87147C6.19625 7.69925 6.05535 7.55835 5.88313 7.55835Z"
        fill="#F24649"
        stroke="#F24649"
        strokeWidth="0.4"
      />
    </svg>
  );
}
