import React from 'react';
import { TypeIconProps } from './icon';

export default function IRightArrow(props: TypeIconProps) {
  const { width, height, className, color } = props;
  return (
    <svg
      width={width ?? '11'}
      height={height ?? '13'}
      className={className ?? ''}
      style={{ color: color ?? 'black' }}
      viewBox="0 0 11 13"
      fill={"currentColor"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M4.6665 9.20834L7.33317 6.50001L4.6665 3.79168"
          stroke="#28303F"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}
