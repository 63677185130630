import React, { createContext, useContext, useEffect, useState } from 'react';
import CreateUser from './create-user/create-user';
import ListUser from './list-user/list-user';
import { TUser } from './type-helper';
import CreateUserModal from './create-user/create-user-modal';
import { useBoundStore } from '../../states/bound.store';
import { IPAD, MOBILE } from '../../utils/enum.util';
import httpClient from '../../utils/http-client.util';
import { BackendAPI } from '../../constants/backend-api.enum';
import { modalMessage } from '../../utils/modal-message.util';
import { Spin, TableProps } from 'antd';
import { TFilter, TOption } from '../../constants/global-type.constant';
import { ApplicationRight, ApplicationRole } from 'bridge/authentication';
import { AxiosError } from 'axios';

type OnChange = NonNullable<TableProps<TUser>['onChange']>;
type Filters = Parameters<OnChange>[1];
export type UserContextType = {
  triggerRoleRightUser: () => void;
  getUserData: () => void;
};

const UserContext = createContext<UserContextType | null>(null);

export default function UserPage() {
  const [dataSource, setDataSource] = useState<TUser[]>([]);
  const [editUser, setEditUser] = useState<TUser | undefined>(undefined);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const device = useBoundStore().device;
  const displayWideMode = useBoundStore().displayWideMode;
  const [loading, setLoading] = useState<boolean>(true);
  const [options, setOptions] = useState<TOption[]>([]);
  const [showCreatedSuccess, setShowCreatedSuccess] = useState<boolean>(false);
  const [filterOption, setFilterOption] = useState<TFilter[]>([]);
  const [filteredInfo, setFilteredInfo] = useState<Filters>({});
  const user = useBoundStore().user;
  const setRights = useBoundStore((state) => state.setRights);
  const setSubRights = useBoundStore((state) => state.setSubRights);
  const setRoles = useBoundStore((state) => state.setRoles);
  const userLogin = useBoundStore().userLogin;
  const [userCurrentList, setUserCurrentList] = useState<number | undefined>()

  useEffect(() => {
    if (![IPAD, MOBILE].includes(device)) {
      setModalOpen(false);
    } else if (editUser !== undefined) {
      setModalOpen(true);
    }
  }, [device]);

  const getRoleRight = async () => {
    try {
      const rolesResponse = await httpClient.get<ApplicationRole<ApplicationRight>[]>(
        BackendAPI.RIGHT_ROLE_MANAGEMENT + '/roles'
      );
      let newRes = rolesResponse.data.map((item) => ({...item, key: item._id}))
      setRoles(newRes)
      const temp: TOption[] = [];
      const filter: TFilter[] = [];
      if (rolesResponse && rolesResponse.data) {
        rolesResponse.data.forEach((item: ApplicationRole<ApplicationRight>) => {
          temp.push({
            label: item.name,
            value: item._id ?? '',
          });
          filter.push({
            text: <span className="text-black">{item.name}</span>,
            value: item.name,
          });
        });
        setOptions([...temp]);
        setFilterOption([...filter]);
      }
    } catch (err) { }
  }

  const triggerRoleRightUser = () => {
    getUserData();
    getRoleRight();
  }

  useEffect(() => {
    triggerRoleRightUser()
  }, []);

  const getUserData = async (fn?: () => void) => {
    const res = await httpClient
      .get(BackendAPI.USER)
      .then((res) => res.data)
      .catch((reason) => ({ status: 400, message: 'Network Error' }));

    if (res.status === 200) {
      setLoading(false);
      if (fn) {
        fn();
      }
      res.data.forEach((item: TUser, index: number) => {
        res.data[index].key = item._id;
      });
      setDataSource([...res.data]);
    } else {
      setLoading(false);
      modalMessage.error({
        title: 'Error ' + res.status,
        content: res.message,
        centered: true,
      });
    }
  };

  const CheckUpdateData = (record: any, originalRecord: any) => {
    const result = Object.keys(record).filter((i) => record[i] !== originalRecord[i]);
    return result.length > 0;
  };

  const GetDifferenceData = (record: any, originalRecord: any) => {
    let newData: any = {};
    const result = Object.keys(record).filter((i) => record[i] !== originalRecord[i]);
    result.forEach((item) => {
      newData[item] = record[item];
    });
    return newData;
  };

  const handleSave = async (data: TUser, fn: () => void) => {
    setLoading(true);
    let res;
    data.email = data.email.toLowerCase();
    if (editUser === undefined) {
      res = await httpClient
        .post(BackendAPI.USER, data)
        .then((res) => res.data)
        .catch((reason) => ({ status: 400, message: 'Network Error' }));
    } else if (CheckUpdateData(data, editUser)) {
      res = await httpClient
        .put(BackendAPI.USER + '/' + editUser._id, GetDifferenceData(data, editUser))
        .then((res) => res.data)
        .catch(() => ({ status: 400, message: 'Network Error' }));
    } else {
      res = { status: 304, message: 'Nothing has been changed' };
    }

    if (res.status === 304 || [200, 201].includes(res.status)) {
      if ([304, 200].includes(res.status)) {
        modalMessage.success({
          title: 'Success',
          content: res.message,
          centered: true,
        });
      }
      if ([200].includes(res.status)) {
        if (user?.userId === editUser?.userId) {
          const newRoleRes = await httpClient.put(`${BackendAPI.RIGHT_ROLE_MANAGEMENT}/role`, { userId: user?.userId });
          userLogin(newRoleRes.data.userObj);
          setRights(newRoleRes.data.userObj.role.rights ? newRoleRes.data.userObj.role.rights : []);
          setSubRights(newRoleRes.data.userObj.role.subRights ? newRoleRes.data.userObj.role.subRights : []);
        }
        getUserData();
      }
      if (res.status === 201) {
        getUserData(() => {
          setFilteredInfo({});
          setShowCreatedSuccess(true);
        });
        setUserCurrentList(1)
      }
      fn();
      setLoading(false);
    } else {
      setLoading(false);
      if (res.message.includes('E11000')) {
        modalMessage.error({
          title: 'Error E11000',
          content: 'Email already exist',
          centered: true,
        });
      } else {
        setLoading(false);
        modalMessage.error({
          title: 'Error ' + res.status,
          content: res.message,
          centered: true,
        });
      }
    }
  };

  const handleResetPassword = async (email: string) => {
    try {
      setLoading(true);
      //API Reset Password
      const requestAccesss = await httpClient.post(BackendAPI.RESET_PASSWORD, { email: email });
      if (requestAccesss.status === 200) {
        modalMessage.success({
          title: 'Send Email Successfully',
          content: 'User will receive reset password link from our email',
          centered: true,
        });
      }
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.response) {
          const { message } = err.response.data;
          modalMessage.error({
            title: 'Error',
            content: message,
            centered: true,
          });
        }
      } else {
        modalMessage.error({
          title: 'Error',
          content: 'Unknown Error During Login!',
          centered: true,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <UserContext.Provider value={{ triggerRoleRightUser, getUserData }}>
      <div className="grid grid-cols-5 lg:gap-[6rem] gap-[0rem]">
        <Spin spinning={loading} fullscreen></Spin>
        <div className={`hidden ${displayWideMode && 'lg:block lg:col-span-2'}`}>
          <CreateUser
            options={options}
            handleResetPassword={handleResetPassword}
            handleSave={handleSave}
            EditUser={editUser}
            setEditUser={setEditUser}
          />
        </div>
        <div className={`${displayWideMode && 'lg:col-span-3'} col-span-5`}>
          <ListUser
            userCurrentList={userCurrentList}
            setUserCurrentList={setUserCurrentList}
            filteredInfo={filteredInfo}
            setFilteredInfo={setFilteredInfo}
            showCreatedSuccess={showCreatedSuccess}
            setShowCreatedSuccess={setShowCreatedSuccess}
            filterOption={filterOption}
            loading={loading}
            setLoading={setLoading}
            dataSource={dataSource}
            setModalOpen={setModalOpen}
            setEditUser={setEditUser}
            editUser={editUser}
          />
        </div>
        <div>
          <CreateUserModal
            options={options}
            handleSave={handleSave}
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            EditUser={editUser}
            setEditUser={setEditUser}
            handleResetPassword={handleResetPassword}
          />
        </div>
      </div>
    </UserContext.Provider>
  );
}

export const useUserContext = (): UserContextType => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUserContext must be used within a ParentProvider');
  }
  return context;
};