import { Button, message } from 'antd'
import { FileDocumentTypes } from 'bridge/sop-bot';
import React from 'react'
import { DeleteIcon } from '../../../assets/icons/delete-icon';
import { CancelTokenSource } from 'axios';
import { BackendAPI } from '../../../constants/backend-api.enum';
import { UploadFile } from 'antd/lib';
import httpClient from '../../../utils/http-client.util';
import { getJson } from '../../../utils/storage.util';
import { useBoundStore } from '../../../states/bound.store';

type CancelDocComponentTypes = {
  record: FileDocumentTypes;
  allDocuments: UploadFile<any>[];
  setAllDocuments: React.Dispatch<React.SetStateAction<UploadFile<any>[]>>;
  setListLoading: React.Dispatch<React.SetStateAction<boolean>>
}

export default function CancelDocComponent(props: CancelDocComponentTypes) {
  const { record, allDocuments, setAllDocuments, setListLoading } = props;
  const setPreventReload = useBoundStore().setPreventReload;
  const user = useBoundStore().user;

  const checkPreventReload = () => {
    const uid = record && record.uid as string;
    const localData: UploadFile[] = getJson("list-doc");
    let check = false;
    const temp : UploadFile[] = localData.filter(i => {
      if (uid && i.status === "uploading" && i.uid !== uid) {
        check = true;
      }
      return i.uid !== uid;
    });
    setPreventReload(check);
    setAllDocuments([...temp]);
  }

  const handleRemove = async (file: FileDocumentTypes) => {
    try {
      if (file.task_id !== undefined) {
        setListLoading(true);
        await httpClient.post(BackendAPI.CANCEL_DOC, { task_id: file.task_id, userId: user?.userId }).catch(error => {
          throw error;
        })
        setListLoading(false);
        message.success(`${file.name} Cancelled Successfully`);
      }
      checkPreventReload();
      setAllDocuments((prevList) => prevList.filter((item) => item.uid !== file.uid));
    } catch (error) {
      message.error('Cancelled Files Error');
    }
  };

  return (
    <div>
      <Button
        type="text"
        shape="default"
        onClick={() => handleRemove(record)}
        icon={<DeleteIcon width="20" height="20" />}
      />
    </div>
  )
}
