import { Button, Collapse, ConfigProvider, Divider, Drawer, Empty, Input, Modal, Popconfirm, Space, Spin, Typography } from 'antd'
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { QuestionIcon } from '../../../assets/icons/question-icon'
import IEditPencil from '../../../assets/icons/edit'
import { DeleteIcon } from '../../../assets/icons/delete-icon'
import { useBoundStore } from '../../../states/bound.store'
import { PlusOutlined } from '@ant-design/icons';
import _ from 'lodash';
import { BackendAPI } from '../../../constants/backend-api.enum';
import { QuestionsResponse } from 'bridge/sop-bot'
import { popMessage } from '../../../utils/pop-message.util'
import httpClient from '../../../utils/http-client.util'

export type SuggestedQuestionDisplay = {
    open: (event: any) => void;
};

const { confirm } = Modal;

export const SuggestedQuestion = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => {
        return {
            open: (record: any) => setIsQuestion({ ...isQuestion, isOpen: true, fileData: record })
        };
    });
    const [newQuestion, setNewQuestion] = useState<string>('');
    const [isQuestion, setIsQuestion] = useState<any>({
        fileData: {},
        isOpen: false
    });
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [allQuestions, setAllQuestions] = useState<string[]>([])
    const [originalQuestions, setOriginalQuestions] = useState<string[]>([])
    const subRights = useBoundStore().subRights;
    const [questionValue, setQuestionValue] = useState<string>('')
    const [isChangesQuestion, setIsChangesQuestion] = useState<any>({
        isOpen: false,
        index: null
    })
    const [noQuestionChange, setNoQuestionChange] = useState<boolean>(true)
    const user = useBoundStore().user;
    const toggleSideNav = useBoundStore().toggleSideNav;
    const fileName = isQuestion.fileData?.name;

    const handleNewQuestion = () => {
        if (allQuestions.includes(newQuestion)) {
            popMessage.warning('Not able to add duplicate question')
            return false
        }
        allQuestions.push(newQuestion);
        setAllQuestions([...allQuestions]);
        setNewQuestion('')
    }

    const fetchQuestionsData = async () => {
        try {
            setIsLoading(true)
            let body = {
                file: fileName,
                user: user?.userId
            }
            //API Fetch Questions by userId
            let resData = await httpClient.post<QuestionsResponse>(BackendAPI.LIST_QUESTION_DOC, body);
            if (resData && resData.data) {
                let questions = resData.data.questions
                setOriginalQuestions(JSON.parse(JSON.stringify(questions)));
                setAllQuestions(questions);
            }
        } catch (err) {
            setIsLoading(false)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (isQuestion.isOpen) {
            fetchQuestionsData()
            if (window.innerWidth < 1300) {
                toggleSideNav(false);
            }
        }
    }, [isQuestion])

    useEffect(() => {
        if (isQuestion.isOpen) {
            if (window.innerWidth < 1300) {
                toggleSideNav(false);
            } else {
                toggleSideNav(true);
            }
        }
    }, [window.innerWidth])

    useEffect(() => {
        setNoQuestionChange(areArraysEqualIgnoreOrder(allQuestions, originalQuestions));
    }, [allQuestions, originalQuestions]);

    const handleSaveQuestion = () => {
        const newQuestion: string[] = _.differenceBy(allQuestions, originalQuestions, (a) => a);
        const deleteQuestion: string[] = _.differenceBy(originalQuestions, allQuestions, (a) => a);

        let body: {
            file: string,
            question: string,
            user: string | undefined
        } = {
            file: fileName,
            question: '',
            user: user?.userId
        }

        const payload: any = {
            newQuestion,
            deleteQuestion,
        };

        let activeKey: number[] = [];
        Object.keys(payload).map((key, index) => {
            if (payload[key].length > 0) {
                activeKey.push(index + 1)
            }
        });

        confirm({
            title: 'Confirm your changes',
            width: 500,
            content: (
                <Collapse
                    defaultActiveKey={activeKey}
                >
                    <Collapse.Panel
                        header={
                            <div className='font-bold'>
                                Suggested Question(s) to be <span style={{ color: '#3f8600' }}>created:</span>
                            </div>
                        }
                        key="1"
                    >
                        <DisplayListQuestion questions={newQuestion} />
                    </Collapse.Panel>
                    <Collapse.Panel
                        header={
                            <div className='font-bold'>
                                Suggested Question(s) to be <span style={{ color: '#cf1322' }}>deleted:</span>
                            </div>
                        }
                        key="2"
                    >
                        <DisplayListQuestion questions={deleteQuestion} />
                    </Collapse.Panel >
                </Collapse >
            ),
            okText: 'Confirm Questions Change',
            onOk: async () => {
                let promises = []
                if (newQuestion && newQuestion.length > 0) {
                    let req = newQuestion.map(async (item) => {
                        body['question'] = item
                        //API add new Question
                        return await httpClient.post(BackendAPI.ADD_QUESTION_DOC, body)
                    });
                    promises.push(Promise.all(req))
                }
                if (deleteQuestion && deleteQuestion.length > 0) {
                    let req = deleteQuestion.map(async (item) => {
                        body['question'] = item
                        //API add new Question
                        return await httpClient.post(BackendAPI.REMOVE_QUESTION_DOC, body)
                    });
                    promises.push(Promise.all(req))
                }

                await Promise.all(promises).then((res) => {
                    setIsLoading(false)
                    fetchQuestionsData()
                }).catch((err) => {
                    popMessage.error('Unable to changes Questions')
                })
            },
        });
    }

    const handleConfirmChangesQuestion = async (index: number, old_questions: string) => {
        try {
            setIsLoading(true);
            let body = {
                file: fileName,
                user: user?.userId,
                question: old_questions,
                question_update: questionValue
            }
            //APIs Edit Questions
            let response = await httpClient.post(BackendAPI.EDIT_QUESTION_DOC, body);
            if (response && response.data.message === "SUCCESS") {
                fetchQuestionsData();
                setIsChangesQuestion({ ...isChangesQuestion, isOpen: false, index: index })
            }
        } catch (err) {
            setIsLoading(false);
        } finally {
            setIsLoading(false);
        }
    }

    function areArraysEqualIgnoreOrder(
        arr1: string[],
        arr2: string[]
    ) {
        const sortArraysRecursively: (obj: Object) => Object = (obj) => {
            if (_.isArray(obj)) {
                return _.sortBy(obj.map(sortArraysRecursively));
            } else if (_.isObject(obj)) {
                return _.map(obj, (value, key) => ({ [key]: sortArraysRecursively(value) }));
            }
            return obj;
        };
        return _.isEqual(sortArraysRecursively(arr1), sortArraysRecursively(arr2));
    }

    const handleCancelChanges = () => {
        if (noQuestionChange) {
            setIsQuestion({ ...isQuestion, isOpen: false, file: {} })
            setQuestionValue('')
            setIsChangesQuestion({ ...isChangesQuestion, existValue: '', isOpen: false });
            setAllQuestions([])
        } else {
            confirm({
                title: 'Confirm Exit',
                content: 'Are you sure you want exit the changes?',
                okText: 'Confirm',
                onOk() {
                    setAllQuestions([])
                    fetchQuestionsData()
                    setIsQuestion({ ...isQuestion, isOpen: false, file: {} })
                    setQuestionValue('')
                    setIsChangesQuestion({ ...isChangesQuestion, existValue: '', isOpen: false });
                },
                onCancel() { },
            });
        }
    }

    return (

        <Drawer
            title={
                <div className='flex flex-row items-center gap-[10px]'>
                    <QuestionIcon width='30' height='30' />
                    <Typography.Text className='text-[18px]'>
                        Suggested Questions
                    </Typography.Text>
                </div>
            }
            width={992}
            open={isQuestion.isOpen}
            closable={false}
            extra={
                <Space>
                    {subRights.includes('Edit') ?
                        <Button onClick={handleSaveQuestion} disabled={noQuestionChange} className='sop-button' color='green' type="primary" >
                            Save
                        </Button>
                        : null}
                    <Button onClick={() => handleCancelChanges()}>Cancel</Button>
                </Space >
            }
        >
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimary: '#FF5C00',
                    }
                }}>
                <Spin spinning={isLoading}>
                    <div className='flex flex-col gap-[20px]'>
                        <Typography className='flex flex-wrap gap-[10px]'>
                            <Typography.Text className='text-[18px] font-semibold'>File Name:</Typography.Text>
                            <Typography.Text className='text-[18px] italic'>{isQuestion.fileData?.name}</Typography.Text>
                        </Typography>
                        <Divider className='m-0' style={{ backgroundColor: '#FFFFFF7D' }} />
                        <div className={`flex flex-col gap-[10px] ${allQuestions.length > 8 ? 'overflow-scroll' : ''}`}>
                            {allQuestions && allQuestions.length > 0 ? allQuestions.map((item, index) => (
                                <div>
                                    <Typography className='flex flex-row items-center justify-between px-[10px] gap-[10px]'>
                                        <Typography.Text className='text-nowrap me-1 text-[18px]'>
                                            {index + 1}.
                                        </Typography.Text>
                                        {
                                            isChangesQuestion.isOpen && isChangesQuestion.index === index ? (
                                                <>
                                                    <Input size='large' defaultValue={item} onChange={(e) => setQuestionValue(e.target.value)} />
                                                    <div className='flex flex-row items-center gap-[10px]'>
                                                        <Popconfirm
                                                            title="Edit the question"
                                                            description="Are you sure to edit this question?"
                                                            placement="topRight"
                                                            onConfirm={() => handleConfirmChangesQuestion(index, item)}
                                                        >
                                                            <Button disabled={questionValue.length && questionValue !== item ? false : true} className={`${questionValue.length && questionValue !== item ? '' : 'disabledDiv'}`} type="text" shape="default" icon={<i className={`ri-check-double-line text-green-700 hover:text-green-600 cursor-pointer text-[22px]`} />} />
                                                        </Popconfirm>
                                                        <Button onClick={() => {
                                                            setQuestionValue('')
                                                            setIsChangesQuestion({ ...isChangesQuestion, existValue: '', isOpen: false });
                                                        }} type="text" shape="default" icon={<i className={`ri-close-line text-red-600 hover:text-red-400 cursor-pointer w-[30px] text-[22px]`} />} />
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <Typography.Text className='text-[18px] w-full text-1-line'>
                                                        {item}
                                                    </Typography.Text>
                                                    <div className={`flex flex-row items-center gap-[10px] ${isChangesQuestion.isOpen ? 'disabledDiv' : ''}`}>
                                                        {/* <BotIcon width='32' height='32' /> */}
                                                        {subRights.includes('Edit') ? <>
                                                            <Button disabled={noQuestionChange ? false : true} className={`${noQuestionChange ? '' : 'disabledDiv'}`} onClick={() => setIsChangesQuestion({ ...isChangesQuestion, index: index, isOpen: true })} type="text" shape="default" icon={<IEditPencil />} />
                                                            <Button type="text" shape="default" onClick={() => setAllQuestions(allQuestions.filter((question) => question !== item))} icon={<DeleteIcon width='20' height='20' />} />
                                                        </> : null}
                                                    </div>
                                                </>
                                            )
                                        }
                                    </Typography>
                                    {
                                        allQuestions.length > index + 1 ? (
                                            <div className='line_dashed_custom mt-[10px]'></div>
                                        ) : null
                                    }
                                </div>
                            )) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={false} />}
                        </div>
                        <Divider className='m-0' style={{ backgroundColor: '#FFFFFF7D' }} />
                        {subRights.includes('Edit') ?
                            <Space className={`${isChangesQuestion.isOpen ? 'disabledDiv' : ''}`} direction="vertical" size="middle">
                                <Space.Compact style={{ width: '100%' }}>
                                    <Input
                                        value={newQuestion}
                                        onChange={(e) => setNewQuestion(e.target.value)}
                                        onPressEnter={handleNewQuestion}
                                    />
                                    <Button disabled={newQuestion.length ? false : true} size='large' onClick={handleNewQuestion} className='sop-button z-10' type="primary" icon={<PlusOutlined />}>
                                        Add Suggested Question
                                    </Button>
                                </Space.Compact>
                            </Space>
                            : null}
                    </div>
                </Spin>
            </ConfigProvider>
        </Drawer>
    )
})

export const DisplayListQuestion = (props: { questions: string[] }) => {
    const { questions } = props
    return (
        <div className='flex flex-col gap-[10px]'>
            {questions && questions.length > 0 ? questions.map((item, index) => (
                <div>
                    <Typography className='flex flex-row items-start gap-[10px]'>
                        <Typography.Text className='text-nowrap'>
                            {index + 1}.
                        </Typography.Text>
                        <Typography.Text>
                            {item}
                        </Typography.Text>
                    </Typography>
                    {
                        questions.length > index + 1 ? (
                            <div className='line_dashed_custom mt-[10px]'></div>
                        ) : null
                    }
                </div>
            )) :
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
        </div>
    )
}