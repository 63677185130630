import { StateCreator } from 'zustand';

export interface SideNavItemBadgeSlice {
  badgedPaths: string[];
  addBadgedPath: (newBadgedPath: string) => void;
  removeBadgedPath: (removingPath: string) => void;
}

export const createSideNavItemBadgeSlice: StateCreator<
  SideNavItemBadgeSlice,
  [['zustand/devtools', never]],
  [],
  SideNavItemBadgeSlice
> = (set) => ({
  badgedPaths: [],
  addBadgedPath: (newBadgedPath: string) => {
    set((state) => {
      if (!state.badgedPaths.includes(newBadgedPath)) {
        return { badgedPaths: [...state.badgedPaths, newBadgedPath] };
      }
      return state;
    });
  },
  removeBadgedPath: (removingPath: string) => {
    set((state) => {
      const newBadgedPaths = state.badgedPaths.filter((path) => path !== removingPath);
      return { badgedPaths: newBadgedPaths };
    });
  },
});
