import React, { useEffect, useRef, useState } from 'react';

import { Button, Form, Input, Typography } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import httpClient from '../../utils/http-client.util';
import { BackendAPI } from '../../constants/backend-api.enum';
import { AxiosError } from 'axios';
import { RequestAccessType } from 'bridge/authentication';
import { popMessage } from '../../utils/pop-message.util';
import { useBoundStore } from '../../states/bound.store';
import { AuthenticationLayout } from '../../components/layout/authentication-layout/authentication-layout.component';
import { modalMessage } from '../../utils/modal-message.util';
import { Recaptcha, ReCaptchaRef } from '../../components/recaptcha/recaptcha';
import { getRedirectRoute } from '../../helpers/redirect-helper';

const { Title } = Typography;
export const RequestAccess = () => {
    const [submitting, setIsSubmitting] = useState(false);
    const [formError, setFormError] = useState(false);
    // const captchaRef = useRef<ReCaptchaRef>(null);

    const user = useBoundStore((state) => state.user);
    const navigate = useNavigate();
    const [requestAccessForm] = Form.useForm<RequestAccessType>();

    const onFieldsChange = async () => {
        const hasErrors = requestAccessForm.getFieldsError().some(({ errors }) => errors.length);
        setFormError(hasErrors);
    };

    const handleSubmit = async (RequestAccess: RequestAccessType) => {
        // verify captcha
        // if (captchaRef.current) {
        //     const token = await captchaRef.current.execute();
        //     if (token === null) {
        //         popMessage.error('Please re-verify CAPTCHA below.')
        //         return false
        //     }
        // }
        setIsSubmitting(true);
        try {
            const requestAccesss = await httpClient.post<RequestAccessType>(BackendAPI.REQUEST_ACCESS, RequestAccess);
            if (requestAccesss.status === 200) {
                modalMessage.success({
                    title: 'Request Access Successfully',
                    content: 'Your Information is request to system admin.',
                    centered: true
                });
                // await captchaRef.current?.reset();
                navigate('/login');
            }
        } catch (err) {
            if (err instanceof AxiosError) {
                if (err.response) {
                    const { message } = err.response.data;
                    popMessage.error({ content: message });
                }
            } else {
                popMessage.error('Unknown Error During Login!');
            }
        } finally {
            setIsSubmitting(false);
        }
    };

    useEffect(() => {
        if (user) {
            navigate(getRedirectRoute(user));
        }
    }, []);

    return (
        <AuthenticationLayout>
            <div className='w-full flex flex-col items-center justify-center mb-4'>
                <Title level={2}>REQUEST ACCESS</Title>
                <Typography className='text-[16px] text-center'>If you don’t have an account, you can send a request to system admin.</Typography>
            </div>
            <Form
                form={requestAccessForm}
                name="basic"
                initialValues={{ remember: true }}
                onFinish={handleSubmit}
                onFieldsChange={onFieldsChange}
                layout="vertical"
                size='large'
                autoComplete={'off'}
            >
                <Form.Item
                    className='custom-form'
                    name="firstName"
                    rules={[{ required: true, type: 'string', message: 'Please input your first name!' }]}
                >
                    <Input
                        placeholder='First Name'
                    />
                </Form.Item>

                <Form.Item
                    className='custom-form'
                    name="lastName"
                    rules={[{ required: true, type: 'string', message: 'Please input your last name!' }]}
                >
                    <Input
                        placeholder='Last Name'
                    />
                </Form.Item>

                <Form.Item
                    className='custom-form'
                    name="email"
                    rules={[{ required: true, type: 'email', message: 'Please input valid email!' }]}
                >
                    <Input
                        placeholder='Email Address'
                    />
                </Form.Item>

                {/* <Form.Item>
                    <Recaptcha ref={captchaRef} />
                </Form.Item> */}

                <Form.Item className='w-full flex items-center justify-center mb-4'>
                    <Button className='login-button' type="primary" htmlType="submit" loading={submitting} disabled={formError}>
                        Send Request
                    </Button>
                </Form.Item>
                <Form.Item className='w-full flex items-center justify-center'>
                    <Link to={'/login'}>
                        <Typography className='link-text'>
                            LOGIN
                        </Typography>
                    </Link>
                </Form.Item>
            </Form>
        </AuthenticationLayout>
    )
}
