import { Spin } from 'antd';
import React from 'react';
import { useBoundStore } from '../../states/bound.store';
import Times from '../times/times';
import PopoverHeader from '../popover/header-user-popover';

export const ApplicationHeader = () => {
  const FullScreenLoading = useBoundStore().FullScreenLoading;
  const user = useBoundStore().user;
 
  return (
    <>
      {user && (
        <div
          style={{ backgroundColor: 'var(--secondary-bg-dark)' }}
          className="border-black border-b-2 shadow-black py-1 px-6 flex items-center sticky top-0 left-0 z-[500]"
        >
          <div className='ml-auto flex items-center'>
            <div className='mr-5 italic'>
              <Times />
            </div>
            <div>
              <PopoverHeader>
                <div className="italic mr-1">
                  <h2 role="button" className="text-sm" style={{color:"var(--primary-text-color)"}}>{user.name}</h2>
                </div>
              </PopoverHeader>
            </div>
          </div>
        </div>
      )}
      <Spin spinning={FullScreenLoading} fullscreen />
    </>
  );
};
