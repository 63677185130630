import React from 'react';

export default function ISearch() {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.1396 11.8139L15.7255 14.3997C16.0915 14.7658 16.0915 15.3595 15.7255 15.7256C15.3595 16.0916 14.7657 16.0916 14.3997 15.7256L11.8138 13.1398M13.3751 7.75036C13.3751 4.64374 10.8567 2.12534 7.75004 2.12534C4.64363 2.12534 2.12526 4.64374 2.12526 7.75036C2.12526 10.8568 4.64363 13.3751 7.75004 13.3751C10.8567 13.3751 13.3751 10.8568 13.3751 7.75036ZM11.8138 13.1398C8.98305 15.2744 4.98312 14.8548 2.65662 12.1792C0.330092 9.50393 0.470268 5.4844 2.97719 2.97748C5.48432 0.470372 9.50362 0.33017 12.1792 2.65667C14.8547 4.9832 15.2743 8.9831 13.1397 11.8139"
        fill="#ACACAC"
      />
      <path
        d="M13.1396 11.8139L15.7255 14.3997C16.0915 14.7658 16.0915 15.3595 15.7255 15.7256C15.3595 16.0916 14.7657 16.0916 14.3997 15.7256L11.8138 13.1398C8.98305 15.2744 4.98312 14.8548 2.65662 12.1792C0.330092 9.50393 0.470268 5.4844 2.97719 2.97748C5.48432 0.470372 9.50362 0.33017 12.1792 2.65667C14.8547 4.9832 15.2743 8.9831 13.1397 11.8139M13.3751 7.75036C13.3751 4.64374 10.8567 2.12534 7.75004 2.12534C4.64363 2.12534 2.12526 4.64374 2.12526 7.75036C2.12526 10.8568 4.64363 13.3751 7.75004 13.3751C10.8567 13.3751 13.3751 10.8568 13.3751 7.75036Z"
        stroke="#ACACAC"
        strokeWidth="0.2"
      />
    </svg>
  );
}
