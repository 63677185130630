import React, { useEffect, useState } from 'react';
import { TUser } from '../type-helper';
import { Button, ConfigProvider, Form, Input, Modal, Select, Spin } from 'antd';
import { TOption } from '../../../constants/global-type.constant';
import './create-user-modal.css';
import { User } from 'bridge/staff';
import IArrow from '../../../assets/icons/arrow';
import ICancel from '../../../assets/icons/cancel';

type TCreateUserModalProp = {
  modalOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  EditUser: TUser | undefined;
  setEditUser: React.Dispatch<React.SetStateAction<TUser | undefined>>;
  handleSave: (data: TUser, fn: () => void) => Promise<void>;
  options: TOption[];
  handleResetPassword: (email: string) => Promise<void>;
};

export default function CreateUserModal(props: TCreateUserModalProp) {
  const { handleResetPassword, options, modalOpen, setModalOpen, EditUser, setEditUser, handleSave } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm<User>();
  const requiredRule = [
    {
      required: true,
      message: '',
    },
  ];

  useEffect(() => {
    form.resetFields();
    if (EditUser !== undefined) {
      form.setFieldValue('firstName', EditUser.firstName);
      form.setFieldValue('lastName', EditUser.lastName);
      form.setFieldValue('email', EditUser.email);
      form.setFieldValue('role', EditUser.role);
    }
  }, [EditUser, modalOpen]);

  const handleCancel = () => {
    setEditUser(undefined);
    setModalOpen(false);
  };

  const handleSubmit = async (data: TUser) => {
    setLoading(true);
    await handleSave(data, () => {
      setEditUser(undefined);
      setModalOpen(false);
    });
    setLoading(false);
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          colorBorder: 'black',
          colorPrimaryHover: 'black',
          colorText: 'black',
        },
        components: {
          Select: {
            selectorBg: 'transparent',
          },
        },
      }}
    >
      <Modal centered footer={null} open={modalOpen} onCancel={handleCancel} closable>
        <Spin spinning={loading} fullscreen></Spin>
        <div className="flex justify-center flex-col p-5">
          <h1 className="text-xl font-medium text-center">Create User Account</h1>
          <Form className="p-0 md:p-7 mt-3" form={form} onFinish={handleSubmit}>
            <Form.Item name={'firstName'} rules={requiredRule}>
              <Input className="p-[0.75rem]" placeholder="First name" />
            </Form.Item>
            <Form.Item name={'lastName'} rules={requiredRule}>
              <Input className="p-[0.75rem]" placeholder="Last name" />
            </Form.Item>
            <Form.Item name={'email'} rules={requiredRule}>
              <Input className="p-[0.75rem]" placeholder="Email address" />
            </Form.Item>
            <Form.Item name={'role'} rules={requiredRule}>
              <Select
                options={options}
                optionRender={(text: any) => <div className="text-black">{text.label}</div>}
                suffixIcon={<IArrow style={{ color: 'black', transform: 'rotate(90deg)' }} />}
                allowClear={{
                  clearIcon: <ICancel color={'black'} className="mt-[-2px] ml-[-5px]" />,
                }}
                placeholder="Select Role"
                className="h-[3rem] select-border-custom"
                style={{ color: 'black' }}
              />
            </Form.Item>
            {EditUser === undefined ? (
              <div className="p-3 flex justify-center">
                <Button
                  htmlType="submit"
                  shape="round"
                  className="border-0 max-w-[15rem] w-[50%] min-w-[10rem] h-[2.5rem]"
                  style={{ backgroundColor: 'var(--primary-color)', color: 'var(--primary-text-color)' }}
                >
                  <strong className="font-medium">Create</strong>
                </Button>
              </div>
            ) : (
              <div className="p-3 flex flex-col items-center gap-4 w-100">
                <Button
                  htmlType="submit"
                  shape="round"
                  className="border-0 max-w-[15rem] w-[50%] min-w-[10rem] h-[2.5rem]"
                  style={{ backgroundColor: 'var(--primary-color)', color: 'var(--primary-text-color)' }}
                >
                  <strong className="font-medium">Update</strong>
                </Button>
                <Button
                  onClick={handleCancel}
                  shape="round"
                  className="border-0 max-w-[15rem] w-[50%] min-w-[10rem] h-[2.5rem]"
                  style={{ backgroundColor: '#d9d9d9', color: 'black' }}
                >
                  <strong className="font-medium">Cancel</strong>
                </Button>
                <Button
                  onClick={() => handleResetPassword(EditUser.email)}
                  type="link"
                  className="underline text-lg"
                  style={{ color: 'var(--primary-color)' }}
                >
                  <span className="send-link-text">Send Reset Password Link</span>
                </Button>
              </div>
            )}
          </Form>
        </div>
      </Modal>
    </ConfigProvider>
  );
}
