import React from 'react';
import { TypeIconProps } from './icon';

export default function IAddCircle(props: TypeIconProps) {
  const { width, height, className, color } = props;
  return (
    <svg
      width={width ?? '14'}
      height={height ?? '14'}
      className={className ?? ''}
      style={{ color: color ?? 'black' }}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.7">
        <path
          d="M6.99984 13.2708C3.54067 13.2708 0.729004 10.4592 0.729004 6.99999C0.729004 3.54082 3.54067 0.729156 6.99984 0.729156C10.459 0.729156 13.2707 3.54082 13.2707 6.99999C13.2707 10.4592 10.459 13.2708 6.99984 13.2708ZM6.99984 1.60416C4.02484 1.60416 1.604 4.02499 1.604 6.99999C1.604 9.97499 4.02484 12.3958 6.99984 12.3958C9.97484 12.3958 12.3957 9.97499 12.3957 6.99999C12.3957 4.02499 9.97484 1.60416 6.99984 1.60416Z"
          fill="#292D32"
        />
        <path
          d="M9.33317 7.4375H4.6665C4.42734 7.4375 4.229 7.23917 4.229 7C4.229 6.76083 4.42734 6.5625 4.6665 6.5625H9.33317C9.57234 6.5625 9.77067 6.76083 9.77067 7C9.77067 7.23917 9.57234 7.4375 9.33317 7.4375Z"
          fill="#292D32"
        />
        <path
          d="M7 9.77082C6.76083 9.77082 6.5625 9.57249 6.5625 9.33332V4.66666C6.5625 4.42749 6.76083 4.22916 7 4.22916C7.23917 4.22916 7.4375 4.42749 7.4375 4.66666V9.33332C7.4375 9.57249 7.23917 9.77082 7 9.77082Z"
          fill="#292D32"
        />
      </g>
    </svg>
  );
}
