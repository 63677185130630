import React from 'react';

type TICacnelProps = {
  width?: string;
  height?: string;
  className?: string;
  color?: string;
};

export default function ISave(props: TICacnelProps) {
  const { width, height, className, color } = props;
  return (

    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{ color: color ?? 'green' }}
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      width={width ?? '24'}
      height={height ?? '24'}
      className={`${className}`}
    >
      <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 12.75 6 6 9-13.5" />
    </svg>
  );
}
