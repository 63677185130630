import React, { useState, useRef, useEffect } from 'react';
import { Page, pdfjs } from 'react-pdf';
import { uniqBy } from 'lodash';

type TPdfViewerProp = {
  zoom: number;
  pageNumber: number | undefined;
  searchs: string[] | undefined;
};

export default function PdfViewer(props: TPdfViewerProp) {
  const { zoom, pageNumber, searchs } = props;
  const pdfWrapperRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [searchResults, setSearchResults] = useState<any[]>([]);

  const compareString = (firstString: string, secondString: string) => {
    return firstString.toLowerCase().replace(/\s/g, '').includes(secondString.toLowerCase().replace(/\s/g, ''));
  };

  const highlightText = (textContent: any, viewport: any) => {
    const textItems = textContent.items;
    const results: any[] = [];
    let TempResults: any[] = [];
    if (searchs && searchs.length > 0) {
      searchs.forEach((search) => {
        const splitString = search.split('\n');

        if (splitString.length > 0) {
          for (let i = 0; i < textItems.length; i++) {
            if (
              splitString.filter(
                (textSplit) => compareString(textSplit, textItems[i].str) || compareString(textItems[i].str, textSplit)
              ).length > 0
            ) {
              TempResults.push({
                ...textItems[i],
                transform: pdfjs.Util.transform(viewport.transform, textItems[i].transform),
              });
              if (compareStringWithReplace(search.replace('\n', '').replace(/\s/g, '').toLocaleLowerCase(), getString(TempResults))) {
                const textItems : any[] = joinTextItems(TempResults);
                textItems.forEach((result) => {
                  results.push({
                    ...result,
                  });
                });
                break;
              }
            } else {
              TempResults = [];
            }
          }
        }
      });
    }
    setSearchResults([...uniqBy(results, (i) => `${i.transform[5]}-${i.transform[4]}-${i.str}`)]);
  };

  const compareStringWithReplace = (search: string, tempResult: string) => {
    const ExtraValue : string = tempResult.replace(search, " ");
    const BothSideValue : string[] = ExtraValue.split(" ");
    const replaceFirstValue = BothSideValue.length > 1 ? tempResult.replace(BothSideValue[0], "") : tempResult;
    const replaceLastValue = BothSideValue.length === 2 ? replaceFirstValue.replace(BothSideValue[1], "") : replaceFirstValue;
    return search === replaceLastValue;
  }

  const getString = (arr: { str: string }[]) => {
    const ArrStr = arr.map((i) => i.str);
    const str = ArrStr.join('');
    return str.replace(/\s/g, '').toLocaleLowerCase();
  };

  const joinTextItems = (textArray: any) => {
    var addNext = false;
    return textArray.reduce((joinedTextArray: any, currentItem: any, index: any) => {
      const prevItem = joinedTextArray[joinedTextArray.length - 1];
      if (addNext) {
        if (prevItem && addNext) {
          prevItem.str += currentItem.str;
          prevItem.width = currentItem.transform[4] - prevItem.transform[4];
          prevItem.width /= zoom;
          prevItem.width += currentItem.width;
          addNext = false;
        }
      } else {
        if (prevItem && currentItem.str === ' ') {
          prevItem.str += ' ';
          prevItem.width = currentItem.transform[4]  - prevItem.transform[4];
          prevItem.width /= zoom;
          prevItem.width += currentItem.width;
          addNext = true;
        } else {
          if (currentItem.str !== '') {
            joinedTextArray.push({ ...currentItem });
          }
        }
      }
      
      return joinedTextArray;
    }, []);
  };

  const renderHighlightedText = () => {
    const context = canvasRef.current?.getContext('2d');
    if (context && pdfWrapperRef.current && canvasRef.current) {
      const pdfBoundingClient = pdfWrapperRef.current?.children[0].children[0].getBoundingClientRect();
      canvasRef.current.width = pdfBoundingClient.width;
      canvasRef.current.height = pdfBoundingClient.height;
      context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
      context.fillStyle = 'rgba(255, 92, 0, 0.5)';
      searchResults.forEach((result) => {
        context.save();
        const [a, b, c, d, e, f] = result.transform;
        context.setTransform(zoom, 0, 0, -zoom, e, f);
        context.rotate(-Math.atan2(b, a));
        context.fillRect(0, 0, result.width, result.height);
        context.restore();
      });
    }
  };

  useEffect(() => {
    renderHighlightedText();
  }, [searchResults, pageNumber, zoom]);

  const onPageRenderSuccess = async (page: any) => {
    const viewport = page.getViewport({ scale: zoom });
    const textContent = await page.getTextContent();
    highlightText(textContent, viewport);
  };

  return (
    <div style={{ position: 'relative' }} ref={pdfWrapperRef}>
      <div className="main-pdf">
        <Page
          pageNumber={pageNumber}
          onRenderSuccess={onPageRenderSuccess}
          scale={zoom}
          renderTextLayer={true}
          renderAnnotationLayer={false}
        />
      </div>
      <canvas
        ref={canvasRef}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          pointerEvents: 'none',
        }}
      />
    </div>
  );
}
