import React, { useEffect, useState } from 'react';
import IEmail from '../../../assets/icons/email';
import { Button, ConfigProvider, Input, Table, TableProps } from 'antd';
import IEditPencil from '../../../assets/icons/edit';
import ILock from '../../../assets/icons/lock';
import EmailPopup from '../email-popup/email-popup';
import { TUser } from '../type-helper';
import './list-user.css';
import IUser from '../../../assets/icons/user';
import { useBoundStore } from '../../../states/bound.store';
import { IPAD, MOBILE, PC } from '../../../utils/enum.util';
import IArrow from '../../../assets/icons/arrow';
import { RoleSetting } from '../components/role-setting.component';
import { isObject } from 'lodash';
import { TFilter } from '../../../constants/global-type.constant';
import PopoverConfirm from '../../../components/popover/popover-confirm';
import httpClient from '../../../utils/http-client.util';
import { BackendAPI } from '../../../constants/backend-api.enum';
import { modalMessage } from '../../../utils/modal-message.util';
import IUnlock from '../../../assets/icons/unlock';
import { FilterValue } from 'antd/es/table/interface';
import IFilter from '../../../assets/icons/filter';
import { useUserContext } from '../user-page';
import { PopoverDisplay } from '../../../components/popover/popover-display';
import IRobot from '../../../assets/icons/robot';
import { paginationItemRender } from '../../../utils/utils.util';

type OnChange = NonNullable<TableProps<TUser>['onChange']>;
type Filters = Parameters<OnChange>[1];

type TListUserProp = {
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  editUser: TUser | undefined;
  setEditUser: React.Dispatch<React.SetStateAction<TUser | undefined>>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  dataSource: TUser[];
  filterOption: TFilter[];
  showCreatedSuccess: boolean;
  setShowCreatedSuccess: React.Dispatch<React.SetStateAction<boolean>>;
  filteredInfo: Filters;
  setFilteredInfo: React.Dispatch<React.SetStateAction<Record<string, FilterValue | null>>>;
  userCurrentList: number | undefined;
  setUserCurrentList: React.Dispatch<React.SetStateAction<number | undefined>>;
};

export default function ListUser({
  filterOption,
  setModalOpen,
  editUser,
  setEditUser,
  loading,
  setLoading,
  dataSource,
  showCreatedSuccess,
  setShowCreatedSuccess,
  filteredInfo,
  setFilteredInfo,
  userCurrentList,
  setUserCurrentList
}: TListUserProp) {
  const user = useBoundStore().user;
  const device = useBoundStore().device;
  const displayWideMode = useBoundStore().displayWideMode;
  const [openRole, setOpenRole] = useState<boolean>(false);
  const subRights = useBoundStore().subRights;
  const { getUserData } = useUserContext();
  const [currentPage, setCurrentPage] = useState<number>(1);

  const TableTitleRender = (title: string, FClass: string) => (
    <div className={'flex items-center select-none ' + FClass}>
      <div>{title}</div>&nbsp;&nbsp;
    </div>
  );

  const handleLock = async (record: TUser) => {
    if (record.isLocked && record.role === null) {
      modalMessage.warning({
        title: 'Missing Role',
        content: 'No roles available for this user.',
      });
      return false
    }
    setLoading(true);
    let res = await httpClient
      .put(BackendAPI.USER + '/lock/' + record._id + '?isLocked=' + !record.isLocked)
      .then((res) => res.data)
      .catch(() => ({ status: 400, message: 'Error network!' }));

    if (res.status === 200) {
      setLoading(false);
      getUserData();
      modalMessage.success({
        title: 'Success',
        content: res.message,
        centered: true,
      });
    } else {
      setLoading(false);
      modalMessage.error({
        title: 'Error ' + res.status,
        content: res.message,
        centered: true,
      });
    }
  };

  const handleChange: OnChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
  };

  const handleEdit = (record: TUser) => {
    if ([IPAD, MOBILE].includes(device) || !displayWideMode) {
      setModalOpen(true);
    }
    setEditUser({ ...record, role: isObject(record.role) ? record.role._id ?? '' : '' });
  };

  return (
    <ConfigProvider>
      <div className="p-0 lg:p-5">
        <div className="block sm:flex items-center justify-between">
          <h1 className="text-lg font-medium content-center">List of User Accounts</h1>
          {subRights.includes('Role Setting') && (
            <div className="mt-3 sm:mt-0 flex items-center gap-3">
              <Button
                type="text"
                className={`${displayWideMode && 'lg:hidden'} p-0 flex items-center`}
                onClick={() => setModalOpen(true)}
              >
                <IUser width="28" height="28" />
              </Button>
              <EmailPopup>
                <IEmail />
              </EmailPopup>
              <Button
                className="h-[28px] border-0"
                style={{ backgroundColor: 'var(--primary-color)', color: 'var(--primary-text-color)' }}
                onClick={() => setOpenRole(!openRole)}
              >
                <div className="flex items-center">
                  <strong className="font-medium">Role Setting</strong>
                  <IArrow style={{ color: 'var(--primary-text-color)' }} />
                </div>
              </Button>
            </div>
          )}
        </div>
        <ConfigProvider
          theme={{
            token: {
              colorBgContainer: 'transparent',
            },
            components: {
              Table: {
                colorBorder: 'var(--primary-text-color)',
                headerSplitColor: 'transparent',
                padding: 5,
                cellPaddingInline: 0,
                colorBgBase: 'transparent',
                filterDropdownBg: 'white',
                headerColor: 'var(--primary-text-color)',
                colorText: 'var(--primary-text-color)',
              },
            },
          }}
        >
          <div className="mt-2">
            <Table
              pagination={{
                position: ['none', 'bottomCenter'],
                pageSize: 10,
                current: currentPage,
                onChange: (count) => setCurrentPage(count),
                showSizeChanger: false,
                itemRender: paginationItemRender
              }}
              onChange={handleChange}
              columns={[
                {
                  dataIndex: ['firstName', 'email', 'lastName'],
                  title: TableTitleRender('Users', ''),
                  className: 'w-[50%] min-w-[200px] p-0',
                  filterIcon: <IFilter />,
                  filterSearch: true,
                  filteredValue: filteredInfo['firstName.email.lastName'] || null,
                  onFilter: (value, record) =>
                    record.firstName.includes(value as string) ||
                    record.lastName.includes(value as string) ||
                    record.email.includes(value as string),
                  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
                    <div className="p-3">
                      <Input.Search
                        className="custom-search-input"
                        placeholder={`Search Users`}
                        value={selectedKeys[0]}
                        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => confirm()}
                        onSearch={() => confirm()}
                        style={{ display: 'block', color: 'black' }}
                      />
                    </div>
                  ),
                  render: (_, record, index) => {
                    return index !== 0 ? (
                      <>
                        {record.firstName + ' ' + record.lastName}
                        <br />
                        {record.email}
                      </>
                    ) : (
                      <PopoverDisplay
                        disableOpen={true}
                        contentChildren={
                          <div>
                            <div className="flex justify-start items-center">
                              <Button type="text" className="p-0">
                                <IRobot />
                              </Button>
                              <h3 className="content-center text-center w-full font-bold">User Account is Created!</h3>
                            </div>
                            <div className="text-start mt-2.5">
                              System will send a password setup link to <span className='font-bold'>{record.firstName + ' ' + record.lastName}</span>’s email address.
                            </div>
                            <div className="text-center flex justify-center ">
                              <Button className='p-0' type="link" style={{ color: 'var(--primary-color)' }} onClick={() => setShowCreatedSuccess(false)}>
                                <span className="underline">Noted, thanks</span>
                              </Button>
                            </div>
                          </div>
                        }
                        display={showCreatedSuccess}
                        hasCloseBtn={false}
                        style={{
                          inset: `${[PC].includes(device) ? 'auto 0px -100px auto' : ''}`,
                          minWidth: '300px'
                        }}
                        placement={`${[PC].includes(device) ? 'leftTop' : 'bottom'}`}
                      >
                        <span>
                          {record.firstName + ' ' + record.lastName}
                          <br />
                          {record.email}
                        </span>
                      </PopoverDisplay>
                    );
                  },
                  ellipsis: true,
                },
                {
                  dataIndex: 'role',
                  title: TableTitleRender('Role', ''),
                  className: 'w-[25%] min-w-[100px] p-0',
                  filterSearch: true,
                  filteredValue: filteredInfo.role || null,
                  filterIcon: <IFilter />,
                  filters: filterOption,
                  onFilter: (value, record) => isObject(record.role) && record.role.name === (value as string),
                  render: (_, record) => isObject(record.role) && record.role.name,
                  ellipsis: true,
                },
                {
                  dataIndex: 'actions',
                  title: <div className="text-center select-none">Action(s)</div>,
                  className: 'w-[25%] min-w-[100px] text-center p-0',
                  render: (_, record) => {
                    return (
                      <div className="flex justify-around w-full items-center">
                        {editUser?._id !== record._id && (
                          <Button
                            type="text"
                            onClick={() => handleEdit(record)}
                            icon={<IEditPencil />}
                            shape="default"
                          />
                        )}
                        {!record.isLocked && user?.userId !== record.userId && (
                          <PopoverConfirm fn={() => handleLock(record)}>
                            <Button type="text" icon={<ILock />} shape="default" />
                          </PopoverConfirm>
                        )}
                        {record.isLocked && user?.userId !== record.userId && (
                          <PopoverConfirm fn={() => handleLock(record)}>
                            <Button type="text" className="p-0 flex items-center" icon={<IUnlock />} shape="default" />
                          </PopoverConfirm>
                        )}
                      </div>
                    );
                  },
                },
              ]}
              scroll={{ x: 550 }}
              dataSource={dataSource}
            />
          </div>
        </ConfigProvider>
      </div>
      <RoleSetting open={openRole} onClose={() => setOpenRole(false)} />
    </ConfigProvider>
  );
}
