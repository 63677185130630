import React, { useEffect, useState } from 'react';
import { Button, Drawer, Space, Input, Checkbox, ConfigProvider, Modal, Collapse, Empty, Popconfirm, } from 'antd'
import { BackendAPI } from '../../../constants/backend-api.enum';
import httpClient from '../../../utils/http-client.util';
import { ApplicationRight, ApplicationRole } from 'bridge/authentication';
import { popMessage } from '../../../utils/pop-message.util';
import { DataTable, DataTableColumn } from '../../../components/data-table/data-table.component';
import _ from 'lodash';
import { useBoundStore } from '../../../states/bound.store';
import { DeleteIcon } from '../../../assets/icons/delete-icon';
import { useUserContext } from '../user-page';

type editRoleNameType = {
    index: number,
    existValue: string,
    isOpen: boolean
}

export const RoleSetting = ({ open, onClose }: { open: boolean, onClose: () => void }) => {

    const [allRoles, setAllRoles] = useState<ApplicationRole<ApplicationRight>[]>([]);
    const [originalAllRoles, setOriginalAllRoles] = useState<ApplicationRole<ApplicationRight>[]>([]);
    const [rightsData, setRightsData] = useState<ApplicationRight[]>([]);
    const [newRoleValue, setNewRoleValue] = useState<string>('')
    const [editRoleName, setEditRoleName] = useState<editRoleNameType>({
        index: 0,
        existValue: '',
        isOpen: false
    })
    const [noRoleIsChanged, setNoRoleIsChanged] = useState(true);
    const userLogin = useBoundStore((state) => state.userLogin);
    const user = useBoundStore().user;
    const toggleSideNav = useBoundStore().toggleSideNav;
    const setRights = useBoundStore((state) => state.setRights);
    const setSubRights = useBoundStore((state) => state.setSubRights);
    const [saveLoading, setSaveLoading] = useState(false);
    const [dataLoading, setDataLoading] = useState(false);
    const [changeRoleValue, setChangeRoleValue] = useState<string>('');
    const roles = useBoundStore().roles;
    const { triggerRoleRightUser } = useUserContext();

    const fetchQuestionsData = async () => {
        setDataLoading(true)
        try {
            const rightsResponse = await httpClient.get<ApplicationRight[]>(BackendAPI.RIGHT_ROLE_MANAGEMENT + '/rights');
            let newRes = rightsResponse.data.map((item) => ({ ...item, key: item._id }))
            setDataLoading(false)
            setRightsData(newRes);
        } catch (e) {
            popMessage.error('Unable to fetch rights');
            setDataLoading(false)
        }
    }

    useEffect(() => {
        if (open) {
            fetchQuestionsData()
            if (window.innerWidth < 1300) {
                toggleSideNav(false);
            }
        }
    }, [open]);

    useEffect(() => {
        if (roles && roles.length > 0)
            setAllRoles(roles);
        setOriginalAllRoles(JSON.parse(JSON.stringify(roles)));
    }, [roles])

    useEffect(() => {
        setNoRoleIsChanged(areArraysEqualIgnoreOrder(allRoles, originalAllRoles));
    }, [allRoles, originalAllRoles]);

    const rightChanges = (roleindex: number, accessItem: string, itemRight: ApplicationRight) => {
        let rightName = itemRight.name;
        let newRolesData = [...allRoles];
        let targetRole = newRolesData[roleindex];
        let newObjectRight: ApplicationRight = {
            name: rightName,
            access: []
        }

        if (targetRole.rights === undefined) {
            targetRole.rights = [newObjectRight];
        } else {
            let findTagetRole = targetRole.rights.filter((item) => item.name === rightName);
            if (findTagetRole.length < 1) {
                targetRole.rights.push(newObjectRight);
            }
        }

        let targetRight: ApplicationRight | undefined = targetRole.rights?.find((item) => item.name === rightName);
        let targetAccess: ApplicationRight['access'] = [];
        targetAccess = targetRight?.access ? targetRight?.access : [];
        if (targetAccess?.includes(accessItem)) {
            if (accessItem === (itemRight.access ? itemRight.access[0] : '')) {
                targetAccess = [];
            }
            let filteredArray = targetAccess?.filter(function (e) { return e !== accessItem });
            targetAccess = filteredArray;
        } else {
            if (itemRight.access[0] !== accessItem && !targetAccess.includes(itemRight.access[0])) {
                targetAccess?.push(itemRight.access[0]);
            }
            targetAccess?.push(accessItem);
        }

        const newTargetRole = targetRole.rights.map((item) => {
            if (item.name === rightName) {
                return { ...item, access: targetAccess };
            } else {
                return item;
            }
        });
        let checkRight = checkRequireRight(newTargetRole);
        if (checkRight) {
            targetRole.rightRequire = checkRight
        } else {
            delete targetRole.rightRequire
        }
        targetRole.rights = newTargetRole;
        newRolesData[roleindex] = targetRole;
        setAllRoles(newRolesData);
    }

    const checkRequireRight = (data: ApplicationRight[]) => {
        let newRight: string[] = [];
        data.forEach((item: ApplicationRight) => {
            item.access.forEach((it: string) => {
                newRight.push(it)
            })
        })
        return newRight.length === 0
    }

    const confirmRolesChanges = async () => {
        let rightRequire = allRoles.filter((item) => item.rightRequire);
        if (rightRequire.length > 0) {
            Modal.warning({
                title: 'Role(s) required at least one right',
                content: (rightRequire.map((r) => r.name).join(', ')),
            })
            return false
        }
        const newRoles = _.differenceBy(allRoles, originalAllRoles, (a) => a.name);
        const deletedRoles = _.differenceBy(originalAllRoles, allRoles, (a) => a.name);
        const changedRoles: ApplicationRole<ApplicationRight>[] = [];
        const allRolesStayed = allRoles.filter((role) => originalAllRoles.find((or) => or.name === role.name));
        allRolesStayed.forEach((role) => {
            const correspondingOriginalRole = originalAllRoles.find(
                (or) => or.name === role.name
            ) as ApplicationRole<ApplicationRight>;
            if (!_.isEqual(_.sortBy(correspondingOriginalRole.rights), _.sortBy(role.rights))) {
                changedRoles.push(role);
            }
        });

        const payload: any = {
            newRoles,
            deletedRoles,
            changedRoles,
        };

        let activeKey: number[] = [];
        Object.keys(payload).map((key, index) => {
            if (payload[key].length > 0) {
                activeKey.push(index + 1)
            }
        });
        payload['userId'] = user?.userId;

        Modal.confirm({
            title: 'Confirm your changes',
            content: (
                <Collapse
                    defaultActiveKey={activeKey}
                >
                    <Collapse.Panel
                        header={
                            <div className='font-bold'>
                                Role(s) to be <span style={{ color: '#3f8600' }}>created:</span>
                            </div>
                        }
                        key="1"
                    >
                        <div className='font-medium'>
                            {newRoles.map((r) => r.name).join(', ') || (
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={false} />
                            )}
                        </div>
                    </Collapse.Panel>
                    <Collapse.Panel
                        header={
                            <div className='font-bold'>
                                Role(s) to be <span style={{ color: '#cf1322' }}>deleted:</span>
                            </div>
                        }
                        key="2"
                    >
                        <div className='font-medium'>
                            {deletedRoles.map((r) => r.name).join(', ') || (
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={false} />
                            )}
                        </div>
                    </Collapse.Panel >
                    <Collapse.Panel
                        header={
                            <div className='font-bold'>
                                Role(s) to be <span style={{ color: '#16c1f6' }}> modified:</span >
                            </div >
                        }
                        key="3"
                    >
                        <div className='font-medium'>
                            {changedRoles.map((r) => r.name).join(', ') || (
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={false} />
                            )}
                        </div>
                    </Collapse.Panel >
                </Collapse >
            ),
            okText: 'Confirm Role Change',
            onOk: async () => {
                setSaveLoading(true)
                try {
                    const newRoleRes = await httpClient.put(`${BackendAPI.RIGHT_ROLE_MANAGEMENT}/role`, payload);
                    userLogin(newRoleRes.data.userObj);
                    setRights(newRoleRes.data.userObj.role.rights ? newRoleRes.data.userObj.role.rights : [])
                    setSubRights(newRoleRes.data.userObj.role.subRights ? newRoleRes.data.userObj.role.subRights : [])
                    popMessage.success({ content: 'Role changes have been applied' });
                    setNoRoleIsChanged(true);
                    triggerRoleRightUser()
                    onClose();
                } catch (err) {
                    setSaveLoading(false)
                    popMessage.error({ content: 'Unable to apply role changes!' });
                } finally {
                    setSaveLoading(false)
                }
            },
        });
    }

    const handleNewRole = () => {
        if (!newRoleValue) {
            popMessage.error('Role name cannot be empty');
            return;
        }
        if (allRoles.find((role) => role.name.toLowerCase() === newRoleValue.toLowerCase())) {
            popMessage.error('Role name already exists or not valid');
            return;
        }
        allRoles.push({ name: newRoleValue.trim(), rights: [], rightRequire: true } as ApplicationRole<ApplicationRight>);
        setAllRoles([...allRoles]);
        setNewRoleValue('');
    }

    const handleCancelRoleSetting = () => {
        if (noRoleIsChanged) {
            onClose()
            setEditRoleName({ ...editRoleName, isOpen: false })
        } else {
            Modal.confirm({
                title: 'Confirm Exit',
                content: 'Are you sure you want exit the changes?',
                okText: 'Confirm',
                onOk: async () => {
                    onClose()
                    setAllRoles(originalAllRoles)
                    setEditRoleName({ ...editRoleName, isOpen: false })
                },
            });
        }
    }

    const currentTableSettings: any = {
        numbered: false,
        numberedWidth: 100,
        pagination: false,
        bordered: true,
        size: 'large',
        operationColumnWidth: 180,
        scroll: { y: 540 }
    }

    const rightChildren = rightsData.map((itemRight, index) => ({
        title: itemRight.name,
        dataIndex: 'rights',
        key: 'rights',
        width: 200,
        render: (rights: ApplicationRole<ApplicationRight>['rights'], record: ApplicationRole<ApplicationRight>, index: number) => {
            return (
                <div className={`flex flex-col gap-[10px] ${editRoleName.isOpen ? 'disabledDiv' : ''}`}>
                    {itemRight.access?.map((it: string, ind: number) => {
                        let targetRights = rights?.find((item) => item.name === itemRight.name);
                        return (
                            <ConfigProvider
                                theme={{
                                    token: {
                                        colorPrimary: '#FF5C00',
                                        colorBorder: record?.rightRequire ? 'red' : '',
                                        colorText: record?.rightRequire ? 'red' : '',
                                    },
                                }}
                            >
                                <Checkbox
                                    checked={targetRights?.access?.includes(it)}
                                    onChange={() => rightChanges(index, it, itemRight)}
                                >
                                    {it}
                                </Checkbox>
                            </ConfigProvider>
                        )
                    })}
                </div>
            )
        }
    }))

    const handleConfirmChangesRole = async (record: ApplicationRole<ApplicationRight>) => {
        try {
            const res = await httpClient.put(`${BackendAPI.RIGHT_ROLE_MANAGEMENT}/role/${record._id}`, {
                newName: changeRoleValue,
                userId: user?.userId
            });
            let response = res.data;

            if (response.status === 200) {
                popMessage.success(response.message);
                userLogin(response.userObj);
                setChangeRoleValue('');
                setEditRoleName({ ...editRoleName, isOpen: false });
                triggerRoleRightUser()
            }
        } catch (err) {
            popMessage.error('Unable to Changes Role')
        }
    }

    const columns: DataTableColumn<any>[] = [
        {
            title: 'Role Name',
            dataIndex: 'name',
            key: 'name',
            width: 200,
            render: (name: string, record: ApplicationRole<ApplicationRight>, index: number) => {
                return (
                    <div className='flex flex-col gap-2'>
                        {
                            editRoleName.isOpen && editRoleName.index === index ? (
                                <>
                                    <Input defaultValue={name} onChange={(event) => setChangeRoleValue(event.target.value)} />
                                    <div>
                                        <Popconfirm
                                            title="Edit the Role"
                                            description="Are you sure to edit this role?"
                                            placement="topRight"
                                            onConfirm={() => handleConfirmChangesRole(record)}
                                        >
                                            <Button disabled={changeRoleValue.length && changeRoleValue !== name ? false : true} className={`${changeRoleValue.length && changeRoleValue !== name ? '' : 'disabledDiv'}`} type="text" shape="default" icon={<i className={`ri-check-double-line text-green-700 hover:text-green-600 cursor-pointer text-[22px]`} />} />
                                        </Popconfirm>
                                        <Button onClick={() => {
                                            setChangeRoleValue('')
                                            setEditRoleName({ ...editRoleName, isOpen: false });
                                        }} type="text" shape="default" icon={<i className={`ri-close-line text-red-600 hover:text-red-400 cursor-pointer w-[30px] text-[22px]`} />} />
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div>{name}</div>
                                    <div className={`flex flex-row items-center gap-[10px] ${editRoleName.isOpen ? 'disabledDiv' : ''}`}>
                                        <Button disabled={noRoleIsChanged ? false : true} className={`sop-button-outlined ${noRoleIsChanged ? '' : 'disabledDiv'}`} onClick={() => setEditRoleName({ ...editRoleName, isOpen: true, index: index, })} size='middle' type="primary" >
                                            Edit Name
                                        </Button>
                                        <Button onClick={() => setAllRoles(allRoles.filter((role) => role.name !== name))} danger type="text" shape="default" icon={<DeleteIcon width='20' height='20' />} />
                                    </div>
                                </>
                            )
                        }
                    </div>
                )
            }
        },
        {
            title: "Assign Access",
            children: rightChildren,
        }
    ]

    function areArraysEqualIgnoreOrder(
        arr1: ApplicationRole<ApplicationRight>[],
        arr2: ApplicationRole<ApplicationRight>[]
    ) {
        const sortArraysRecursively: (obj: Object) => Object = (obj) => {
            if (_.isArray(obj)) {
                return _.sortBy(obj.map(sortArraysRecursively));
            } else if (_.isObject(obj)) {
                return _.map(obj, (value, key) => ({ [key]: sortArraysRecursively(value) }));
            }
            return obj;
        };
        return _.isEqual(sortArraysRecursively(arr1), sortArraysRecursively(arr2));
    }

    useEffect(() => {
        if (open) {
            if (window.innerWidth < 1300) {
                toggleSideNav(false);
            } else {
                toggleSideNav(true);
            }
        }
    }, [window.innerWidth])

    return (
        <Drawer
            title="Role Setting"
            width={992}
            open={open}
            closable={false}
            extra={
                <Space>
                    <Button loading={saveLoading} onClick={confirmRolesChanges} disabled={noRoleIsChanged} className='sop-button' color='green' type="primary" >
                        Save
                    </Button>
                    <Button onClick={handleCancelRoleSetting}>Cancel</Button>
                </Space >
            }
        >
            <div className='flex flex-col gap-3'>
                <DataTable style={{ margin: '0' }}  data={allRoles} setting={currentTableSettings} loading={dataLoading} columnsDef={columns} />
            </div>
            <Space direction="vertical" size="middle" className={`mt-[1rem] ${editRoleName.isOpen ? 'disabledDiv' : ''}`}>
                <Space.Compact style={{ width: '100%' }}>
                    <Input value={newRoleValue} onChange={(e) => setNewRoleValue(e.target.value)}  />
                    <Button disabled={newRoleValue.length ? false : true} size='large' onClick={handleNewRole} className='sop-button-outlined z-10' type="primary" >
                        Add New Role
                    </Button>
                </Space.Compact>
            </Space>
        </Drawer >
    )
}